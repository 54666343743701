/* eslint-disable */
/* Generated via yarn generate-type */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import type React from 'react';
import type { NormalizedCacheObject } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/* Typescript */
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date (isoformat) */
  Date: string;
  /** Date with time (isoformat) */
  DateTime: any;
  /** Decimal (fixed-point) */
  Decimal: any;
  UUID: string;
};

export type ApiError = {
  __typename: 'APIError';
  message: Scalars['String'];
};

export type ApiErrorRedemptionCodeInfo = ApiError | RedemptionCodeInfo;

export type ApiErrorRenewCycleSchemePlanQuote = ApiError | RenewCycleSchemePlanQuote;

export type ApiResult = {
  __typename: 'APIResult';
  message: Scalars['String'];
};

export type ApiResultApiError = ApiResult | ApiError;

export type Address = {
  __typename: 'Address';
  addressLineOne: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  flat?: Maybe<Scalars['String']>;
  houseName?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  postTown?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  addressLineOne: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  flat?: Maybe<Scalars['String']>;
  houseName?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  postTown?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
};

export type B2BWaitlistInput = {
  fullName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  nameOrWebsite: Scalars['String'];
  estimatedNumberOfBikes: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type BikeType = {
  __typename: 'BikeType';
  bikeId: Scalars['Int'];
  name: Scalars['String'];
  variationName: Scalars['String'];
  shortDescription: Scalars['String'];
  images?: Maybe<Array<KitImageType>>;
};

export type BillingDetails = {
  __typename: 'BillingDetails';
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type BuyingBikeInvoiceSummary = {
  __typename: 'BuyingBikeInvoiceSummary';
  buyingBikePrice: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
};

export type BuyingBikeInvoiceSummaryApiError = BuyingBikeInvoiceSummary | ApiError;

export type BuzzbikeRetailData = {
  __typename: 'BuzzbikeRetailData';
  id: Scalars['Int'];
  retailName: Scalars['String'];
  addressLineOne: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  retailPhoneNumber?: Maybe<Scalars['String']>;
};

export type CancelInProgressJourneyError = {
  __typename: 'CancelInProgressJourneyError';
  message: Scalars['String'];
};

export type CancelInProgressJourneyResult = {
  __typename: 'CancelInProgressJourneyResult';
  message: Scalars['String'];
};

export type CancelInProgressJourneyResultCancelInProgressJourneyError = CancelInProgressJourneyResult | CancelInProgressJourneyError;

export type CancellationRequestInput = {
  postcode: Scalars['String'];
  addressLineOne: Scalars['String'];
  city: Scalars['String'];
  reason: Scalars['String'];
  collectionDate: Scalars['Date'];
  addressLineTwo?: Maybe<Scalars['String']>;
  subReason?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  theBikeOverall?: Maybe<Scalars['Int']>;
  theLevelOfPower?: Maybe<Scalars['Int']>;
  theBatteryRange?: Maybe<Scalars['Int']>;
  feedback?: Maybe<Scalars['String']>;
  chargeNewFlexiblePlan?: Maybe<Scalars['Boolean']>;
};

export type CancellationTerms = {
  __typename: 'CancellationTerms';
  /** How much notice we require to cancel this subscription. Typically 30 days. */
  noticePeriodDays: Scalars['Int'];
  /** If provided, the earliest date a subscriber could give notice to unsubscribe. If not provided, they can cancel whenever they like, with the notice period. */
  earliestDate?: Maybe<Scalars['Date']>;
};

export type ChangePasswordError = {
  __typename: 'ChangePasswordError';
  message: Scalars['String'];
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChangePasswordResult = {
  __typename: 'ChangePasswordResult';
  message: Scalars['String'];
};

export type ChangePasswordResultChangePasswordError = ChangePasswordResult | ChangePasswordError;

export type ChangePaymentDateProrata = {
  __typename: 'ChangePaymentDateProrata';
  chargeAmount: Scalars['Int'];
  chargeFromDate: Scalars['Date'];
  chargeToDate: Scalars['Date'];
  theftFeeAddOnChargeAmount?: Maybe<Scalars['Int']>;
};

export type ChangePaymentDateProrataApiError = ChangePaymentDateProrata | ApiError;

export type ChangingPlanData = {
  packageSlug: Scalars['String'];
  discountCode?: Maybe<Scalars['String']>;
  bikeId?: Maybe<Scalars['Int']>;
  swapOptions?: Maybe<DeliveryOptionsInput>;
  fullTheftCoverage?: Maybe<Scalars['Boolean']>;
  cycleSchemeRedemptionCode?: Maybe<Scalars['String']>;
};

export type ChangingPlanQuote = {
  __typename: 'ChangingPlanQuote';
  currentPackage: Package;
  newPackage: Package;
  newSubscription: MonthlyQuoteFixedTermQuote;
  nextMonthlyInvoiceDate: Scalars['Date'];
  additionalProRataInPence: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  appliedDiscountCode?: Maybe<Scalars['String']>;
  theftFeeAddOnPriceInPence?: Maybe<Scalars['Int']>;
};

export type ChangingPlanQuoteApiError = ChangingPlanQuote | ApiError;

export type City = {
  __typename: 'City';
  id: Scalars['Int'];
  name: Scalars['String'];
  timezone: Scalars['String'];
  currency: Scalars['String'];
  country: Scalars['String'];
  isDefault: Scalars['Boolean'];
  postalAreaList?: Maybe<Scalars['String']>;
  postalDistrictList?: Maybe<Scalars['String']>;
};

export type CityDetailsWithBikes = {
  __typename: 'CityDetailsWithBikes';
  id: Scalars['Int'];
  city: City;
  bikes: Array<Maybe<BikeType>>;
};

export type CollectionOrderReschedulingInvoiceSummary = {
  __typename: 'CollectionOrderReschedulingInvoiceSummary';
  prorataPayment: Scalars['String'];
  collectionCharge: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
};

export type CollectionOrderReschedulingInvoiceSummaryApiError = CollectionOrderReschedulingInvoiceSummary | ApiError;

/** Options for a subscription with a minimum term */
export type CommitmentTerms = {
  __typename: 'CommitmentTerms';
  /** Minimum period subscriber must continue their subscription, in months */
  lengthMonths: Scalars['Int'];
  /** If provided, the monthly price in pence the subscriber will move to after this term is complete. If not provided, the select package renews instead. */
  monthlyRateAfterwards?: Maybe<Scalars['Int']>;
};

export enum ConfidenceInput {
  NeverBefore = 'NEVER_BEFORE',
  Beginner = 'BEGINNER',
  Moderate = 'MODERATE',
  Experienced = 'EXPERIENCED'
}

export type CreateAccountInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumberMobile: Scalars['String'];
  homeAddress: AddressInput;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<GenderInput>;
  cyclingConfidence?: Maybe<ConfidenceInput>;
  heightCm?: Maybe<Scalars['Decimal']>;
  title?: Maybe<Scalars['String']>;
  selectedBike?: Maybe<Scalars['String']>;
  promotionCode?: Maybe<Scalars['String']>;
  nationalInsurance?: Maybe<Scalars['String']>;
  requestUrl?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  email: Scalars['String'];
  wantsMarketingEmails: Scalars['Boolean'];
  hdyhau?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
};

export type CreateAccountResult = {
  __typename: 'CreateAccountResult';
  accessToken: Scalars['String'];
  viewer: Viewer;
};

export type CreateAccountResultApiError = CreateAccountResult | ApiError;

export type CreateAccountResultCreateUserErrorDuplicate = CreateAccountResult | CreateUserErrorDuplicate;

export type CreateSubscriptionFailed = {
  __typename: 'CreateSubscriptionFailed';
  errorMessage: Scalars['String'];
};

export type CreateSubscriptionInput = {
  priceOptions: QuoteOptionsInput;
  deliveryOptions: DeliveryOptionsInput;
  setupIntentId: Scalars['String'];
  selectedKitItems?: Maybe<Array<SelectedKitItem>>;
  bikeId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  fullTheftCoverage?: Maybe<Scalars['Boolean']>;
};

export type CreateSubscriptionStarted = {
  __typename: 'CreateSubscriptionStarted';
  id: Scalars['UUID'];
};

export type CreateSubscriptionStartedCreateSubscriptionFailed = CreateSubscriptionStarted | CreateSubscriptionFailed;

export type CreateUserErrorDuplicate = {
  __typename: 'CreateUserErrorDuplicate';
  message: Scalars['String'];
};




export type DeliveryOptionsInput = {
  requestedDeliveryDate: Scalars['String'];
  addressLineOne: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type DeliveryOrderReschedulingInvoiceSummary = {
  __typename: 'DeliveryOrderReschedulingInvoiceSummary';
  deliveryCharge: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
};

export type DeliveryOrderReschedulingInvoiceSummaryApiError = DeliveryOrderReschedulingInvoiceSummary | ApiError;

export type DeliveryQuote = {
  __typename: 'DeliveryQuote';
  /** The cost in pence for Delivery */
  cost: Scalars['Int'];
  /** If included, the cost of standard Delivery in pence - used to indicate if delivery price has been reduced */
  comparativeCost?: Maybe<Scalars['Int']>;
  /** When the bike is expected to arrive */
  deliveryDate: Scalars['Date'];
};

export type DiscountCodeType = {
  __typename: 'DiscountCodeType';
  discountCode: Scalars['String'];
  duration: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amountOff?: Maybe<Scalars['Int']>;
  percentOff?: Maybe<Scalars['Int']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  freeDelivery: Scalars['Boolean'];
};

export type EchoError = {
  __typename: 'EchoError';
  error: Scalars['String'];
};

export type EchoInput = {
  message: Scalars['String'];
  /** If set to true, the mutation will fail */
  fail?: Maybe<Scalars['Boolean']>;
};

export type EchoSuccess = {
  __typename: 'EchoSuccess';
  message: Scalars['String'];
};

export type EchoSuccessEchoError = EchoSuccess | EchoError;

export type FinalAppData = {
  durationInSeconds: Scalars['Float'];
  distanceInMetres: Scalars['Float'];
};

/** When a pay monthly subscription's first month is charged */
export enum FirstMonthCharged {
  AtCheckout = 'AT_CHECKOUT',
  DayBeforeDelivery = 'DAY_BEFORE_DELIVERY'
}

/** Pricing for a fixed term subscription */
export type FixedTermQuote = {
  __typename: 'FixedTermQuote';
  /** Price in pence to use as the main rate. Paid up front. */
  stickerPrice: Scalars['Int'];
  /** Terms around how long a subscription must be kept for */
  commitment: CommitmentTerms;
  /** Terms to apply if cancelling this subscription */
  cancellation: CancellationTerms;
  noMaintenance?: Maybe<Scalars['Boolean']>;
};

export enum GenderInput {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER'
}

export type GeographicWaitlistInput = {
  email: Scalars['String'];
  postcode: Scalars['String'];
  bikeModelId: Scalars['Int'];
  wantsMarketingEmails?: Maybe<Scalars['Boolean']>;
};

export type GetChangingPlanQuoteInput = {
  packageSlug: Scalars['String'];
  discountCode?: Maybe<Scalars['String']>;
  changeDate?: Maybe<Scalars['String']>;
  fullTheftCoverage?: Maybe<Scalars['Boolean']>;
};

export type GetSubscriptionQuoteInput = {
  priceOptions: QuoteOptionsInput;
  deliveryDate: Scalars['Date'];
  selectedKitItems?: Maybe<Array<SelectedKitItem>>;
  bikeId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  buzzbikeRetailId?: Maybe<Scalars['Int']>;
  fullTheftCoverage?: Maybe<Scalars['Boolean']>;
};

export type InvoicePdf = {
  __typename: 'InvoicePdf';
  url: Scalars['String'];
};

export type InvoicePdfApiError = InvoicePdf | ApiError;

export type IssueReportData = {
  __typename: 'IssueReportData';
  id: Scalars['Int'];
  riderId: Scalars['Int'];
  reportNumber: Scalars['String'];
  isKeyOrder: Scalars['Boolean'];
  description: Scalars['String'];
  appointmentStartTime: Scalars['DateTime'];
  appointmentEndTime: Scalars['DateTime'];
  initialStartTime: Scalars['DateTime'];
  initialEndTime: Scalars['DateTime'];
  addressType: Scalars['String'];
  address: Scalars['String'];
  status: Scalars['String'];
  assigneeName?: Maybe<Scalars['String']>;
  assigneeAvatarUrl?: Maybe<Scalars['String']>;
  becameCancelled?: Maybe<Scalars['DateTime']>;
  becameCompleted?: Maybe<Scalars['DateTime']>;
  categoryId?: Maybe<Scalars['String']>;
  categoryLabel?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
};

export type IssueReportDataApiError = IssueReportData | ApiError;

export type IssueReportInput = {
  categoryId?: Maybe<Scalars['String']>;
  categoryLabel?: Maybe<Scalars['String']>;
  issueTypeId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  addressType: Scalars['String'];
  address: Scalars['String'];
  images?: Maybe<Array<Scalars['String']>>;
  bikeNumber?: Maybe<Scalars['String']>;
  isKeyOrder?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subcategory?: Maybe<Scalars['String']>;
};

export type IssueReportList = {
  __typename: 'IssueReportList';
  issueReports: Array<IssueReportData>;
};

export type IssueReportListApiError = IssueReportList | ApiError;

export type IssueReportType = {
  __typename: 'IssueReportType';
  reportNumber: Scalars['String'];
  address: Scalars['String'];
  appointmentStartTime: Scalars['DateTime'];
  appointmentEndTime: Scalars['DateTime'];
  category?: Maybe<Scalars['String']>;
  subcategory?: Maybe<Scalars['String']>;
};

export enum JourneyUploadAsyncProgressStatus {
  Empty = 'EMPTY',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Cancelled = 'CANCELLED'
}

export type JourneyUploadError = {
  __typename: 'JourneyUploadError';
  message: Scalars['String'];
};

export type JourneyUploadInput = {
  journeyId: Scalars['UUID'];
  uploadedAt: Scalars['DateTime'];
  metadata: Array<MetadataItem>;
  measurements?: Maybe<Array<Measurement>>;
  isFinal?: Maybe<Scalars['Boolean']>;
  finalAppData?: Maybe<FinalAppData>;
};

export type JourneyUploadResult = {
  __typename: 'JourneyUploadResult';
  message: Scalars['String'];
};

export type JourneyUploadResultJourneyUploadError = JourneyUploadResult | JourneyUploadError;

export type KitCategoryType = {
  __typename: 'KitCategoryType';
  kitCategoryId: Scalars['Int'];
  categoryName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type KitImageType = {
  __typename: 'KitImageType';
  imageUrl: Scalars['String'];
  thumbnailImageUrl?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
};

export type KitInventoryType = {
  __typename: 'KitInventoryType';
  kitInventoryId: Scalars['Int'];
  code: Scalars['String'];
  name: Scalars['String'];
  rrpPrice?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  size?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  isSellingFast?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<KitImageType>>;
};

export type KitItemType = {
  __typename: 'KitItemType';
  kitDescriptionId: Scalars['Int'];
  name: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  whyWeLoveIt?: Maybe<Scalars['String']>;
  bestFor?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  brandLogoUrl?: Maybe<Scalars['String']>;
  featuresAndBenefits?: Maybe<Scalars['String']>;
  technicalDetails?: Maybe<Scalars['String']>;
  whatsIncluded?: Maybe<Scalars['String']>;
  returns?: Maybe<Scalars['String']>;
  sizeGuide?: Maybe<Scalars['String']>;
  kitCategory: KitCategoryType;
  variations?: Maybe<Array<KitInventoryType>>;
};

export type KitOrderTypeInput = {
  orderDate: Scalars['String'];
  addressLineOne: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type KlarnaObject = {
  __typename: 'KlarnaObject';
  url: Scalars['String'];
};

export type KlarnaObjectApiError = KlarnaObject | ApiError;

export type LoyaltyRewardDiscount = {
  __typename: 'LoyaltyRewardDiscount';
  name: Scalars['String'];
  duration: Scalars['String'];
  amountOff?: Maybe<Scalars['Int']>;
  percentOff?: Maybe<Scalars['Int']>;
  durationInMonths?: Maybe<Scalars['Int']>;
};

export type Measurement = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  timestamp: Scalars['DateTime'];
  paused?: Maybe<Scalars['Boolean']>;
};

export type MetadataItem = {
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Pricing for a pay monthly subscription */
export type MonthlyQuote = {
  __typename: 'MonthlyQuote';
  /** Price in pence to use as the main rate */
  stickerPrice: Scalars['Int'];
  /** If this price was discounted, what rate does it revert to after some period of time */
  revertsTo?: Maybe<MonthlyRevertToQuote>;
  /** When would this subscription's first monthly bill be charged */
  charged: FirstMonthCharged;
  /** Terms around how long a subscription must be kept for */
  commitment?: Maybe<CommitmentTerms>;
  /** Terms to apply if cancelling this subscription */
  cancellation: CancellationTerms;
  noMaintenance?: Maybe<Scalars['Boolean']>;
};

export type MonthlyQuoteFixedTermQuote = MonthlyQuote | FixedTermQuote;

/** What price a discounted rate later reverts to */
export type MonthlyRevertToQuote = {
  __typename: 'MonthlyRevertToQuote';
  /** Price in pence to use as the main rate */
  stickerPrice: Scalars['Int'];
  /** After how many months does this rate apply. If not provided, this is a permanent discount. */
  delayMonths?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename: 'Mutation';
  /** Example mutation that can pass or fail depending on input */
  echo: EchoSuccessEchoError;
  /** Start the process of creating a subscription. As this takes time, an ID is provided which can be used to subsequently check in on the progress of the creation process. */
  startSubscriptionCreation: CreateSubscriptionStartedCreateSubscriptionFailed;
  /** Start the process of setting up payment. No customer record is created. */
  startSetupStripePaymentAnonymous: SetupIntentConfig;
  /** Start the process of changing a subscriber's payment details. */
  startEditStripePayment: SetupIntentConfig;
  /** To be called after calling start edit stripe payment */
  finishEditStripePayment: PaymentDetails;
  /** Used to update billing address */
  updateBillingAddress: UpdateBillingAddressResult;
  /** Set up an account for a new user */
  createAccount: CreateAccountResultCreateUserErrorDuplicate;
  /** Update the account of the logged-in user */
  updateAccount: Viewer;
  /** Change password of the logged-in user */
  changePassword: ChangePasswordResultChangePasswordError;
  /** Reset password */
  resetPassword: ResetPasswordResultResetPasswordError;
  /** Redeem reset password token */
  redeemResetPasswordToken: ResetPasswordErrorResetPasswordResult;
  /** Takes an authenticated user and starts a new journey, returning a journey UUID. It should close off any incomplete journeys from the same user */
  startANewJourney?: Maybe<NewJourneyStarted>;
  /** Add a segment of measurements to an ongoing journey, taking the measurements, an authenticated user and an optional flag indicating if this is the final segment.Measurements are best provided as a big encoded string */
  uploadJourney: JourneyUploadResultJourneyUploadError;
  /** Support cancelling an in-progress journey */
  cancelInProgressJourney: CancelInProgressJourneyResultCancelInProgressJourneyError;
  /** Update User Marketing Preferences */
  updateMarketingPreferences: Scalars['String'];
  /** Rejoiner */
  createRejoiner: CreateAccountResultApiError;
  /** Change Plan */
  changePlan: SubscribedPackagesApiError;
  /** Update Delivery Information */
  updateKitOrder: ApiResultApiError;
  /** Update Collection Information */
  rescheduleCollectionOrder: ApiResultApiError;
  /** Update Rider Information */
  updateRider: ApiResultApiError;
  /** Join the waitlist */
  joinTheWaitlist: ApiResultApiError;
  /** Join the Geographic waitlist */
  joinTheGeoggraphicWaitlist: ApiResultApiError;
  /** Join B2B waitlist */
  joinB2bWaitlist: ApiResultApiError;
  /** Cancel changing plan */
  cancelChangingPlan: ApiResultApiError;
  /** Cancel changing plan */
  rescheduleChangingPlan: ApiResultApiError;
  /** Apply retention discount */
  applyRetentionDiscount: StripeUpcomingInvoiceApiError;
  /** Claim Loyalty Reward */
  claimLoyaltyReward: StripeUpcomingInvoiceIssueReportTypeApiError;
  /** Pause collection payment */
  pauseCollectionPayment: StripeUpcomingInvoiceApiError;
  /** delete account */
  deleteAccount: ApiResultApiError;
  /** Buy your bike */
  buyYourBike: InvoicePdfApiError;
  /** Report an issue */
  reportAnIssue: ApiResultApiError;
  /** Reschedule an issue */
  rescheduleAnIssue: IssueReportDataApiError;
  /** Cancel an issue */
  cancelAnIssue: IssueReportDataApiError;
  /** Revert cancellation */
  revertCancellation: ApiResultApiError;
  createKlarnaUrl: KlarnaObjectApiError;
  createKlarnaUrlForBuyingYourBike: KlarnaObjectApiError;
  createKlarnaUrlForCancellation: KlarnaObjectApiError;
  changePaymentDate: ApiResultApiError;
  subscribeTheftFeeAddOn: SubscriptionTheftFeeAddOnTypeApiError;
  cancelTheftFeeAddOn: ApiResultApiError;
};


export type MutationEchoArgs = {
  input: EchoInput;
};


export type MutationStartSubscriptionCreationArgs = {
  input: CreateSubscriptionInput;
  tmpFakeSuccess?: Maybe<Scalars['Boolean']>;
};


export type MutationFinishEditStripePaymentArgs = {
  setupIntentId: Scalars['String'];
};


export type MutationUpdateBillingAddressArgs = {
  input: UpdateBillingAddressInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRedeemResetPasswordTokenArgs = {
  input: RedeemResetPasswordTokenInput;
};


export type MutationStartANewJourneyArgs = {
  input: NewJourneyUploadInput;
};


export type MutationUploadJourneyArgs = {
  input: JourneyUploadInput;
};


export type MutationCancelInProgressJourneyArgs = {
  journeyId: Scalars['UUID'];
};


export type MutationUpdateMarketingPreferencesArgs = {
  email: Scalars['String'];
  optedIn: Scalars['Boolean'];
};


export type MutationChangePlanArgs = {
  input: ChangingPlanData;
};


export type MutationUpdateKitOrderArgs = {
  input: DeliveryOptionsInput;
};


export type MutationRescheduleCollectionOrderArgs = {
  input: OrderReschedulingInput;
};


export type MutationUpdateRiderArgs = {
  input: UpdateRiderInput;
};


export type MutationJoinTheWaitlistArgs = {
  input: WaitlistInput;
};


export type MutationJoinTheGeoggraphicWaitlistArgs = {
  input: GeographicWaitlistInput;
};


export type MutationJoinB2bWaitlistArgs = {
  input: B2BWaitlistInput;
};


export type MutationRescheduleChangingPlanArgs = {
  rescheduleDate: Scalars['String'];
};


export type MutationApplyRetentionDiscountArgs = {
  discountCode: Scalars['String'];
};


export type MutationClaimLoyaltyRewardArgs = {
  rewardKey: RewardJourneyKey;
  buzziversaryOrder?: Maybe<KitOrderTypeInput>;
};


export type MutationPauseCollectionPaymentArgs = {
  months: Scalars['Int'];
  chargeAmountInPence: Scalars['Int'];
};


export type MutationBuyYourBikeArgs = {
  priceInPence?: Maybe<Scalars['Int']>;
};


export type MutationReportAnIssueArgs = {
  input: IssueReportInput;
};


export type MutationRescheduleAnIssueArgs = {
  input: RescheduleIssueReportInput;
};


export type MutationCancelAnIssueArgs = {
  issueReportId: Scalars['Int'];
};


export type MutationCreateKlarnaUrlArgs = {
  invoiceId: Scalars['String'];
  redirectUrl: Scalars['String'];
};


export type MutationCreateKlarnaUrlForBuyingYourBikeArgs = {
  redirectUrl: Scalars['String'];
};


export type MutationCreateKlarnaUrlForCancellationArgs = {
  redirectUrl: Scalars['String'];
  data: CancellationRequestInput;
};


export type MutationChangePaymentDateArgs = {
  requestedDay: Scalars['Int'];
};

export type NewJourneyStarted = {
  __typename: 'NewJourneyStarted';
  journeyId: Scalars['UUID'];
};

export type NewJourneyUploadInput = {
  metadata: Array<MetadataItem>;
};

export type OrderReschedulingInput = {
  orderDate: Scalars['String'];
  addressLineOne: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type Package = {
  __typename: 'Package';
  /** Identifier for the package */
  packageSlug: Scalars['String'];
  /** Price in pence before any discount */
  basePrice: Scalars['Int'];
  /** Price in pence to use as the main rate */
  stickerPrice: Scalars['Int'];
  /** Usually true; if not, this package is paid in advance for e.g. 6/12 months */
  paidMonthly: Scalars['Boolean'];
  /** If provided, this package covers a particular commitment length */
  termLengthMonths?: Maybe<Scalars['Int']>;
  /** Amount in pence to use when quoting a package's saving vs. some 'standard' rate */
  savingAmount?: Maybe<Scalars['Int']>;
  /** Comparative price in pence for displaying purpose only */
  comparativeBasePrice?: Maybe<Scalars['Int']>;
  /** Amount in pence for Setup */
  setupFeeInPence?: Maybe<Scalars['Int']>;
  /** Amount in pence for Delivery */
  deliveryFeeInPence?: Maybe<Scalars['Int']>;
  /** Amount in pence for Collection */
  collectionFeeInPence?: Maybe<Scalars['Int']>;
  /** bike type based on selected package */
  bike?: Maybe<BikeType>;
  /** Description for the package */
  description?: Maybe<Scalars['String']>;
  couponToApplyAtEndOfMinimumTerm?: Maybe<StripeCoupon>;
  appliedDiscountCode?: Maybe<DiscountCodeType>;
  noMaintenance?: Maybe<Scalars['Boolean']>;
};

export type PausedCollectionPaymentType = {
  __typename: 'PausedCollectionPaymentType';
  pausedAt: Scalars['Date'];
  resumesAt: Scalars['Date'];
  chargeAmountInPence?: Maybe<Scalars['Int']>;
  chargeDate?: Maybe<Scalars['Date']>;
  charged?: Maybe<Scalars['Boolean']>;
};

export type PaymentDetails = {
  __typename: 'PaymentDetails';
  id: Scalars['String'];
  last4: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  cardholderName: Scalars['String'];
};

export type PaymentMethod = {
  __typename: 'PaymentMethod';
  brand: Scalars['String'];
  last4: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  cardholderName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  billingDetails?: Maybe<BillingDetails>;
};

export type PaymentMethodApiError = PaymentMethod | ApiError;

export type PricingPackages = {
  __typename: 'PricingPackages';
  defaultPackage: Package;
  otherPackages: Array<Package>;
};

export type Query = {
  __typename: 'Query';
  /** Send back a message */
  hello: Scalars['String'];
  /** Fields available for the logged-in user */
  viewer: Viewer;
  /** check if given email has an account */
  checkEmailHasAccount: Scalars['Boolean'];
  /** Get list of all cities */
  cities: Array<City>;
  /** Get list of bikes for a city or all cities */
  bikes: Array<CityDetailsWithBikes>;
  /** Get a pricing breakdown for a new subscription */
  getSubscriptionQuote?: Maybe<SubscriptionQuote>;
  /** Get the current state of a subscription being created */
  subscriptionCreationState: SubscriptionCreationState;
  /** Which pricing packages should be shown in the checkout */
  pricingPackages: PricingPackages;
  /** Get available dates for bike delivery */
  availableDeliveryDates: Array<Scalars['Date']>;
  /** Get available dates for bike collection */
  availableCollectionDates: Array<Scalars['Date']>;
  /** Get available dates for bike swap */
  availableSwapDates: Array<Scalars['Date']>;
  /** List the options for the How Did You Hear About Us? question */
  hdyhauOptions: Array<Scalars['String']>;
  /** Get the payment details */
  paymentDetails?: Maybe<PaymentMethod>;
  /** Get outstanding invoices */
  outstandingInvoices: Array<SummarisedInvoice>;
  /** Get upcoming invoice */
  upcomingInvoice: Array<UpcomingInvoice>;
  /** Get reset password token */
  getResetPasswordToken: ResetPasswordErrorResetPasswordToken;
  /** get pricing package by slug */
  getPricingPackage: Package;
  /** API for inferring overall status of a journey */
  getStatusOfAJourney: JourneyUploadAsyncProgressStatus;
  /** API for getting journey stats */
  getJourneyStats: RideSummary;
  /** Get kit shop items */
  getKitAvailability: Array<KitItemType>;
  /** Get recommended packages when downgrade or upgrade plans */
  getRecommendedPackages: Array<Package>;
  /** Get no maintenance packages */
  getNoMaintenancePackages: Array<Package>;
  /** Get subscribed packages */
  getSubscribedPackages: SubscribedPackagesApiError;
  /** Get bikes */
  getBikeAvailability: Array<KitItemType>;
  /** Get a pricing breakdown for changing plan */
  getChangingPlanQuote: ChangingPlanQuoteApiError;
  /** Preview buying your bike */
  previewBuyingYourBike: BuyingBikeInvoiceSummaryApiError;
  /** Get rescheduling delivery order invoice summary */
  rescheduleDeliveryOrder?: Maybe<DeliveryOrderReschedulingInvoiceSummaryApiError>;
  /** Get collection order rescheduling invoice summary */
  rescheduleCollectionOrder?: Maybe<CollectionOrderReschedulingInvoiceSummaryApiError>;
  /** Get issue reports */
  getIssueReports: IssueReportListApiError;
  /** Get available dates for bike prof */
  availableBikeProfDates: SlotPairListApiError;
  /** Get company info from redemption code */
  validateRedemptionCode: ApiErrorRedemptionCodeInfo;
  getRenewCycleSchemePlanQuote: ApiErrorRenewCycleSchemePlanQuote;
  /** Get default payment method */
  getDefaultPaymentMethod: PaymentMethodApiError;
  /** Get Buzzbike retails */
  getBuzzbikeRetails: Array<BuzzbikeRetailData>;
  changePaymentDate: ChangePaymentDateProrataApiError;
  /** Get theft fee price or subscribed theft fee add on */
  getTheftFeeAddOnPrice?: Maybe<TheftFeeAddOnPriceTypeApiError>;
  /** Get theft fee price or subscribed theft fee add on */
  getTheftFeeAddOnPriceAtSingup?: Maybe<TheftFeeAddOnPriceTypeApiError>;
  /** Get theft fee price or subscribed theft fee add on */
  getSubscriptionTheftFeeAddOn?: Maybe<SubscriptionTheftFeeAddOnTypeApiError>;
  /** Get Buying You Buzzbike invoice (Klarna) */
  getBuyingYourBuzzbikeInvoiceUrl: InvoicePdfApiError;
};


export type QueryHelloArgs = {
  world: Scalars['String'];
};


export type QueryCheckEmailHasAccountArgs = {
  email: Scalars['String'];
};


export type QueryBikesArgs = {
  cityId?: Maybe<Scalars['Int']>;
};


export type QueryGetSubscriptionQuoteArgs = {
  input: GetSubscriptionQuoteInput;
};


export type QuerySubscriptionCreationStateArgs = {
  id: Scalars['UUID'];
};


export type QueryPricingPackagesArgs = {
  discountCode?: Maybe<Scalars['String']>;
  bikeId?: Maybe<Scalars['Int']>;
  packageGroupName?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['Int']>;
};


export type QueryAvailableDeliveryDatesArgs = {
  bikeId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  buzzbikeRetailId?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
};


export type QueryAvailableCollectionDatesArgs = {
  buzzbikeRetailId?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
};


export type QueryAvailableSwapDatesArgs = {
  cityId?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
};


export type QueryGetResetPasswordTokenArgs = {
  token: Scalars['String'];
};


export type QueryGetPricingPackageArgs = {
  packageSlug: Scalars['String'];
  discountCode?: Maybe<Scalars['String']>;
};


export type QueryGetStatusOfAJourneyArgs = {
  journeyId: Scalars['UUID'];
};


export type QueryGetJourneyStatsArgs = {
  journeyId: Scalars['UUID'];
};


export type QueryGetKitAvailabilityArgs = {
  bikeId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
};


export type QueryGetRecommendedPackagesArgs = {
  packageGroupName?: Maybe<Scalars['String']>;
  bikeId?: Maybe<Scalars['Int']>;
  freeMonthsPromo?: Maybe<Scalars['Boolean']>;
};


export type QueryGetChangingPlanQuoteArgs = {
  input: GetChangingPlanQuoteInput;
};


export type QueryPreviewBuyingYourBikeArgs = {
  priceInPence?: Maybe<Scalars['Int']>;
};


export type QueryRescheduleDeliveryOrderArgs = {
  input: OrderReschedulingInput;
};


export type QueryRescheduleCollectionOrderArgs = {
  input: OrderReschedulingInput;
};


export type QueryAvailableBikeProfDatesArgs = {
  postcode?: Maybe<Scalars['String']>;
};


export type QueryValidateRedemptionCodeArgs = {
  redemptionCode: Scalars['String'];
  isCyclescheme?: Scalars['Boolean'];
};


export type QueryGetRenewCycleSchemePlanQuoteArgs = {
  redemptionCode: Scalars['String'];
};


export type QueryGetBuzzbikeRetailsArgs = {
  cityId: Scalars['Int'];
};


export type QueryChangePaymentDateArgs = {
  requestedDay: Scalars['Int'];
};


export type QueryGetTheftFeeAddOnPriceArgs = {
  bikeId?: Maybe<Scalars['Int']>;
};


export type QueryGetTheftFeeAddOnPriceAtSingupArgs = {
  bikeId?: Maybe<Scalars['Int']>;
};

export type QuoteOptionsInput = {
  packageSlug: Scalars['String'];
  discountCode?: Maybe<Scalars['String']>;
};

export type RedeemResetPasswordTokenInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
  nextPath?: Maybe<Scalars['String']>;
};

export type RedemptionCodeInfo = {
  __typename: 'RedemptionCodeInfo';
  companyId: Scalars['Int'];
  companyName: Scalars['String'];
  companyCode?: Maybe<Scalars['String']>;
  bike?: Maybe<BikeType>;
};

export type RenewCycleSchemePlanQuote = {
  __typename: 'RenewCycleSchemePlanQuote';
  renewType: RenewType;
  currentPackageId: Scalars['Int'];
  currentPackageSlug: Scalars['String'];
  currentMonthlyPayment: Scalars['String'];
  currentBikeType: BikeType;
  currentPlanStartDate: Scalars['Date'];
  currentPlanEndDate: Scalars['Date'];
  currentSwitchRolling?: Maybe<SwitchPackage>;
  newPackageId: Scalars['Int'];
  newPackageSlug: Scalars['String'];
  newMonthlyPayment: Scalars['String'];
  newBikeType: BikeType;
  newPlanStartDate: Scalars['Date'];
  newPlanEndDate: Scalars['Date'];
  newSwitchRolling?: Maybe<SwitchPackage>;
};

export enum RenewType {
  Renew = 'RENEW',
  Upgrade = 'UPGRADE',
  Downgrade = 'DOWNGRADE'
}

export type RescheduleIssueReportInput = {
  issueReportId: Scalars['Int'];
  orderDate: Scalars['String'];
  address: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type ResetPasswordError = {
  __typename: 'ResetPasswordError';
  message: Scalars['String'];
};

export type ResetPasswordErrorResetPasswordResult = ResetPasswordError | ResetPasswordResult;

export type ResetPasswordErrorResetPasswordToken = ResetPasswordError | ResetPasswordToken;

export type ResetPasswordInput = {
  email: Scalars['String'];
  nextPath?: Maybe<Scalars['String']>;
};

export type ResetPasswordResult = {
  __typename: 'ResetPasswordResult';
  message: Scalars['String'];
};

export type ResetPasswordResultResetPasswordError = ResetPasswordResult | ResetPasswordError;

export type ResetPasswordToken = {
  __typename: 'ResetPasswordToken';
  isRedeemed: Scalars['Boolean'];
  token: Scalars['String'];
  nextPath?: Maybe<Scalars['String']>;
};

export enum RewardJourneyKey {
  Month_3 = 'MONTH_3',
  Month_6 = 'MONTH_6',
  Month_9 = 'MONTH_9',
  Month_12 = 'MONTH_12'
}

export enum RewardState {
  NotAvailable = 'NOT_AVAILABLE',
  Available = 'AVAILABLE',
  Claimed = 'CLAIMED',
  Expired = 'EXPIRED'
}

export type RideSummary = {
  __typename: 'RideSummary';
  id: Scalars['Int'];
  time: Scalars['Float'];
  saved: Scalars['Float'];
  distance: Scalars['Float'];
  carbon: Scalars['Float'];
  calories: Scalars['Float'];
  averageSpeed: Scalars['Float'];
  streak: Streak;
  totals: Totals;
};

export type SelectedKitItem = {
  kitInventoryId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type SetupIntentConfig = {
  __typename: 'SetupIntentConfig';
  setupIntentId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type SingleRewardStepType = {
  __typename: 'SingleRewardStepType';
  key: RewardJourneyKey;
  state: RewardState;
  discount?: Maybe<LoyaltyRewardDiscount>;
};

export type SlotPairList = {
  __typename: 'SlotPairList';
  availableSlots: Array<SlotPairType>;
};

export type SlotPairListApiError = SlotPairList | ApiError;

export type SlotPairType = {
  __typename: 'SlotPairType';
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type Streak = {
  __typename: 'Streak';
  number: Scalars['Int'];
  message: Scalars['String'];
};

export type StripeCoupon = {
  __typename: 'StripeCoupon';
  id: Scalars['String'];
  name: Scalars['String'];
  duration: Scalars['String'];
  durationInMonths?: Maybe<Scalars['Int']>;
  amountOff?: Maybe<Scalars['Int']>;
  percentOff?: Maybe<Scalars['Int']>;
};

export type StripeDiscount = {
  __typename: 'StripeDiscount';
  start: Scalars['DateTime'];
  coupon: StripeCoupon;
  end?: Maybe<Scalars['DateTime']>;
};

export type StripeUpcomingInvoice = {
  __typename: 'StripeUpcomingInvoice';
  total: Scalars['Int'];
  periodStart: Scalars['DateTime'];
  periodEnd: Scalars['DateTime'];
  nextPaymentAttempt: Scalars['DateTime'];
  discount?: Maybe<StripeDiscount>;
};

export type StripeUpcomingInvoiceApiError = StripeUpcomingInvoice | ApiError;

export type StripeUpcomingInvoiceIssueReportTypeApiError = StripeUpcomingInvoice | IssueReportType | ApiError;

export type SubscribedPackage = {
  __typename: 'SubscribedPackage';
  /** Identifier for the package */
  packageSlug: Scalars['String'];
  /** Price in pence before any discount */
  basePrice: Scalars['Int'];
  /** Price in pence to use as the main rate */
  stickerPrice: Scalars['Int'];
  /** Usually true; if not, this package is paid in advance for e.g. 6/12 months */
  paidMonthly: Scalars['Boolean'];
  /** If provided, this package covers a particular commitment length */
  termLengthMonths?: Maybe<Scalars['Int']>;
  /** Amount in pence to use when quoting a package's saving vs. some 'standard' rate */
  savingAmount?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  currentPeriodStart?: Maybe<Scalars['DateTime']>;
  currentPeriodEnd?: Maybe<Scalars['DateTime']>;
  discountEnds?: Maybe<Scalars['DateTime']>;
  selectedBike?: Maybe<BikeType>;
  pausedCollectionPayment?: Maybe<PausedCollectionPaymentType>;
  /** Amount in pence for Collection */
  collectionFeeInPence?: Maybe<Scalars['Int']>;
  couponToApplyAtEndOfMinimumTerm?: Maybe<StripeCoupon>;
  noMaintenance?: Maybe<Scalars['Boolean']>;
};

export type SubscribedPackages = {
  __typename: 'SubscribedPackages';
  currentPackage?: Maybe<SubscribedPackage>;
  nextPackage?: Maybe<SubscribedPackage>;
};

export type SubscribedPackagesApiError = SubscribedPackages | ApiError;

/** A Buzzbike subscription */
export type Subscription = {
  __typename: 'Subscription';
  subscriptionCode: Scalars['String'];
  generatedReferralCode: Scalars['String'];
  status: SubscriptionState;
  phase: SubscriptionPhase;
  requestedDeliveryDate: Scalars['Date'];
  startDate: Scalars['Date'];
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
  canChangeBikeType: Scalars['Boolean'];
  canChangeSubscriptionPackage: Scalars['Boolean'];
  canApplyRetentionDiscount: Scalars['Boolean'];
  canPauseCollectionPayment: Scalars['Boolean'];
  canRevertCancellation: Scalars['Boolean'];
  canSwitchToNoMaintenance: Scalars['Boolean'];
  canRejoin: Scalars['Boolean'];
  isBadPayer: Scalars['Boolean'];
  isCycleScheme: Scalars['Boolean'];
  city: City;
  canBuyYourBike: Scalars['Boolean'];
  riderTenureInMonth: Scalars['Int'];
  rewards: Array<SingleRewardStepType>;
  boughtTheBikeWithPrice?: Maybe<Scalars['Int']>;
  packageSlug?: Maybe<Scalars['String']>;
  packageBasePriceInPence?: Maybe<Scalars['Int']>;
  packageMinimumTermLengthInMonths?: Maybe<Scalars['Int']>;
  discountCode?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Address>;
  deliveryNote?: Maybe<Scalars['String']>;
  deliveryBuzzbikeRetailId?: Maybe<Scalars['Int']>;
  deliveryStartTime?: Maybe<Scalars['String']>;
  deliveryEndTime?: Maybe<Scalars['String']>;
  requestedCollectionDate?: Maybe<Scalars['Date']>;
  collectionAddress?: Maybe<Address>;
  collectionNote?: Maybe<Scalars['String']>;
  collectionBuzzbikeRetailId?: Maybe<Scalars['Int']>;
  collectionStartTime?: Maybe<Scalars['String']>;
  collectionEndTime?: Maybe<Scalars['String']>;
  phaseBecamePending?: Maybe<Scalars['Date']>;
  phaseBecameActive?: Maybe<Scalars['Date']>;
  phaseBecameCancelled?: Maybe<Scalars['Date']>;
  phaseBecameChurned?: Maybe<Scalars['Date']>;
  canUnsubscribeFrom?: Maybe<Scalars['Date']>;
  retentionDiscountApplied?: Maybe<Scalars['Date']>;
  bike?: Maybe<BikeType>;
  statusBecameOnNotice?: Maybe<Scalars['Date']>;
  noticeDatePlus30Days?: Maybe<Scalars['Date']>;
  actualCollectionTime?: Maybe<Scalars['Date']>;
  actualDeliveryTime?: Maybe<Scalars['Date']>;
  verificationStatus: VerificationStatus;
  manualAllowUpgrade?: Maybe<Scalars['Boolean']>;
  suspended?: Maybe<Scalars['Boolean']>;
};

/** Current state of a subscription being created */
export type SubscriptionCreationState = {
  __typename: 'SubscriptionCreationState';
  /** If completed successfully, the newly minted Subscription */
  subscription?: Maybe<Subscription>;
  /** If there was a problem completing the set up of the Subscription, the error message */
  errorMessage?: Maybe<Scalars['String']>;
  /** Screening failed */
  screeningFailed?: Maybe<Scalars['Boolean']>;
};

export enum SubscriptionPhase {
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Active = 'ACTIVE',
  Churned = 'CHURNED'
}

/** Calculated pricing for a potential subscriber */
export type SubscriptionQuote = {
  __typename: 'SubscriptionQuote';
  /** Information on the subscription part of the quote */
  subscription: MonthlyQuoteFixedTermQuote;
  /** If included, the costing for Delivery. If not included, delivery is not part of this quote. */
  delivery?: Maybe<DeliveryQuote>;
  /** Total amount in pence due today, e.g. for delivery, kit, or up front subscription payments */
  totalDueToday: Scalars['Int'];
  /** The cost in pence for Delivery */
  collectionFeeInPence: Scalars['Int'];
  /** List of selected kit items */
  selectedKitItems?: Maybe<Array<KitItemType>>;
  /** selected kit items */
  selectedBike?: Maybe<BikeType>;
  /** The cost in pence for Setup */
  setupFeeInPence?: Maybe<Scalars['Int']>;
  couponToApplyAtEndOfMinimumTerm?: Maybe<StripeCoupon>;
  theftFeeAddOnPriceInPence?: Maybe<Scalars['Int']>;
  appliedDiscountCode?: Maybe<DiscountCodeType>;
};

export enum SubscriptionState {
  ToBeConfirmed = 'TO_BE_CONFIRMED',
  PendingDelivery = 'PENDING_DELIVERY',
  RollingContract = 'ROLLING_CONTRACT',
  FixedContract = 'FIXED_CONTRACT',
  OnNotice = 'ON_NOTICE',
  Churned = 'CHURNED',
  Cancelled = 'CANCELLED'
}

export type SubscriptionTheftFeeAddOnType = {
  __typename: 'SubscriptionTheftFeeAddOnType';
  theftFeeAddOnPriceInPence: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  canceledAt?: Maybe<Scalars['Date']>;
  canceled?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionTheftFeeAddOnTypeApiError = SubscriptionTheftFeeAddOnType | ApiError;

export type SummarisedInvoice = {
  __typename: 'SummarisedInvoice';
  id?: Maybe<Scalars['String']>;
  amountDue: Scalars['Int'];
  currency: Scalars['String'];
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['Date']>;
  created: Scalars['Date'];
  invoiceNumber: Scalars['String'];
  invoiceUrl?: Maybe<Scalars['String']>;
  invoicePdf?: Maybe<Scalars['String']>;
};

export type SwitchPackage = {
  __typename: 'SwitchPackage';
  /** Identifier for the package */
  packageSlug: Scalars['String'];
  /** Price in pence before any discount */
  basePrice: Scalars['Int'];
  /** Usually true; if not, this package is paid in advance for e.g. 6/12 months */
  paidMonthly: Scalars['Boolean'];
  /** If provided, this package covers a particular commitment length */
  termLengthMonths?: Maybe<Scalars['Int']>;
  /** Amount in pence for Delivery */
  deliveryFeeInPence?: Maybe<Scalars['Int']>;
  /** Amount in pence for Collection */
  collectionFeeInPence?: Maybe<Scalars['Int']>;
  /** Description for the package */
  description?: Maybe<Scalars['String']>;
};

export type TheftFeeAddOnPriceType = {
  __typename: 'TheftFeeAddOnPriceType';
  theftFeeAddOnPriceInPence: Scalars['Int'];
  applyFrom?: Maybe<Scalars['Date']>;
};

export type TheftFeeAddOnPriceTypeApiError = TheftFeeAddOnPriceType | ApiError;

export type Totals = {
  __typename: 'Totals';
  saved: TotalsItem;
  distance: TotalsItem;
  days: TotalsItem;
  carbon: TotalsItem;
  calories: TotalsItem;
};

export type TotalsItem = {
  __typename: 'TotalsItem';
  value: Scalars['Float'];
  message: Scalars['String'];
};


export type UpcomingInvoice = {
  __typename: 'UpcomingInvoice';
  id?: Maybe<Scalars['String']>;
  amountDue: Scalars['Int'];
  currency: Scalars['String'];
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['Date']>;
  created: Scalars['Date'];
  invoiceNumber: Scalars['String'];
  invoiceUrl?: Maybe<Scalars['String']>;
  invoicePdf?: Maybe<Scalars['String']>;
  billingCycleAnchor: Scalars['Date'];
};

export type UpdateAccountInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumberMobile: Scalars['String'];
  homeAddress: AddressInput;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<GenderInput>;
  cyclingConfidence?: Maybe<ConfidenceInput>;
  heightCm?: Maybe<Scalars['Decimal']>;
  title?: Maybe<Scalars['String']>;
  selectedBike?: Maybe<Scalars['String']>;
  promotionCode?: Maybe<Scalars['String']>;
  nationalInsurance?: Maybe<Scalars['String']>;
  requestUrl?: Maybe<Scalars['String']>;
};

export type UpdateBillingAddressInput = {
  paymentMethodId: Scalars['String'];
  city: Scalars['String'];
  line1: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type UpdateBillingAddressResult = {
  __typename: 'UpdateBillingAddressResult';
  message: Scalars['String'];
};

export type UpdateRiderInput = {
  homeAddress?: Maybe<AddressInput>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumberMobile?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  cancellationRequestUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Verification Status of rider */
export type Verification = {
  __typename: 'Verification';
  verificationStatus: VerificationStatus;
  verificationStripeErrorCode?: Maybe<Scalars['String']>;
  verificationStripeErrorMessage?: Maybe<Scalars['String']>;
  verificationSystemErrorMessage?: Maybe<Scalars['String']>;
  requiresReschedule?: Maybe<Scalars['Boolean']>;
};

export enum VerificationStatus {
  Pending = 'PENDING',
  Attempt_1St = 'ATTEMPT_1ST',
  Retry = 'RETRY',
  Attempt_2Nd = 'ATTEMPT_2ND',
  Verified = 'VERIFIED',
  NeedAction = 'NEED_ACTION',
  Rejected = 'REJECTED',
  NotRequired = 'NOT_REQUIRED'
}

export type Viewer = {
  __typename: 'Viewer';
  /** The current user's full name */
  fullName: Scalars['String'];
  email: Scalars['String'];
  riderId?: Maybe<Scalars['Int']>;
  hasSubscription: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  birthDate?: Maybe<Scalars['Date']>;
  companyName?: Maybe<Scalars['String']>;
  wantsMarketingEmails: Scalars['Boolean'];
  hdyhau?: Maybe<Scalars['String']>;
  phoneNumberMobile?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Address>;
  gender?: Maybe<GenderInput>;
  heightCm?: Maybe<Scalars['Decimal']>;
  cyclingConfidence?: Maybe<ConfidenceInput>;
  subscription?: Maybe<Subscription>;
  verification?: Maybe<Verification>;
  isRejoiner?: Maybe<Scalars['Boolean']>;
  rejoinedTimes?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  requestUrl?: Maybe<Scalars['String']>;
  cancellationRequestUrl?: Maybe<Scalars['String']>;
  isBtw: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  promotionCode?: Maybe<Scalars['String']>;
  nationalInsurance?: Maybe<Scalars['String']>;
};

export type WaitlistInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  channel?: Maybe<Scalars['String']>;
};

/* Typescript Operations */
export type DeliveryAvailabilityQueryVariables = Exact<{
  bikeId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  buzzbikeRetailId?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
}>;


export type DeliveryAvailabilityQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'availableDeliveryDates'>
);

export type CollectionAvailabilityQueryVariables = Exact<{
  buzzbikeRetailId?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
}>;


export type CollectionAvailabilityQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'availableCollectionDates'>
);

export type SwapAvailabilityQueryVariables = Exact<{
  cityId?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
}>;


export type SwapAvailabilityQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'availableSwapDates'>
);

export type SubscriptionCreationStateQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SubscriptionCreationStateQuery = (
  { __typename: 'Query' }
  & { subscriptionCreationState: (
    { __typename: 'SubscriptionCreationState' }
    & Pick<SubscriptionCreationState, 'errorMessage' | 'screeningFailed'>
    & { subscription?: Maybe<(
      { __typename: 'Subscription' }
      & SubscriptionFragment
    )> }
  ) }
);

export type GetBikeAvailabilityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBikeAvailabilityQuery = (
  { __typename: 'Query' }
  & { getBikeAvailability: Array<(
    { __typename: 'KitItemType' }
    & Pick<KitItemType, 'kitDescriptionId' | 'name' | 'shortDescription'>
    & { variations?: Maybe<Array<(
      { __typename: 'KitInventoryType' }
      & Pick<KitInventoryType, 'kitInventoryId' | 'code' | 'name'>
      & { images?: Maybe<Array<(
        { __typename: 'KitImageType' }
        & Pick<KitImageType, 'imageUrl' | 'isFeatured'>
      )>> }
    )>> }
  )> }
);

export type GetPackagesQueryVariables = Exact<{
  bikeId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  discountCode?: Maybe<Scalars['String']>;
  packageGroupName?: Maybe<Scalars['String']>;
}>;


export type GetPackagesQuery = (
  { __typename: 'Query' }
  & { pricingPackages: (
    { __typename: 'PricingPackages' }
    & { defaultPackage: (
      { __typename: 'Package' }
      & Pick<Package, 'packageSlug'>
      & PackageFragment
    ), otherPackages: Array<(
      { __typename: 'Package' }
      & Pick<Package, 'packageSlug'>
      & PackageFragment
    )> }
  ) }
);

export type GetPricingPackageQueryVariables = Exact<{
  packageSlug: Scalars['String'];
}>;


export type GetPricingPackageQuery = (
  { __typename: 'Query' }
  & { getPricingPackage: (
    { __typename: 'Package' }
    & PackageFragment
  ) }
);

export type GetKitAvailabilityQueryVariables = Exact<{
  bikeId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
}>;


export type GetKitAvailabilityQuery = (
  { __typename: 'Query' }
  & { getKitAvailability: Array<(
    { __typename: 'KitItemType' }
    & Pick<KitItemType, 'brandLogoUrl' | 'brandName' | 'description' | 'whyWeLoveIt' | 'whatsIncluded' | 'featuresAndBenefits' | 'bestFor' | 'kitDescriptionId' | 'name' | 'returns' | 'shortDescription' | 'sizeGuide' | 'technicalDetails'>
    & { kitCategory: (
      { __typename: 'KitCategoryType' }
      & Pick<KitCategoryType, 'categoryName' | 'description' | 'imageUrl' | 'kitCategoryId'>
    ), variations?: Maybe<Array<(
      { __typename: 'KitInventoryType' }
      & Pick<KitInventoryType, 'code' | 'color' | 'isSellingFast' | 'kitInventoryId' | 'name' | 'price' | 'quantity' | 'rrpPrice' | 'size'>
      & { images?: Maybe<Array<(
        { __typename: 'KitImageType' }
        & Pick<KitImageType, 'imageUrl' | 'thumbnailImageUrl' | 'isFeatured'>
      )>> }
    )>> }
  )> }
);

export type GetSubscriptionQuoteQueryVariables = Exact<{
  input: GetSubscriptionQuoteInput;
}>;


export type GetSubscriptionQuoteQuery = (
  { __typename: 'Query' }
  & { getSubscriptionQuote?: Maybe<(
    { __typename: 'SubscriptionQuote' }
    & Pick<SubscriptionQuote, 'collectionFeeInPence' | 'totalDueToday' | 'setupFeeInPence' | 'theftFeeAddOnPriceInPence'>
    & { appliedDiscountCode?: Maybe<(
      { __typename: 'DiscountCodeType' }
      & AppliedDiscountCodeFragment
    )>, couponToApplyAtEndOfMinimumTerm?: Maybe<(
      { __typename: 'StripeCoupon' }
      & StripeCouponFragment
    )>, delivery?: Maybe<(
      { __typename: 'DeliveryQuote' }
      & Pick<DeliveryQuote, 'comparativeCost' | 'cost' | 'deliveryDate'>
    )>, selectedBike?: Maybe<(
      { __typename: 'BikeType' }
      & Pick<BikeType, 'bikeId' | 'name' | 'variationName'>
      & { images?: Maybe<Array<(
        { __typename: 'KitImageType' }
        & Pick<KitImageType, 'imageUrl' | 'isFeatured' | 'thumbnailImageUrl'>
      )>> }
    )>, selectedKitItems?: Maybe<Array<(
      { __typename: 'KitItemType' }
      & Pick<KitItemType, 'bestFor' | 'brandLogoUrl' | 'brandName' | 'description' | 'kitDescriptionId' | 'name' | 'returns' | 'shortDescription' | 'sizeGuide' | 'technicalDetails' | 'whyWeLoveIt' | 'whatsIncluded'>
      & { variations?: Maybe<Array<(
        { __typename: 'KitInventoryType' }
        & Pick<KitInventoryType, 'kitInventoryId' | 'price' | 'quantity' | 'code' | 'color' | 'name' | 'rrpPrice' | 'size'>
        & { images?: Maybe<Array<(
          { __typename: 'KitImageType' }
          & Pick<KitImageType, 'imageUrl' | 'thumbnailImageUrl' | 'isFeatured'>
        )>> }
      )>>, kitCategory: (
        { __typename: 'KitCategoryType' }
        & Pick<KitCategoryType, 'categoryName' | 'description' | 'imageUrl' | 'kitCategoryId'>
      ) }
    )>>, subscription: (
      { __typename: 'MonthlyQuote' }
      & Pick<MonthlyQuote, 'noMaintenance' | 'stickerPrice' | 'charged'>
      & { cancellation: (
        { __typename: 'CancellationTerms' }
        & Pick<CancellationTerms, 'earliestDate' | 'noticePeriodDays'>
      ), monthlyCommitment?: Maybe<(
        { __typename: 'CommitmentTerms' }
        & Pick<CommitmentTerms, 'lengthMonths' | 'monthlyRateAfterwards'>
      )>, revertsTo?: Maybe<(
        { __typename: 'MonthlyRevertToQuote' }
        & Pick<MonthlyRevertToQuote, 'delayMonths' | 'stickerPrice'>
      )> }
    ) | (
      { __typename: 'FixedTermQuote' }
      & Pick<FixedTermQuote, 'noMaintenance' | 'stickerPrice'>
      & { cancellation: (
        { __typename: 'CancellationTerms' }
        & Pick<CancellationTerms, 'earliestDate' | 'noticePeriodDays'>
      ), fixedTermCommitment: (
        { __typename: 'CommitmentTerms' }
        & Pick<CommitmentTerms, 'lengthMonths' | 'monthlyRateAfterwards'>
      ) }
    ) }
  )> }
);

export type StartSetupStripePaymentMutationVariables = Exact<{ [key: string]: never; }>;


export type StartSetupStripePaymentMutation = (
  { __typename: 'Mutation' }
  & { startSetupStripePaymentAnonymous: (
    { __typename: 'SetupIntentConfig' }
    & Pick<SetupIntentConfig, 'clientSecret' | 'setupIntentId'>
  ) }
);

export type CheckoutSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CheckoutSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { startSubscriptionCreation: (
    { __typename: 'CreateSubscriptionStarted' }
    & Pick<CreateSubscriptionStarted, 'id'>
  ) | (
    { __typename: 'CreateSubscriptionFailed' }
    & Pick<CreateSubscriptionFailed, 'errorMessage'>
  ) }
);

export type GetChangingPlanQuoteQueryVariables = Exact<{
  input: GetChangingPlanQuoteInput;
}>;


export type GetChangingPlanQuoteQuery = (
  { __typename: 'Query' }
  & { getChangingPlanQuote: (
    { __typename: 'ChangingPlanQuote' }
    & Pick<ChangingPlanQuote, 'additionalProRataInPence' | 'appliedDiscountCode' | 'nextMonthlyInvoiceDate' | 'endDate' | 'startDate' | 'theftFeeAddOnPriceInPence'>
    & { currentPackage: (
      { __typename: 'Package' }
      & PackageFragment
    ), newPackage: (
      { __typename: 'Package' }
      & PackageFragment
    ), newSubscription: (
      { __typename: 'MonthlyQuote' }
      & Pick<MonthlyQuote, 'stickerPrice' | 'charged'>
      & { cancellation: (
        { __typename: 'CancellationTerms' }
        & Pick<CancellationTerms, 'earliestDate' | 'noticePeriodDays'>
      ), monthlyCommitment?: Maybe<(
        { __typename: 'CommitmentTerms' }
        & Pick<CommitmentTerms, 'lengthMonths' | 'monthlyRateAfterwards'>
      )>, revertsTo?: Maybe<(
        { __typename: 'MonthlyRevertToQuote' }
        & Pick<MonthlyRevertToQuote, 'delayMonths' | 'stickerPrice'>
      )> }
    ) | (
      { __typename: 'FixedTermQuote' }
      & Pick<FixedTermQuote, 'stickerPrice'>
      & { cancellation: (
        { __typename: 'CancellationTerms' }
        & Pick<CancellationTerms, 'earliestDate' | 'noticePeriodDays'>
      ), fixedTermCommitment: (
        { __typename: 'CommitmentTerms' }
        & Pick<CommitmentTerms, 'lengthMonths' | 'monthlyRateAfterwards'>
      ) }
    ) }
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type GetTheftFeeAddOnPriceAtSingupQueryVariables = Exact<{
  bikeId?: Maybe<Scalars['Int']>;
}>;


export type GetTheftFeeAddOnPriceAtSingupQuery = (
  { __typename: 'Query' }
  & { getTheftFeeAddOnPriceAtSingup?: Maybe<(
    { __typename: 'TheftFeeAddOnPriceType' }
    & Pick<TheftFeeAddOnPriceType, 'applyFrom' | 'theftFeeAddOnPriceInPence'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  )> }
);

export type CityFragment = (
  { __typename: 'City' }
  & Pick<City, 'country' | 'currency' | 'id' | 'isDefault' | 'name' | 'timezone' | 'postalAreaList' | 'postalDistrictList'>
);

export type BikeTypeFragment = (
  { __typename: 'BikeType' }
  & Pick<BikeType, 'bikeId' | 'name' | 'variationName' | 'shortDescription'>
  & { images?: Maybe<Array<(
    { __typename: 'KitImageType' }
    & Pick<KitImageType, 'imageUrl' | 'isFeatured' | 'thumbnailImageUrl'>
  )>> }
);

export type BillingDetailsFragment = (
  { __typename: 'BillingDetails' }
  & Pick<BillingDetails, 'city' | 'country' | 'line1' | 'line2' | 'postalCode' | 'state'>
);

export type UpcomingInvoiceFragment = (
  { __typename: 'UpcomingInvoice' }
  & Pick<UpcomingInvoice, 'amountDue' | 'billingCycleAnchor' | 'created' | 'currency' | 'description' | 'dueDate' | 'id' | 'invoiceNumber' | 'invoicePdf' | 'invoiceUrl'>
);

export type SummarisedInvoiceFragment = (
  { __typename: 'SummarisedInvoice' }
  & Pick<SummarisedInvoice, 'amountDue' | 'created' | 'currency' | 'description' | 'dueDate' | 'id' | 'invoiceNumber' | 'invoicePdf' | 'invoiceUrl'>
);

export type PaymentMethodFragment = (
  { __typename: 'PaymentMethod' }
  & Pick<PaymentMethod, 'brand' | 'cardholderName' | 'expMonth' | 'expYear' | 'id' | 'last4'>
  & { billingDetails?: Maybe<(
    { __typename: 'BillingDetails' }
    & BillingDetailsFragment
  )> }
);

export type RedemptionCodeInfoFragment = (
  { __typename: 'RedemptionCodeInfo' }
  & Pick<RedemptionCodeInfo, 'companyCode' | 'companyId' | 'companyName'>
  & { bike?: Maybe<(
    { __typename: 'BikeType' }
    & BikeTypeFragment
  )> }
);

export type ViewerFragment = (
  { __typename: 'Viewer' }
  & Pick<Viewer, 'email' | 'fullName' | 'riderId' | 'userId' | 'hasSubscription' | 'firstName' | 'lastName' | 'birthDate' | 'cyclingConfidence' | 'heightCm' | 'gender' | 'nationalInsurance' | 'phoneNumberMobile' | 'promotionCode' | 'hdyhau' | 'wantsMarketingEmails' | 'companyName' | 'isBtw' | 'isRejoiner' | 'rejoinedTimes'>
  & { homeAddress?: Maybe<(
    { __typename: 'Address' }
    & Pick<Address, 'addressLineOne' | 'city' | 'postcode' | 'addressLineTwo'>
  )> }
);

export type StripeUpcomingInvoiceFragment = (
  { __typename: 'StripeUpcomingInvoice' }
  & Pick<StripeUpcomingInvoice, 'total' | 'nextPaymentAttempt' | 'periodStart' | 'periodEnd'>
  & { discount?: Maybe<(
    { __typename: 'StripeDiscount' }
    & Pick<StripeDiscount, 'end' | 'start'>
    & { coupon: (
      { __typename: 'StripeCoupon' }
      & Pick<StripeCoupon, 'amountOff' | 'duration' | 'durationInMonths' | 'id' | 'name' | 'percentOff'>
    ) }
  )> }
);

export type StripeCouponFragment = (
  { __typename: 'StripeCoupon' }
  & Pick<StripeCoupon, 'amountOff' | 'durationInMonths' | 'duration' | 'id' | 'name' | 'percentOff'>
);

export type AppliedDiscountCodeFragment = (
  { __typename: 'DiscountCodeType' }
  & Pick<DiscountCodeType, 'amountOff' | 'description' | 'discountCode' | 'duration' | 'durationInMonths' | 'freeDelivery' | 'percentOff'>
);

export type PackageFragment = (
  { __typename: 'Package' }
  & Pick<Package, 'basePrice' | 'collectionFeeInPence' | 'comparativeBasePrice' | 'deliveryFeeInPence' | 'description' | 'noMaintenance' | 'packageSlug' | 'paidMonthly' | 'savingAmount' | 'setupFeeInPence' | 'stickerPrice' | 'termLengthMonths'>
  & { appliedDiscountCode?: Maybe<(
    { __typename: 'DiscountCodeType' }
    & AppliedDiscountCodeFragment
  )>, bike?: Maybe<(
    { __typename: 'BikeType' }
    & BikeTypeFragment
  )>, couponToApplyAtEndOfMinimumTerm?: Maybe<(
    { __typename: 'StripeCoupon' }
    & StripeCouponFragment
  )> }
);

export type IssueReportDataFragment = (
  { __typename: 'IssueReportData' }
  & Pick<IssueReportData, 'address' | 'addressType' | 'appointmentEndTime' | 'appointmentStartTime' | 'assigneeAvatarUrl' | 'assigneeName' | 'becameCancelled' | 'categoryId' | 'becameCompleted' | 'description' | 'categoryLabel' | 'id' | 'images' | 'initialEndTime' | 'initialStartTime' | 'isKeyOrder' | 'reportNumber' | 'riderId' | 'status'>
);

export type SubscribedPackageFragment = (
  { __typename: 'SubscribedPackage' }
  & Pick<SubscribedPackage, 'basePrice' | 'collectionFeeInPence' | 'currentPeriodEnd' | 'currentPeriodStart' | 'discountEnds' | 'endDate' | 'noMaintenance' | 'packageSlug' | 'paidMonthly' | 'savingAmount' | 'startDate' | 'stickerPrice' | 'termLengthMonths'>
  & { couponToApplyAtEndOfMinimumTerm?: Maybe<(
    { __typename: 'StripeCoupon' }
    & StripeCouponFragment
  )>, pausedCollectionPayment?: Maybe<(
    { __typename: 'PausedCollectionPaymentType' }
    & Pick<PausedCollectionPaymentType, 'chargeAmountInPence' | 'chargeDate' | 'charged' | 'pausedAt' | 'resumesAt'>
  )>, selectedBike?: Maybe<(
    { __typename: 'BikeType' }
    & BikeTypeFragment
  )> }
);

export type SubscriptionFragment = (
  { __typename: 'Subscription' }
  & Pick<Subscription, 'actualCollectionTime' | 'actualDeliveryTime' | 'boughtTheBikeWithPrice' | 'canPauseCollectionPayment' | 'canApplyRetentionDiscount' | 'canBuyYourBike' | 'canChangeBikeType' | 'canChangeSubscriptionPackage' | 'canRevertCancellation' | 'canSwitchToNoMaintenance' | 'canUnsubscribeFrom' | 'canRejoin' | 'deliveryNote' | 'discountCode' | 'noticeDatePlus30Days' | 'generatedReferralCode' | 'isBadPayer' | 'isCycleScheme' | 'manualAllowUpgrade' | 'packageMinimumTermLengthInMonths' | 'packageBasePriceInPence' | 'packageSlug' | 'phase' | 'phaseBecameActive' | 'phaseBecameCancelled' | 'phaseBecameChurned' | 'requestedCollectionDate' | 'phaseBecamePending' | 'requestedDeliveryDate' | 'retentionDiscountApplied' | 'riderTenureInMonth' | 'startDate' | 'status' | 'statusBecameOnNotice' | 'stripeCustomerId' | 'stripeSubscriptionId' | 'subscriptionCode' | 'collectionBuzzbikeRetailId' | 'collectionEndTime' | 'collectionStartTime' | 'deliveryBuzzbikeRetailId' | 'deliveryEndTime' | 'deliveryStartTime' | 'suspended' | 'verificationStatus'>
  & { bike?: Maybe<(
    { __typename: 'BikeType' }
    & BikeTypeFragment
  )>, city: (
    { __typename: 'City' }
    & CityFragment
  ), collectionAddress?: Maybe<(
    { __typename: 'Address' }
    & Pick<Address, 'addressLineOne' | 'addressLineTwo' | 'city' | 'postcode'>
  )>, deliveryAddress?: Maybe<(
    { __typename: 'Address' }
    & Pick<Address, 'addressLineOne' | 'addressLineTwo' | 'city' | 'postcode'>
  )>, rewards: Array<(
    { __typename: 'SingleRewardStepType' }
    & Pick<SingleRewardStepType, 'key' | 'state'>
    & { discount?: Maybe<(
      { __typename: 'LoyaltyRewardDiscount' }
      & Pick<LoyaltyRewardDiscount, 'amountOff' | 'duration' | 'durationInMonths' | 'name' | 'percentOff'>
    )> }
  )> }
);

export type VerificationFragment = (
  { __typename: 'Verification' }
  & Pick<Verification, 'requiresReschedule' | 'verificationStatus' | 'verificationStripeErrorCode' | 'verificationStripeErrorMessage' | 'verificationSystemErrorMessage'>
);

export type SubscriptionTheftFeeAddOnTypeFragment = (
  { __typename: 'SubscriptionTheftFeeAddOnType' }
  & Pick<SubscriptionTheftFeeAddOnType, 'endDate' | 'canceledAt' | 'canceled' | 'startDate' | 'theftFeeAddOnPriceInPence'>
);

export type SwitchPackageFragment = (
  { __typename: 'SwitchPackage' }
  & Pick<SwitchPackage, 'basePrice' | 'collectionFeeInPence' | 'deliveryFeeInPence' | 'description' | 'packageSlug' | 'paidMonthly' | 'termLengthMonths'>
);

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type CreateAccountMutation = (
  { __typename: 'Mutation' }
  & { createAccount: (
    { __typename: 'CreateAccountResult' }
    & Pick<CreateAccountResult, 'accessToken'>
    & { viewer: (
      { __typename: 'Viewer' }
      & ViewerFragment
    ) }
  ) | (
    { __typename: 'CreateUserErrorDuplicate' }
    & Pick<CreateUserErrorDuplicate, 'message'>
  ) }
);

export type UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;


export type UpdateAccountMutation = (
  { __typename: 'Mutation' }
  & { updateAccount: (
    { __typename: 'Viewer' }
    & ViewerFragment
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename: 'Mutation' }
  & { changePassword: (
    { __typename: 'ChangePasswordResult' }
    & Pick<ChangePasswordResult, 'message'>
  ) | (
    { __typename: 'ChangePasswordError' }
    & Pick<ChangePasswordError, 'message'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename: 'Mutation' }
  & { resetPassword: (
    { __typename: 'ResetPasswordResult' }
    & Pick<ResetPasswordResult, 'message'>
  ) | (
    { __typename: 'ResetPasswordError' }
    & Pick<ResetPasswordError, 'message'>
  ) }
);

export type RedeemResetPasswordTokenMutationVariables = Exact<{
  input: RedeemResetPasswordTokenInput;
}>;


export type RedeemResetPasswordTokenMutation = (
  { __typename: 'Mutation' }
  & { redeemResetPasswordToken: (
    { __typename: 'ResetPasswordError' }
    & Pick<ResetPasswordError, 'message'>
  ) | (
    { __typename: 'ResetPasswordResult' }
    & Pick<ResetPasswordResult, 'message'>
  ) }
);

export type ApplyPromoCodeMutationVariables = Exact<{
  promoCode: Scalars['String'];
}>;


export type ApplyPromoCodeMutation = (
  { __typename: 'Mutation' }
  & { echo: (
    { __typename: 'EchoSuccess' }
    & { message: EchoSuccess['message'] }
  ) | (
    { __typename: 'EchoError' }
    & { error: EchoError['error'] }
  ) }
);

export type StartEditStripePaymentMutationVariables = Exact<{ [key: string]: never; }>;


export type StartEditStripePaymentMutation = (
  { __typename: 'Mutation' }
  & { startEditStripePayment: (
    { __typename: 'SetupIntentConfig' }
    & Pick<SetupIntentConfig, 'clientSecret' | 'setupIntentId'>
  ) }
);

export type FinishEditStripePaymentMutationVariables = Exact<{
  setupIntentId: Scalars['String'];
}>;


export type FinishEditStripePaymentMutation = (
  { __typename: 'Mutation' }
  & { finishEditStripePayment: (
    { __typename: 'PaymentDetails' }
    & Pick<PaymentDetails, 'cardholderName' | 'expMonth' | 'expYear' | 'last4'>
  ) }
);

export type UpdateMarketingPreferencesMutationVariables = Exact<{
  email: Scalars['String'];
  optedIn: Scalars['Boolean'];
}>;


export type UpdateMarketingPreferencesMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'updateMarketingPreferences'>
);

export type CreateRejoinerMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateRejoinerMutation = (
  { __typename: 'Mutation' }
  & { createRejoiner: (
    { __typename: 'CreateAccountResult' }
    & Pick<CreateAccountResult, 'accessToken'>
    & { viewer: (
      { __typename: 'Viewer' }
      & ViewerFragment
    ) }
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type ChangePlanMutationVariables = Exact<{
  input: ChangingPlanData;
}>;


export type ChangePlanMutation = (
  { __typename: 'Mutation' }
  & { changePlan: (
    { __typename: 'SubscribedPackages' }
    & { currentPackage?: Maybe<(
      { __typename: 'SubscribedPackage' }
      & SubscribedPackageFragment
    )>, nextPackage?: Maybe<(
      { __typename: 'SubscribedPackage' }
      & SubscribedPackageFragment
    )> }
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type UpdateKitOrderMutationVariables = Exact<{
  input: DeliveryOptionsInput;
}>;


export type UpdateKitOrderMutation = (
  { __typename: 'Mutation' }
  & { updateKitOrder: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type UpdateRiderMutationVariables = Exact<{
  input: UpdateRiderInput;
}>;


export type UpdateRiderMutation = (
  { __typename: 'Mutation' }
  & { updateRider: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type ApplyRetentionDiscountMutationVariables = Exact<{
  discountCode: Scalars['String'];
}>;


export type ApplyRetentionDiscountMutation = (
  { __typename: 'Mutation' }
  & { applyRetentionDiscount: (
    { __typename: 'StripeUpcomingInvoice' }
    & StripeUpcomingInvoiceFragment
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type PauseCollectionPaymentMutationVariables = Exact<{
  chargeAmountInPence: Scalars['Int'];
  months: Scalars['Int'];
}>;


export type PauseCollectionPaymentMutation = (
  { __typename: 'Mutation' }
  & { pauseCollectionPayment: (
    { __typename: 'StripeUpcomingInvoice' }
    & StripeUpcomingInvoiceFragment
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type JoinTheWaitlistMutationVariables = Exact<{
  input: WaitlistInput;
}>;


export type JoinTheWaitlistMutation = (
  { __typename: 'Mutation' }
  & { joinTheWaitlist: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAccountMutation = (
  { __typename: 'Mutation' }
  & { deleteAccount: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type CancelChangingPlanMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelChangingPlanMutation = (
  { __typename: 'Mutation' }
  & { cancelChangingPlan: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type BuyYourBikeMutationVariables = Exact<{
  priceInPence?: Maybe<Scalars['Int']>;
}>;


export type BuyYourBikeMutation = (
  { __typename: 'Mutation' }
  & { buyYourBike: (
    { __typename: 'InvoicePdf' }
    & Pick<InvoicePdf, 'url'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type ReportAnIssueMutationVariables = Exact<{
  input: IssueReportInput;
}>;


export type ReportAnIssueMutation = (
  { __typename: 'Mutation' }
  & { reportAnIssue: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type RescheduleAnIssueMutationVariables = Exact<{
  input: RescheduleIssueReportInput;
}>;


export type RescheduleAnIssueMutation = (
  { __typename: 'Mutation' }
  & { rescheduleAnIssue: (
    { __typename: 'IssueReportData' }
    & IssueReportDataFragment
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type RescheduleCollectionMutationVariables = Exact<{
  input: OrderReschedulingInput;
}>;


export type RescheduleCollectionMutation = (
  { __typename: 'Mutation' }
  & { rescheduleCollectionOrder: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type RevertCancellationMutationVariables = Exact<{ [key: string]: never; }>;


export type RevertCancellationMutation = (
  { __typename: 'Mutation' }
  & { revertCancellation: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type ClaimLoyaltyRewardMutationVariables = Exact<{
  rewardKey: RewardJourneyKey;
  buzziversaryOrder?: Maybe<KitOrderTypeInput>;
}>;


export type ClaimLoyaltyRewardMutation = (
  { __typename: 'Mutation' }
  & { claimLoyaltyReward: (
    { __typename: 'StripeUpcomingInvoice' }
    & StripeUpcomingInvoiceFragment
  ) | (
    { __typename: 'IssueReportType' }
    & Pick<IssueReportType, 'category' | 'subcategory' | 'address' | 'appointmentEndTime' | 'appointmentStartTime' | 'reportNumber'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type ChangePaymentDateMutationVariables = Exact<{
  requestedDay: Scalars['Int'];
}>;


export type ChangePaymentDateMutation = (
  { __typename: 'Mutation' }
  & { changePaymentDate: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type CreateKlarnaUrlMutationVariables = Exact<{
  invoiceId: Scalars['String'];
  redirectUrl: Scalars['String'];
}>;


export type CreateKlarnaUrlMutation = (
  { __typename: 'Mutation' }
  & { createKlarnaUrl: (
    { __typename: 'KlarnaObject' }
    & Pick<KlarnaObject, 'url'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type UpdateBillingAddressMutationVariables = Exact<{
  input: UpdateBillingAddressInput;
}>;


export type UpdateBillingAddressMutation = (
  { __typename: 'Mutation' }
  & { updateBillingAddress: (
    { __typename: 'UpdateBillingAddressResult' }
    & Pick<UpdateBillingAddressResult, 'message'>
  ) }
);

export type SubscribeTheftFeeAddOnMutationVariables = Exact<{ [key: string]: never; }>;


export type SubscribeTheftFeeAddOnMutation = (
  { __typename: 'Mutation' }
  & { subscribeTheftFeeAddOn: (
    { __typename: 'SubscriptionTheftFeeAddOnType' }
    & SubscriptionTheftFeeAddOnTypeFragment
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type CancelTheftFeeAddOnMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelTheftFeeAddOnMutation = (
  { __typename: 'Mutation' }
  & { cancelTheftFeeAddOn: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type JoinTheGeoggraphicWaitlistMutationVariables = Exact<{
  input: GeographicWaitlistInput;
}>;


export type JoinTheGeoggraphicWaitlistMutation = (
  { __typename: 'Mutation' }
  & { joinTheGeoggraphicWaitlist: (
    { __typename: 'APIResult' }
    & Pick<ApiResult, 'message'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type CreateKlarnaUrlForBuyingYourBikeMutationVariables = Exact<{
  redirectUrl: Scalars['String'];
}>;


export type CreateKlarnaUrlForBuyingYourBikeMutation = (
  { __typename: 'Mutation' }
  & { createKlarnaUrlForBuyingYourBike: (
    { __typename: 'KlarnaObject' }
    & Pick<KlarnaObject, 'url'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type CreateKlarnaUrlForCancellationMutationVariables = Exact<{
  input: CancellationRequestInput;
  redirectUrl: Scalars['String'];
}>;


export type CreateKlarnaUrlForCancellationMutation = (
  { __typename: 'Mutation' }
  & { createKlarnaUrlForCancellation: (
    { __typename: 'KlarnaObject' }
    & Pick<KlarnaObject, 'url'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type CheckUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckUserQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'checkEmailHasAccount'>
);

export type HasSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type HasSubscriptionQuery = (
  { __typename: 'Query' }
  & { viewer: (
    { __typename: 'Viewer' }
    & Pick<Viewer, 'hasSubscription'>
  ) }
);

export type GetReferralCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferralCodeQuery = (
  { __typename: 'Query' }
  & { viewer: (
    { __typename: 'Viewer' }
    & { subscription?: Maybe<(
      { __typename: 'Subscription' }
      & Pick<Subscription, 'generatedReferralCode'>
    )> }
  ) }
);

export type AllHdyhausQueryVariables = Exact<{ [key: string]: never; }>;


export type AllHdyhausQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'hdyhauOptions'>
);

export type GetConfirmationViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfirmationViewerQuery = (
  { __typename: 'Query' }
  & { viewer: (
    { __typename: 'Viewer' }
    & Pick<Viewer, 'riderId' | 'isRejoiner'>
    & { subscription?: Maybe<(
      { __typename: 'Subscription' }
      & Pick<Subscription, 'stripeSubscriptionId' | 'discountCode' | 'packageSlug' | 'requestedDeliveryDate' | 'generatedReferralCode'>
    )> }
  ) }
);

export type CitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CitiesQuery = (
  { __typename: 'Query' }
  & { cities: Array<(
    { __typename: 'City' }
    & CityFragment
  )> }
);

export type BikesQueryVariables = Exact<{
  cityId?: Maybe<Scalars['Int']>;
}>;


export type BikesQuery = (
  { __typename: 'Query' }
  & { bikes: Array<(
    { __typename: 'CityDetailsWithBikes' }
    & Pick<CityDetailsWithBikes, 'id'>
    & { bikes: Array<Maybe<(
      { __typename: 'BikeType' }
      & BikeTypeFragment
    )>>, city: (
      { __typename: 'City' }
      & CityFragment
    ) }
  )> }
);

export type PreviewBuyingYourBikeQueryVariables = Exact<{
  priceInPence?: Maybe<Scalars['Int']>;
}>;


export type PreviewBuyingYourBikeQuery = (
  { __typename: 'Query' }
  & { previewBuyingYourBike: (
    { __typename: 'BuyingBikeInvoiceSummary' }
    & Pick<BuyingBikeInvoiceSummary, 'buyingBikePrice'>
    & { paymentMethod?: Maybe<(
      { __typename: 'PaymentMethod' }
      & PaymentMethodFragment
    )> }
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type RescheduleDeliveryOrderQueryVariables = Exact<{
  input: OrderReschedulingInput;
}>;


export type RescheduleDeliveryOrderQuery = (
  { __typename: 'Query' }
  & { rescheduleDeliveryOrder?: Maybe<(
    { __typename: 'DeliveryOrderReschedulingInvoiceSummary' }
    & Pick<DeliveryOrderReschedulingInvoiceSummary, 'deliveryCharge'>
    & { paymentMethod?: Maybe<(
      { __typename: 'PaymentMethod' }
      & PaymentMethodFragment
    )> }
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  )> }
);

export type GetIssueReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIssueReportsQuery = (
  { __typename: 'Query' }
  & { getIssueReports: (
    { __typename: 'IssueReportList' }
    & { issueReports: Array<(
      { __typename: 'IssueReportData' }
      & IssueReportDataFragment
    )> }
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type AvailableBikeProfDatesQueryVariables = Exact<{
  postcode?: Maybe<Scalars['String']>;
}>;


export type AvailableBikeProfDatesQuery = (
  { __typename: 'Query' }
  & { availableBikeProfDates: (
    { __typename: 'SlotPairList' }
    & { availableSlots: Array<(
      { __typename: 'SlotPairType' }
      & Pick<SlotPairType, 'startTime' | 'endTime'>
    )> }
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type RescheduleCollectionOrderQueryVariables = Exact<{
  input: OrderReschedulingInput;
}>;


export type RescheduleCollectionOrderQuery = (
  { __typename: 'Query' }
  & { rescheduleCollectionOrder?: Maybe<(
    { __typename: 'CollectionOrderReschedulingInvoiceSummary' }
    & Pick<CollectionOrderReschedulingInvoiceSummary, 'collectionCharge' | 'prorataPayment'>
    & { paymentMethod?: Maybe<(
      { __typename: 'PaymentMethod' }
      & PaymentMethodFragment
    )> }
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  )> }
);

export type ValidateRedemptionCodeQueryVariables = Exact<{
  redemptionCode: Scalars['String'];
  isCyclescheme?: Scalars['Boolean'];
}>;


export type ValidateRedemptionCodeQuery = (
  { __typename: 'Query' }
  & { validateRedemptionCode: (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) | (
    { __typename: 'RedemptionCodeInfo' }
    & RedemptionCodeInfoFragment
  ) }
);

export type GetDefaultPaymentMethodQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultPaymentMethodQuery = (
  { __typename: 'Query' }
  & { getDefaultPaymentMethod: (
    { __typename: 'PaymentMethod' }
    & PaymentMethodFragment
  ) | { __typename: 'APIError' } }
);

export type GetBuzzbikeRetailsQueryVariables = Exact<{
  cityId: Scalars['Int'];
}>;


export type GetBuzzbikeRetailsQuery = (
  { __typename: 'Query' }
  & { getBuzzbikeRetails: Array<(
    { __typename: 'BuzzbikeRetailData' }
    & Pick<BuzzbikeRetailData, 'addressLineOne' | 'addressLineTwo' | 'city' | 'id' | 'postcode' | 'retailName' | 'retailPhoneNumber'>
  )> }
);

export type GetChangePaymentDateQueryVariables = Exact<{
  requestedDay: Scalars['Int'];
}>;


export type GetChangePaymentDateQuery = (
  { __typename: 'Query' }
  & { changePaymentDate: (
    { __typename: 'ChangePaymentDateProrata' }
    & Pick<ChangePaymentDateProrata, 'chargeAmount' | 'chargeFromDate' | 'chargeToDate' | 'theftFeeAddOnChargeAmount'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type OutstandingInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type OutstandingInvoicesQuery = (
  { __typename: 'Query' }
  & { outstandingInvoices: Array<(
    { __typename: 'SummarisedInvoice' }
    & SummarisedInvoiceFragment
  )> }
);

export type UpcomingInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type UpcomingInvoicesQuery = (
  { __typename: 'Query' }
  & { upcomingInvoice: Array<(
    { __typename: 'UpcomingInvoice' }
    & UpcomingInvoiceFragment
  )> }
);

export type GetTheftFeeAddOnPriceQueryVariables = Exact<{
  bikeId?: Maybe<Scalars['Int']>;
}>;


export type GetTheftFeeAddOnPriceQuery = (
  { __typename: 'Query' }
  & { getTheftFeeAddOnPrice?: Maybe<(
    { __typename: 'TheftFeeAddOnPriceType' }
    & Pick<TheftFeeAddOnPriceType, 'applyFrom' | 'theftFeeAddOnPriceInPence'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  )> }
);

export type GetSubscriptionTheftFeeAddOnQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionTheftFeeAddOnQuery = (
  { __typename: 'Query' }
  & { getSubscriptionTheftFeeAddOn?: Maybe<(
    { __typename: 'SubscriptionTheftFeeAddOnType' }
    & SubscriptionTheftFeeAddOnTypeFragment
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  )> }
);

export type GetBuyingYourBuzzbikeInvoiceUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBuyingYourBuzzbikeInvoiceUrlQuery = (
  { __typename: 'Query' }
  & { getBuyingYourBuzzbikeInvoiceUrl: (
    { __typename: 'InvoicePdf' }
    & Pick<InvoicePdf, 'url'>
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type GetRenewCycleSchemePlanQuoteQueryVariables = Exact<{
  redemptionCode: Scalars['String'];
}>;


export type GetRenewCycleSchemePlanQuoteQuery = (
  { __typename: 'Query' }
  & { getRenewCycleSchemePlanQuote: (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) | (
    { __typename: 'RenewCycleSchemePlanQuote' }
    & Pick<RenewCycleSchemePlanQuote, 'currentMonthlyPayment' | 'currentPackageId' | 'currentPackageSlug' | 'currentPlanEndDate' | 'currentPlanStartDate' | 'newMonthlyPayment' | 'newPackageId' | 'newPackageSlug' | 'newPlanEndDate' | 'newPlanStartDate' | 'renewType'>
    & { currentBikeType: (
      { __typename: 'BikeType' }
      & BikeTypeFragment
    ), currentSwitchRolling?: Maybe<(
      { __typename: 'SwitchPackage' }
      & SwitchPackageFragment
    )>, newBikeType: (
      { __typename: 'BikeType' }
      & BikeTypeFragment
    ), newSwitchRolling?: Maybe<(
      { __typename: 'SwitchPackage' }
      & SwitchPackageFragment
    )> }
  ) }
);

export type GetNoMaintenancePackagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNoMaintenancePackagesQuery = (
  { __typename: 'Query' }
  & { getNoMaintenancePackages: Array<(
    { __typename: 'Package' }
    & Pick<Package, 'packageSlug'>
    & PackageFragment
  )> }
);

export type AllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllUsersQuery = (
  { __typename: 'Query' }
  & { viewer: (
    { __typename: 'Viewer' }
    & Pick<Viewer, 'email' | 'fullName' | 'riderId'>
  ) }
);

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = (
  { __typename: 'Query' }
  & { viewer: (
    { __typename: 'Viewer' }
    & Pick<Viewer, 'firstName' | 'lastName' | 'fullName' | 'email' | 'birthDate' | 'title' | 'userId' | 'companyName' | 'isBtw' | 'isRejoiner' | 'rejoinedTimes' | 'gender' | 'cyclingConfidence' | 'nationalInsurance' | 'phoneNumberMobile' | 'promotionCode' | 'heightCm' | 'hasSubscription' | 'wantsMarketingEmails' | 'requestUrl' | 'cancellationRequestUrl'>
    & { homeAddress?: Maybe<(
      { __typename: 'Address' }
      & Pick<Address, 'addressLineOne' | 'addressLineTwo' | 'city' | 'postcode'>
    )>, subscription?: Maybe<(
      { __typename: 'Subscription' }
      & SubscriptionFragment
    )>, verification?: Maybe<(
      { __typename: 'Verification' }
      & VerificationFragment
    )> }
  ) }
);

export type UserSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSubscriptionQuery = (
  { __typename: 'Query' }
  & { viewer: (
    { __typename: 'Viewer' }
    & Pick<Viewer, 'requestUrl' | 'cancellationRequestUrl'>
    & { subscription?: Maybe<(
      { __typename: 'Subscription' }
      & SubscriptionFragment
    )> }
  ) }
);

export type GetSubscribedPackagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscribedPackagesQuery = (
  { __typename: 'Query' }
  & { getSubscribedPackages: (
    { __typename: 'SubscribedPackages' }
    & { currentPackage?: Maybe<(
      { __typename: 'SubscribedPackage' }
      & SubscribedPackageFragment
    )>, nextPackage?: Maybe<(
      { __typename: 'SubscribedPackage' }
      & SubscribedPackageFragment
    )> }
  ) | (
    { __typename: 'APIError' }
    & Pick<ApiError, 'message'>
  ) }
);

export type GetRecommendedPackagesQueryVariables = Exact<{
  bikeId?: Maybe<Scalars['Int']>;
  packageGroupName?: Maybe<Scalars['String']>;
  freeMonthsPromo?: Maybe<Scalars['Boolean']>;
}>;


export type GetRecommendedPackagesQuery = (
  { __typename: 'Query' }
  & { getRecommendedPackages: Array<(
    { __typename: 'Package' }
    & Pick<Package, 'basePrice' | 'packageSlug' | 'savingAmount' | 'paidMonthly' | 'stickerPrice' | 'termLengthMonths'>
    & { couponToApplyAtEndOfMinimumTerm?: Maybe<(
      { __typename: 'StripeCoupon' }
      & StripeCouponFragment
    )> }
  )> }
);

export type PaymentDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentDetailsQuery = (
  { __typename: 'Query' }
  & { paymentDetails?: Maybe<(
    { __typename: 'PaymentMethod' }
    & Pick<PaymentMethod, 'last4' | 'expMonth' | 'expYear' | 'cardholderName'>
  )> }
);

/* Typescript React Apollo */
export const UpcomingInvoiceFragmentDoc = gql`
    fragment upcomingInvoice on UpcomingInvoice {
  amountDue
  billingCycleAnchor
  created
  currency
  description
  dueDate
  id
  invoiceNumber
  invoicePdf
  invoiceUrl
}
    `;
export const SummarisedInvoiceFragmentDoc = gql`
    fragment summarisedInvoice on SummarisedInvoice {
  amountDue
  created
  currency
  description
  dueDate
  id
  invoiceNumber
  invoicePdf
  invoiceUrl
}
    `;
export const BillingDetailsFragmentDoc = gql`
    fragment billingDetails on BillingDetails {
  city
  country
  line1
  line2
  postalCode
  state
}
    `;
export const PaymentMethodFragmentDoc = gql`
    fragment paymentMethod on PaymentMethod {
  billingDetails {
    ...billingDetails
  }
  brand
  cardholderName
  expMonth
  expYear
  id
  last4
}
    ${BillingDetailsFragmentDoc}`;
export const BikeTypeFragmentDoc = gql`
    fragment bikeType on BikeType {
  bikeId
  name
  variationName
  shortDescription
  images {
    imageUrl
    isFeatured
    thumbnailImageUrl
  }
}
    `;
export const RedemptionCodeInfoFragmentDoc = gql`
    fragment redemptionCodeInfo on RedemptionCodeInfo {
  bike {
    ...bikeType
  }
  companyCode
  companyId
  companyName
}
    ${BikeTypeFragmentDoc}`;
export const ViewerFragmentDoc = gql`
    fragment viewer on Viewer {
  email
  fullName
  riderId
  userId
  hasSubscription
  firstName
  lastName
  birthDate
  cyclingConfidence
  heightCm
  gender
  homeAddress {
    addressLineOne
    city
    postcode
    addressLineTwo
  }
  nationalInsurance
  phoneNumberMobile
  promotionCode
  hdyhau
  wantsMarketingEmails
  companyName
  isBtw
  isRejoiner
  rejoinedTimes
}
    `;
export const StripeUpcomingInvoiceFragmentDoc = gql`
    fragment stripeUpcomingInvoice on StripeUpcomingInvoice {
  total
  discount {
    coupon {
      amountOff
      duration
      durationInMonths
      id
      name
      percentOff
    }
    end
    start
  }
  nextPaymentAttempt
  periodStart
  periodEnd
}
    `;
export const AppliedDiscountCodeFragmentDoc = gql`
    fragment appliedDiscountCode on DiscountCodeType {
  amountOff
  description
  discountCode
  duration
  durationInMonths
  freeDelivery
  percentOff
}
    `;
export const StripeCouponFragmentDoc = gql`
    fragment stripeCoupon on StripeCoupon {
  amountOff
  durationInMonths
  duration
  id
  name
  percentOff
}
    `;
export const PackageFragmentDoc = gql`
    fragment package on Package {
  appliedDiscountCode {
    ...appliedDiscountCode
  }
  basePrice
  bike {
    ...bikeType
  }
  collectionFeeInPence
  comparativeBasePrice
  couponToApplyAtEndOfMinimumTerm {
    ...stripeCoupon
  }
  deliveryFeeInPence
  description
  noMaintenance
  packageSlug
  paidMonthly
  savingAmount
  setupFeeInPence
  stickerPrice
  termLengthMonths
}
    ${AppliedDiscountCodeFragmentDoc}
${BikeTypeFragmentDoc}
${StripeCouponFragmentDoc}`;
export const IssueReportDataFragmentDoc = gql`
    fragment issueReportData on IssueReportData {
  address
  addressType
  appointmentEndTime
  appointmentStartTime
  assigneeAvatarUrl
  assigneeName
  becameCancelled
  categoryId
  becameCompleted
  description
  categoryLabel
  id
  images
  initialEndTime
  initialStartTime
  isKeyOrder
  reportNumber
  riderId
  status
}
    `;
export const SubscribedPackageFragmentDoc = gql`
    fragment subscribedPackage on SubscribedPackage {
  basePrice
  collectionFeeInPence
  couponToApplyAtEndOfMinimumTerm {
    ...stripeCoupon
  }
  currentPeriodEnd
  currentPeriodStart
  discountEnds
  endDate
  noMaintenance
  packageSlug
  paidMonthly
  savingAmount
  startDate
  stickerPrice
  termLengthMonths
  pausedCollectionPayment {
    chargeAmountInPence
    chargeDate
    charged
    pausedAt
    resumesAt
  }
  selectedBike {
    ...bikeType
  }
}
    ${StripeCouponFragmentDoc}
${BikeTypeFragmentDoc}`;
export const CityFragmentDoc = gql`
    fragment city on City {
  country
  currency
  id
  isDefault
  name
  timezone
  postalAreaList
  postalDistrictList
}
    `;
export const SubscriptionFragmentDoc = gql`
    fragment subscription on Subscription {
  actualCollectionTime
  actualDeliveryTime
  bike {
    ...bikeType
  }
  boughtTheBikeWithPrice
  canPauseCollectionPayment
  canApplyRetentionDiscount
  canBuyYourBike
  canChangeBikeType
  canChangeSubscriptionPackage
  canRevertCancellation
  canSwitchToNoMaintenance
  canUnsubscribeFrom
  canRejoin
  city {
    ...city
  }
  collectionAddress {
    addressLineOne
    addressLineTwo
    city
    postcode
  }
  deliveryAddress {
    addressLineOne
    addressLineTwo
    city
    postcode
  }
  deliveryNote
  discountCode
  noticeDatePlus30Days
  generatedReferralCode
  isBadPayer
  isCycleScheme
  manualAllowUpgrade
  packageMinimumTermLengthInMonths
  packageBasePriceInPence
  packageSlug
  phase
  phaseBecameActive
  phaseBecameCancelled
  phaseBecameChurned
  requestedCollectionDate
  phaseBecamePending
  requestedDeliveryDate
  retentionDiscountApplied
  rewards {
    key
    state
    discount {
      amountOff
      duration
      durationInMonths
      name
      percentOff
    }
  }
  riderTenureInMonth
  startDate
  status
  statusBecameOnNotice
  stripeCustomerId
  stripeSubscriptionId
  subscriptionCode
  collectionBuzzbikeRetailId
  collectionEndTime
  collectionStartTime
  deliveryBuzzbikeRetailId
  deliveryEndTime
  deliveryStartTime
  suspended
  verificationStatus
}
    ${BikeTypeFragmentDoc}
${CityFragmentDoc}`;
export const VerificationFragmentDoc = gql`
    fragment verification on Verification {
  requiresReschedule
  verificationStatus
  verificationStripeErrorCode
  verificationStripeErrorMessage
  verificationSystemErrorMessage
}
    `;
export const SubscriptionTheftFeeAddOnTypeFragmentDoc = gql`
    fragment subscriptionTheftFeeAddOnType on SubscriptionTheftFeeAddOnType {
  endDate
  canceledAt
  canceled
  startDate
  theftFeeAddOnPriceInPence
}
    `;
export const SwitchPackageFragmentDoc = gql`
    fragment switchPackage on SwitchPackage {
  basePrice
  collectionFeeInPence
  deliveryFeeInPence
  description
  packageSlug
  paidMonthly
  termLengthMonths
}
    `;
export const DeliveryAvailabilityDocument = gql`
    query deliveryAvailability($bikeId: Int, $cityId: Int, $buzzbikeRetailId: Int, $postcode: String) {
  availableDeliveryDates(
    bikeId: $bikeId
    cityId: $cityId
    buzzbikeRetailId: $buzzbikeRetailId
    postcode: $postcode
  )
}
    `;

/**
 * __useDeliveryAvailabilityQuery__
 *
 * To run a query within a React component, call `useDeliveryAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryAvailabilityQuery({
 *   variables: {
 *      bikeId: // value for 'bikeId'
 *      cityId: // value for 'cityId'
 *      buzzbikeRetailId: // value for 'buzzbikeRetailId'
 *      postcode: // value for 'postcode'
 *   },
 * });
 */
export function useDeliveryAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryAvailabilityQuery, DeliveryAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryAvailabilityQuery, DeliveryAvailabilityQueryVariables>(DeliveryAvailabilityDocument, options);
      }
export function useDeliveryAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryAvailabilityQuery, DeliveryAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryAvailabilityQuery, DeliveryAvailabilityQueryVariables>(DeliveryAvailabilityDocument, options);
        }
export type DeliveryAvailabilityQueryHookResult = ReturnType<typeof useDeliveryAvailabilityQuery>;
export type DeliveryAvailabilityLazyQueryHookResult = ReturnType<typeof useDeliveryAvailabilityLazyQuery>;
export type DeliveryAvailabilityQueryResult = Apollo.QueryResult<DeliveryAvailabilityQuery, DeliveryAvailabilityQueryVariables>;
export const CollectionAvailabilityDocument = gql`
    query collectionAvailability($buzzbikeRetailId: Int, $postcode: String) {
  availableCollectionDates(
    buzzbikeRetailId: $buzzbikeRetailId
    postcode: $postcode
  )
}
    `;

/**
 * __useCollectionAvailabilityQuery__
 *
 * To run a query within a React component, call `useCollectionAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionAvailabilityQuery({
 *   variables: {
 *      buzzbikeRetailId: // value for 'buzzbikeRetailId'
 *      postcode: // value for 'postcode'
 *   },
 * });
 */
export function useCollectionAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<CollectionAvailabilityQuery, CollectionAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollectionAvailabilityQuery, CollectionAvailabilityQueryVariables>(CollectionAvailabilityDocument, options);
      }
export function useCollectionAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollectionAvailabilityQuery, CollectionAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollectionAvailabilityQuery, CollectionAvailabilityQueryVariables>(CollectionAvailabilityDocument, options);
        }
export type CollectionAvailabilityQueryHookResult = ReturnType<typeof useCollectionAvailabilityQuery>;
export type CollectionAvailabilityLazyQueryHookResult = ReturnType<typeof useCollectionAvailabilityLazyQuery>;
export type CollectionAvailabilityQueryResult = Apollo.QueryResult<CollectionAvailabilityQuery, CollectionAvailabilityQueryVariables>;
export const SwapAvailabilityDocument = gql`
    query swapAvailability($cityId: Int, $postcode: String) {
  availableSwapDates(cityId: $cityId, postcode: $postcode)
}
    `;

/**
 * __useSwapAvailabilityQuery__
 *
 * To run a query within a React component, call `useSwapAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwapAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwapAvailabilityQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *      postcode: // value for 'postcode'
 *   },
 * });
 */
export function useSwapAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<SwapAvailabilityQuery, SwapAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwapAvailabilityQuery, SwapAvailabilityQueryVariables>(SwapAvailabilityDocument, options);
      }
export function useSwapAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwapAvailabilityQuery, SwapAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwapAvailabilityQuery, SwapAvailabilityQueryVariables>(SwapAvailabilityDocument, options);
        }
export type SwapAvailabilityQueryHookResult = ReturnType<typeof useSwapAvailabilityQuery>;
export type SwapAvailabilityLazyQueryHookResult = ReturnType<typeof useSwapAvailabilityLazyQuery>;
export type SwapAvailabilityQueryResult = Apollo.QueryResult<SwapAvailabilityQuery, SwapAvailabilityQueryVariables>;
export const SubscriptionCreationStateDocument = gql`
    query subscriptionCreationState($id: UUID!) {
  subscriptionCreationState(id: $id) {
    errorMessage
    screeningFailed
    subscription {
      ...subscription
    }
  }
}
    ${SubscriptionFragmentDoc}`;

/**
 * __useSubscriptionCreationStateQuery__
 *
 * To run a query within a React component, call `useSubscriptionCreationStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionCreationStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionCreationStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscriptionCreationStateQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionCreationStateQuery, SubscriptionCreationStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionCreationStateQuery, SubscriptionCreationStateQueryVariables>(SubscriptionCreationStateDocument, options);
      }
export function useSubscriptionCreationStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionCreationStateQuery, SubscriptionCreationStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionCreationStateQuery, SubscriptionCreationStateQueryVariables>(SubscriptionCreationStateDocument, options);
        }
export type SubscriptionCreationStateQueryHookResult = ReturnType<typeof useSubscriptionCreationStateQuery>;
export type SubscriptionCreationStateLazyQueryHookResult = ReturnType<typeof useSubscriptionCreationStateLazyQuery>;
export type SubscriptionCreationStateQueryResult = Apollo.QueryResult<SubscriptionCreationStateQuery, SubscriptionCreationStateQueryVariables>;
export const GetBikeAvailabilityDocument = gql`
    query getBikeAvailability {
  getBikeAvailability {
    variations {
      images {
        imageUrl
        isFeatured
      }
      kitInventoryId
      code
      name
    }
    kitDescriptionId
    name
    shortDescription
  }
}
    `;

/**
 * __useGetBikeAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetBikeAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBikeAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBikeAvailabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBikeAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<GetBikeAvailabilityQuery, GetBikeAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBikeAvailabilityQuery, GetBikeAvailabilityQueryVariables>(GetBikeAvailabilityDocument, options);
      }
export function useGetBikeAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBikeAvailabilityQuery, GetBikeAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBikeAvailabilityQuery, GetBikeAvailabilityQueryVariables>(GetBikeAvailabilityDocument, options);
        }
export type GetBikeAvailabilityQueryHookResult = ReturnType<typeof useGetBikeAvailabilityQuery>;
export type GetBikeAvailabilityLazyQueryHookResult = ReturnType<typeof useGetBikeAvailabilityLazyQuery>;
export type GetBikeAvailabilityQueryResult = Apollo.QueryResult<GetBikeAvailabilityQuery, GetBikeAvailabilityQueryVariables>;
export const GetPackagesDocument = gql`
    query getPackages($bikeId: Int, $cityId: Int, $discountCode: String, $packageGroupName: String) {
  pricingPackages(
    bikeId: $bikeId
    cityId: $cityId
    discountCode: $discountCode
    packageGroupName: $packageGroupName
  ) {
    defaultPackage {
      packageSlug
      ...package
    }
    otherPackages {
      packageSlug
      ...package
    }
  }
}
    ${PackageFragmentDoc}`;

/**
 * __useGetPackagesQuery__
 *
 * To run a query within a React component, call `useGetPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagesQuery({
 *   variables: {
 *      bikeId: // value for 'bikeId'
 *      cityId: // value for 'cityId'
 *      discountCode: // value for 'discountCode'
 *      packageGroupName: // value for 'packageGroupName'
 *   },
 * });
 */
export function useGetPackagesQuery(baseOptions?: Apollo.QueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
      }
export function useGetPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
        }
export type GetPackagesQueryHookResult = ReturnType<typeof useGetPackagesQuery>;
export type GetPackagesLazyQueryHookResult = ReturnType<typeof useGetPackagesLazyQuery>;
export type GetPackagesQueryResult = Apollo.QueryResult<GetPackagesQuery, GetPackagesQueryVariables>;
export const GetPricingPackageDocument = gql`
    query getPricingPackage($packageSlug: String!) {
  getPricingPackage(packageSlug: $packageSlug) {
    ...package
  }
}
    ${PackageFragmentDoc}`;

/**
 * __useGetPricingPackageQuery__
 *
 * To run a query within a React component, call `useGetPricingPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricingPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricingPackageQuery({
 *   variables: {
 *      packageSlug: // value for 'packageSlug'
 *   },
 * });
 */
export function useGetPricingPackageQuery(baseOptions: Apollo.QueryHookOptions<GetPricingPackageQuery, GetPricingPackageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPricingPackageQuery, GetPricingPackageQueryVariables>(GetPricingPackageDocument, options);
      }
export function useGetPricingPackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPricingPackageQuery, GetPricingPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPricingPackageQuery, GetPricingPackageQueryVariables>(GetPricingPackageDocument, options);
        }
export type GetPricingPackageQueryHookResult = ReturnType<typeof useGetPricingPackageQuery>;
export type GetPricingPackageLazyQueryHookResult = ReturnType<typeof useGetPricingPackageLazyQuery>;
export type GetPricingPackageQueryResult = Apollo.QueryResult<GetPricingPackageQuery, GetPricingPackageQueryVariables>;
export const GetKitAvailabilityDocument = gql`
    query getKitAvailability($bikeId: Int, $cityId: Int) {
  __typename
  getKitAvailability(bikeId: $bikeId, cityId: $cityId) {
    brandLogoUrl
    brandName
    description
    whyWeLoveIt
    whatsIncluded
    featuresAndBenefits
    bestFor
    kitCategory {
      categoryName
      description
      imageUrl
      kitCategoryId
    }
    kitDescriptionId
    name
    returns
    shortDescription
    sizeGuide
    technicalDetails
    variations {
      code
      color
      images {
        imageUrl
        thumbnailImageUrl
        isFeatured
      }
      isSellingFast
      kitInventoryId
      name
      price
      quantity
      rrpPrice
      size
    }
  }
}
    `;

/**
 * __useGetKitAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetKitAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKitAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKitAvailabilityQuery({
 *   variables: {
 *      bikeId: // value for 'bikeId'
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useGetKitAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<GetKitAvailabilityQuery, GetKitAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKitAvailabilityQuery, GetKitAvailabilityQueryVariables>(GetKitAvailabilityDocument, options);
      }
export function useGetKitAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKitAvailabilityQuery, GetKitAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKitAvailabilityQuery, GetKitAvailabilityQueryVariables>(GetKitAvailabilityDocument, options);
        }
export type GetKitAvailabilityQueryHookResult = ReturnType<typeof useGetKitAvailabilityQuery>;
export type GetKitAvailabilityLazyQueryHookResult = ReturnType<typeof useGetKitAvailabilityLazyQuery>;
export type GetKitAvailabilityQueryResult = Apollo.QueryResult<GetKitAvailabilityQuery, GetKitAvailabilityQueryVariables>;
export const GetSubscriptionQuoteDocument = gql`
    query getSubscriptionQuote($input: GetSubscriptionQuoteInput!) {
  getSubscriptionQuote(input: $input) {
    appliedDiscountCode {
      ...appliedDiscountCode
    }
    collectionFeeInPence
    couponToApplyAtEndOfMinimumTerm {
      ...stripeCoupon
    }
    delivery {
      comparativeCost
      cost
      deliveryDate
    }
    totalDueToday
    selectedBike {
      bikeId
      images {
        imageUrl
        isFeatured
        thumbnailImageUrl
      }
      name
      variationName
    }
    selectedKitItems {
      variations {
        kitInventoryId
        price
        quantity
        code
        color
        images {
          imageUrl
          thumbnailImageUrl
          isFeatured
        }
        name
        rrpPrice
        size
      }
      bestFor
      brandLogoUrl
      brandName
      kitCategory {
        categoryName
        description
        imageUrl
        kitCategoryId
      }
      description
      kitDescriptionId
      name
      returns
      shortDescription
      sizeGuide
      technicalDetails
      whyWeLoveIt
      whatsIncluded
    }
    setupFeeInPence
    theftFeeAddOnPriceInPence
    subscription {
      ... on MonthlyQuote {
        cancellation {
          earliestDate
          noticePeriodDays
        }
        monthlyCommitment: commitment {
          lengthMonths
          monthlyRateAfterwards
        }
        noMaintenance
        revertsTo {
          delayMonths
          stickerPrice
        }
        stickerPrice
        charged
      }
      ... on FixedTermQuote {
        cancellation {
          earliestDate
          noticePeriodDays
        }
        fixedTermCommitment: commitment {
          lengthMonths
          monthlyRateAfterwards
        }
        noMaintenance
        stickerPrice
      }
    }
  }
}
    ${AppliedDiscountCodeFragmentDoc}
${StripeCouponFragmentDoc}`;

/**
 * __useGetSubscriptionQuoteQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuoteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSubscriptionQuoteQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriptionQuoteQuery, GetSubscriptionQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionQuoteQuery, GetSubscriptionQuoteQueryVariables>(GetSubscriptionQuoteDocument, options);
      }
export function useGetSubscriptionQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionQuoteQuery, GetSubscriptionQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionQuoteQuery, GetSubscriptionQuoteQueryVariables>(GetSubscriptionQuoteDocument, options);
        }
export type GetSubscriptionQuoteQueryHookResult = ReturnType<typeof useGetSubscriptionQuoteQuery>;
export type GetSubscriptionQuoteLazyQueryHookResult = ReturnType<typeof useGetSubscriptionQuoteLazyQuery>;
export type GetSubscriptionQuoteQueryResult = Apollo.QueryResult<GetSubscriptionQuoteQuery, GetSubscriptionQuoteQueryVariables>;
export const StartSetupStripePaymentDocument = gql`
    mutation startSetupStripePayment {
  startSetupStripePaymentAnonymous {
    clientSecret
    setupIntentId
  }
}
    `;
export type StartSetupStripePaymentMutationFn = Apollo.MutationFunction<StartSetupStripePaymentMutation, StartSetupStripePaymentMutationVariables>;

/**
 * __useStartSetupStripePaymentMutation__
 *
 * To run a mutation, you first call `useStartSetupStripePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSetupStripePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSetupStripePaymentMutation, { data, loading, error }] = useStartSetupStripePaymentMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartSetupStripePaymentMutation(baseOptions?: Apollo.MutationHookOptions<StartSetupStripePaymentMutation, StartSetupStripePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSetupStripePaymentMutation, StartSetupStripePaymentMutationVariables>(StartSetupStripePaymentDocument, options);
      }
export type StartSetupStripePaymentMutationHookResult = ReturnType<typeof useStartSetupStripePaymentMutation>;
export type StartSetupStripePaymentMutationResult = Apollo.MutationResult<StartSetupStripePaymentMutation>;
export type StartSetupStripePaymentMutationOptions = Apollo.BaseMutationOptions<StartSetupStripePaymentMutation, StartSetupStripePaymentMutationVariables>;
export const CheckoutSubscriptionDocument = gql`
    mutation checkoutSubscription($input: CreateSubscriptionInput!) {
  startSubscriptionCreation(input: $input) {
    ... on CreateSubscriptionFailed {
      errorMessage
    }
    ... on CreateSubscriptionStarted {
      id
    }
  }
}
    `;
export type CheckoutSubscriptionMutationFn = Apollo.MutationFunction<CheckoutSubscriptionMutation, CheckoutSubscriptionMutationVariables>;

/**
 * __useCheckoutSubscriptionMutation__
 *
 * To run a mutation, you first call `useCheckoutSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutSubscriptionMutation, { data, loading, error }] = useCheckoutSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutSubscriptionMutation, CheckoutSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutSubscriptionMutation, CheckoutSubscriptionMutationVariables>(CheckoutSubscriptionDocument, options);
      }
export type CheckoutSubscriptionMutationHookResult = ReturnType<typeof useCheckoutSubscriptionMutation>;
export type CheckoutSubscriptionMutationResult = Apollo.MutationResult<CheckoutSubscriptionMutation>;
export type CheckoutSubscriptionMutationOptions = Apollo.BaseMutationOptions<CheckoutSubscriptionMutation, CheckoutSubscriptionMutationVariables>;
export const GetChangingPlanQuoteDocument = gql`
    query getChangingPlanQuote($input: GetChangingPlanQuoteInput!) {
  getChangingPlanQuote(input: $input) {
    ... on ChangingPlanQuote {
      currentPackage {
        ...package
      }
      newPackage {
        ...package
      }
      additionalProRataInPence
      appliedDiscountCode
      nextMonthlyInvoiceDate
      endDate
      startDate
      theftFeeAddOnPriceInPence
      newSubscription {
        ... on MonthlyQuote {
          cancellation {
            earliestDate
            noticePeriodDays
          }
          monthlyCommitment: commitment {
            lengthMonths
            monthlyRateAfterwards
          }
          revertsTo {
            delayMonths
            stickerPrice
          }
          stickerPrice
          charged
        }
        ... on FixedTermQuote {
          cancellation {
            earliestDate
            noticePeriodDays
          }
          fixedTermCommitment: commitment {
            lengthMonths
            monthlyRateAfterwards
          }
          stickerPrice
        }
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${PackageFragmentDoc}`;

/**
 * __useGetChangingPlanQuoteQuery__
 *
 * To run a query within a React component, call `useGetChangingPlanQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangingPlanQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangingPlanQuoteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChangingPlanQuoteQuery(baseOptions: Apollo.QueryHookOptions<GetChangingPlanQuoteQuery, GetChangingPlanQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChangingPlanQuoteQuery, GetChangingPlanQuoteQueryVariables>(GetChangingPlanQuoteDocument, options);
      }
export function useGetChangingPlanQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChangingPlanQuoteQuery, GetChangingPlanQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChangingPlanQuoteQuery, GetChangingPlanQuoteQueryVariables>(GetChangingPlanQuoteDocument, options);
        }
export type GetChangingPlanQuoteQueryHookResult = ReturnType<typeof useGetChangingPlanQuoteQuery>;
export type GetChangingPlanQuoteLazyQueryHookResult = ReturnType<typeof useGetChangingPlanQuoteLazyQuery>;
export type GetChangingPlanQuoteQueryResult = Apollo.QueryResult<GetChangingPlanQuoteQuery, GetChangingPlanQuoteQueryVariables>;
export const GetTheftFeeAddOnPriceAtSingupDocument = gql`
    query getTheftFeeAddOnPriceAtSingup($bikeId: Int) {
  getTheftFeeAddOnPriceAtSingup(bikeId: $bikeId) {
    ... on TheftFeeAddOnPriceType {
      applyFrom
      theftFeeAddOnPriceInPence
    }
    ... on APIError {
      message
    }
  }
}
    `;

/**
 * __useGetTheftFeeAddOnPriceAtSingupQuery__
 *
 * To run a query within a React component, call `useGetTheftFeeAddOnPriceAtSingupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTheftFeeAddOnPriceAtSingupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTheftFeeAddOnPriceAtSingupQuery({
 *   variables: {
 *      bikeId: // value for 'bikeId'
 *   },
 * });
 */
export function useGetTheftFeeAddOnPriceAtSingupQuery(baseOptions?: Apollo.QueryHookOptions<GetTheftFeeAddOnPriceAtSingupQuery, GetTheftFeeAddOnPriceAtSingupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTheftFeeAddOnPriceAtSingupQuery, GetTheftFeeAddOnPriceAtSingupQueryVariables>(GetTheftFeeAddOnPriceAtSingupDocument, options);
      }
export function useGetTheftFeeAddOnPriceAtSingupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTheftFeeAddOnPriceAtSingupQuery, GetTheftFeeAddOnPriceAtSingupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTheftFeeAddOnPriceAtSingupQuery, GetTheftFeeAddOnPriceAtSingupQueryVariables>(GetTheftFeeAddOnPriceAtSingupDocument, options);
        }
export type GetTheftFeeAddOnPriceAtSingupQueryHookResult = ReturnType<typeof useGetTheftFeeAddOnPriceAtSingupQuery>;
export type GetTheftFeeAddOnPriceAtSingupLazyQueryHookResult = ReturnType<typeof useGetTheftFeeAddOnPriceAtSingupLazyQuery>;
export type GetTheftFeeAddOnPriceAtSingupQueryResult = Apollo.QueryResult<GetTheftFeeAddOnPriceAtSingupQuery, GetTheftFeeAddOnPriceAtSingupQueryVariables>;
export const CreateAccountDocument = gql`
    mutation createAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    __typename
    ... on CreateAccountResult {
      accessToken
      viewer {
        ...viewer
      }
    }
    ... on CreateUserErrorDuplicate {
      message
    }
  }
}
    ${ViewerFragmentDoc}`;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    ...viewer
  }
}
    ${ViewerFragmentDoc}`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    ... on ChangePasswordResult {
      message
    }
    ... on ChangePasswordError {
      message
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    ... on ResetPasswordResult {
      message
    }
    ... on ResetPasswordError {
      message
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RedeemResetPasswordTokenDocument = gql`
    mutation redeemResetPasswordToken($input: RedeemResetPasswordTokenInput!) {
  redeemResetPasswordToken(input: $input) {
    ... on ResetPasswordResult {
      message
    }
    ... on ResetPasswordError {
      message
    }
  }
}
    `;
export type RedeemResetPasswordTokenMutationFn = Apollo.MutationFunction<RedeemResetPasswordTokenMutation, RedeemResetPasswordTokenMutationVariables>;

/**
 * __useRedeemResetPasswordTokenMutation__
 *
 * To run a mutation, you first call `useRedeemResetPasswordTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemResetPasswordTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemResetPasswordTokenMutation, { data, loading, error }] = useRedeemResetPasswordTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedeemResetPasswordTokenMutation(baseOptions?: Apollo.MutationHookOptions<RedeemResetPasswordTokenMutation, RedeemResetPasswordTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemResetPasswordTokenMutation, RedeemResetPasswordTokenMutationVariables>(RedeemResetPasswordTokenDocument, options);
      }
export type RedeemResetPasswordTokenMutationHookResult = ReturnType<typeof useRedeemResetPasswordTokenMutation>;
export type RedeemResetPasswordTokenMutationResult = Apollo.MutationResult<RedeemResetPasswordTokenMutation>;
export type RedeemResetPasswordTokenMutationOptions = Apollo.BaseMutationOptions<RedeemResetPasswordTokenMutation, RedeemResetPasswordTokenMutationVariables>;
export const ApplyPromoCodeDocument = gql`
    mutation applyPromoCode($promoCode: String!) {
  echo(input: {message: $promoCode}) {
    ... on EchoSuccess {
      message: message
    }
    ... on EchoError {
      error: error
    }
  }
}
    `;
export type ApplyPromoCodeMutationFn = Apollo.MutationFunction<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>;

/**
 * __useApplyPromoCodeMutation__
 *
 * To run a mutation, you first call `useApplyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoCodeMutation, { data, loading, error }] = useApplyPromoCodeMutation({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useApplyPromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>(ApplyPromoCodeDocument, options);
      }
export type ApplyPromoCodeMutationHookResult = ReturnType<typeof useApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationResult = Apollo.MutationResult<ApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationOptions = Apollo.BaseMutationOptions<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>;
export const StartEditStripePaymentDocument = gql`
    mutation startEditStripePayment {
  startEditStripePayment {
    clientSecret
    setupIntentId
  }
}
    `;
export type StartEditStripePaymentMutationFn = Apollo.MutationFunction<StartEditStripePaymentMutation, StartEditStripePaymentMutationVariables>;

/**
 * __useStartEditStripePaymentMutation__
 *
 * To run a mutation, you first call `useStartEditStripePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartEditStripePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startEditStripePaymentMutation, { data, loading, error }] = useStartEditStripePaymentMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartEditStripePaymentMutation(baseOptions?: Apollo.MutationHookOptions<StartEditStripePaymentMutation, StartEditStripePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartEditStripePaymentMutation, StartEditStripePaymentMutationVariables>(StartEditStripePaymentDocument, options);
      }
export type StartEditStripePaymentMutationHookResult = ReturnType<typeof useStartEditStripePaymentMutation>;
export type StartEditStripePaymentMutationResult = Apollo.MutationResult<StartEditStripePaymentMutation>;
export type StartEditStripePaymentMutationOptions = Apollo.BaseMutationOptions<StartEditStripePaymentMutation, StartEditStripePaymentMutationVariables>;
export const FinishEditStripePaymentDocument = gql`
    mutation finishEditStripePayment($setupIntentId: String!) {
  finishEditStripePayment(setupIntentId: $setupIntentId) {
    cardholderName
    expMonth
    expYear
    last4
  }
}
    `;
export type FinishEditStripePaymentMutationFn = Apollo.MutationFunction<FinishEditStripePaymentMutation, FinishEditStripePaymentMutationVariables>;

/**
 * __useFinishEditStripePaymentMutation__
 *
 * To run a mutation, you first call `useFinishEditStripePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishEditStripePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishEditStripePaymentMutation, { data, loading, error }] = useFinishEditStripePaymentMutation({
 *   variables: {
 *      setupIntentId: // value for 'setupIntentId'
 *   },
 * });
 */
export function useFinishEditStripePaymentMutation(baseOptions?: Apollo.MutationHookOptions<FinishEditStripePaymentMutation, FinishEditStripePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishEditStripePaymentMutation, FinishEditStripePaymentMutationVariables>(FinishEditStripePaymentDocument, options);
      }
export type FinishEditStripePaymentMutationHookResult = ReturnType<typeof useFinishEditStripePaymentMutation>;
export type FinishEditStripePaymentMutationResult = Apollo.MutationResult<FinishEditStripePaymentMutation>;
export type FinishEditStripePaymentMutationOptions = Apollo.BaseMutationOptions<FinishEditStripePaymentMutation, FinishEditStripePaymentMutationVariables>;
export const UpdateMarketingPreferencesDocument = gql`
    mutation updateMarketingPreferences($email: String!, $optedIn: Boolean!) {
  updateMarketingPreferences(email: $email, optedIn: $optedIn)
}
    `;
export type UpdateMarketingPreferencesMutationFn = Apollo.MutationFunction<UpdateMarketingPreferencesMutation, UpdateMarketingPreferencesMutationVariables>;

/**
 * __useUpdateMarketingPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateMarketingPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketingPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketingPreferencesMutation, { data, loading, error }] = useUpdateMarketingPreferencesMutation({
 *   variables: {
 *      email: // value for 'email'
 *      optedIn: // value for 'optedIn'
 *   },
 * });
 */
export function useUpdateMarketingPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketingPreferencesMutation, UpdateMarketingPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketingPreferencesMutation, UpdateMarketingPreferencesMutationVariables>(UpdateMarketingPreferencesDocument, options);
      }
export type UpdateMarketingPreferencesMutationHookResult = ReturnType<typeof useUpdateMarketingPreferencesMutation>;
export type UpdateMarketingPreferencesMutationResult = Apollo.MutationResult<UpdateMarketingPreferencesMutation>;
export type UpdateMarketingPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateMarketingPreferencesMutation, UpdateMarketingPreferencesMutationVariables>;
export const CreateRejoinerDocument = gql`
    mutation createRejoiner {
  createRejoiner {
    ... on CreateAccountResult {
      accessToken
      viewer {
        ...viewer
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${ViewerFragmentDoc}`;
export type CreateRejoinerMutationFn = Apollo.MutationFunction<CreateRejoinerMutation, CreateRejoinerMutationVariables>;

/**
 * __useCreateRejoinerMutation__
 *
 * To run a mutation, you first call `useCreateRejoinerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRejoinerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRejoinerMutation, { data, loading, error }] = useCreateRejoinerMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateRejoinerMutation(baseOptions?: Apollo.MutationHookOptions<CreateRejoinerMutation, CreateRejoinerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRejoinerMutation, CreateRejoinerMutationVariables>(CreateRejoinerDocument, options);
      }
export type CreateRejoinerMutationHookResult = ReturnType<typeof useCreateRejoinerMutation>;
export type CreateRejoinerMutationResult = Apollo.MutationResult<CreateRejoinerMutation>;
export type CreateRejoinerMutationOptions = Apollo.BaseMutationOptions<CreateRejoinerMutation, CreateRejoinerMutationVariables>;
export const ChangePlanDocument = gql`
    mutation changePlan($input: ChangingPlanData!) {
  changePlan(input: $input) {
    ... on SubscribedPackages {
      currentPackage {
        ...subscribedPackage
      }
      nextPackage {
        ...subscribedPackage
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${SubscribedPackageFragmentDoc}`;
export type ChangePlanMutationFn = Apollo.MutationFunction<ChangePlanMutation, ChangePlanMutationVariables>;

/**
 * __useChangePlanMutation__
 *
 * To run a mutation, you first call `useChangePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePlanMutation, { data, loading, error }] = useChangePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePlanMutation(baseOptions?: Apollo.MutationHookOptions<ChangePlanMutation, ChangePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePlanMutation, ChangePlanMutationVariables>(ChangePlanDocument, options);
      }
export type ChangePlanMutationHookResult = ReturnType<typeof useChangePlanMutation>;
export type ChangePlanMutationResult = Apollo.MutationResult<ChangePlanMutation>;
export type ChangePlanMutationOptions = Apollo.BaseMutationOptions<ChangePlanMutation, ChangePlanMutationVariables>;
export const UpdateKitOrderDocument = gql`
    mutation updateKitOrder($input: DeliveryOptionsInput!) {
  updateKitOrder(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type UpdateKitOrderMutationFn = Apollo.MutationFunction<UpdateKitOrderMutation, UpdateKitOrderMutationVariables>;

/**
 * __useUpdateKitOrderMutation__
 *
 * To run a mutation, you first call `useUpdateKitOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitOrderMutation, { data, loading, error }] = useUpdateKitOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKitOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKitOrderMutation, UpdateKitOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKitOrderMutation, UpdateKitOrderMutationVariables>(UpdateKitOrderDocument, options);
      }
export type UpdateKitOrderMutationHookResult = ReturnType<typeof useUpdateKitOrderMutation>;
export type UpdateKitOrderMutationResult = Apollo.MutationResult<UpdateKitOrderMutation>;
export type UpdateKitOrderMutationOptions = Apollo.BaseMutationOptions<UpdateKitOrderMutation, UpdateKitOrderMutationVariables>;
export const UpdateRiderDocument = gql`
    mutation updateRider($input: UpdateRiderInput!) {
  updateRider(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type UpdateRiderMutationFn = Apollo.MutationFunction<UpdateRiderMutation, UpdateRiderMutationVariables>;

/**
 * __useUpdateRiderMutation__
 *
 * To run a mutation, you first call `useUpdateRiderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRiderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRiderMutation, { data, loading, error }] = useUpdateRiderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRiderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRiderMutation, UpdateRiderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRiderMutation, UpdateRiderMutationVariables>(UpdateRiderDocument, options);
      }
export type UpdateRiderMutationHookResult = ReturnType<typeof useUpdateRiderMutation>;
export type UpdateRiderMutationResult = Apollo.MutationResult<UpdateRiderMutation>;
export type UpdateRiderMutationOptions = Apollo.BaseMutationOptions<UpdateRiderMutation, UpdateRiderMutationVariables>;
export const ApplyRetentionDiscountDocument = gql`
    mutation applyRetentionDiscount($discountCode: String!) {
  applyRetentionDiscount(discountCode: $discountCode) {
    ... on StripeUpcomingInvoice {
      ...stripeUpcomingInvoice
    }
    ... on APIError {
      message
    }
  }
}
    ${StripeUpcomingInvoiceFragmentDoc}`;
export type ApplyRetentionDiscountMutationFn = Apollo.MutationFunction<ApplyRetentionDiscountMutation, ApplyRetentionDiscountMutationVariables>;

/**
 * __useApplyRetentionDiscountMutation__
 *
 * To run a mutation, you first call `useApplyRetentionDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyRetentionDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyRetentionDiscountMutation, { data, loading, error }] = useApplyRetentionDiscountMutation({
 *   variables: {
 *      discountCode: // value for 'discountCode'
 *   },
 * });
 */
export function useApplyRetentionDiscountMutation(baseOptions?: Apollo.MutationHookOptions<ApplyRetentionDiscountMutation, ApplyRetentionDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyRetentionDiscountMutation, ApplyRetentionDiscountMutationVariables>(ApplyRetentionDiscountDocument, options);
      }
export type ApplyRetentionDiscountMutationHookResult = ReturnType<typeof useApplyRetentionDiscountMutation>;
export type ApplyRetentionDiscountMutationResult = Apollo.MutationResult<ApplyRetentionDiscountMutation>;
export type ApplyRetentionDiscountMutationOptions = Apollo.BaseMutationOptions<ApplyRetentionDiscountMutation, ApplyRetentionDiscountMutationVariables>;
export const PauseCollectionPaymentDocument = gql`
    mutation pauseCollectionPayment($chargeAmountInPence: Int!, $months: Int!) {
  pauseCollectionPayment(
    chargeAmountInPence: $chargeAmountInPence
    months: $months
  ) {
    ... on StripeUpcomingInvoice {
      ...stripeUpcomingInvoice
    }
    ... on APIError {
      message
    }
  }
}
    ${StripeUpcomingInvoiceFragmentDoc}`;
export type PauseCollectionPaymentMutationFn = Apollo.MutationFunction<PauseCollectionPaymentMutation, PauseCollectionPaymentMutationVariables>;

/**
 * __usePauseCollectionPaymentMutation__
 *
 * To run a mutation, you first call `usePauseCollectionPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseCollectionPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseCollectionPaymentMutation, { data, loading, error }] = usePauseCollectionPaymentMutation({
 *   variables: {
 *      chargeAmountInPence: // value for 'chargeAmountInPence'
 *      months: // value for 'months'
 *   },
 * });
 */
export function usePauseCollectionPaymentMutation(baseOptions?: Apollo.MutationHookOptions<PauseCollectionPaymentMutation, PauseCollectionPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PauseCollectionPaymentMutation, PauseCollectionPaymentMutationVariables>(PauseCollectionPaymentDocument, options);
      }
export type PauseCollectionPaymentMutationHookResult = ReturnType<typeof usePauseCollectionPaymentMutation>;
export type PauseCollectionPaymentMutationResult = Apollo.MutationResult<PauseCollectionPaymentMutation>;
export type PauseCollectionPaymentMutationOptions = Apollo.BaseMutationOptions<PauseCollectionPaymentMutation, PauseCollectionPaymentMutationVariables>;
export const JoinTheWaitlistDocument = gql`
    mutation joinTheWaitlist($input: WaitlistInput!) {
  __typename
  joinTheWaitlist(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type JoinTheWaitlistMutationFn = Apollo.MutationFunction<JoinTheWaitlistMutation, JoinTheWaitlistMutationVariables>;

/**
 * __useJoinTheWaitlistMutation__
 *
 * To run a mutation, you first call `useJoinTheWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTheWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTheWaitlistMutation, { data, loading, error }] = useJoinTheWaitlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinTheWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<JoinTheWaitlistMutation, JoinTheWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinTheWaitlistMutation, JoinTheWaitlistMutationVariables>(JoinTheWaitlistDocument, options);
      }
export type JoinTheWaitlistMutationHookResult = ReturnType<typeof useJoinTheWaitlistMutation>;
export type JoinTheWaitlistMutationResult = Apollo.MutationResult<JoinTheWaitlistMutation>;
export type JoinTheWaitlistMutationOptions = Apollo.BaseMutationOptions<JoinTheWaitlistMutation, JoinTheWaitlistMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount {
  deleteAccount {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const CancelChangingPlanDocument = gql`
    mutation cancelChangingPlan {
  cancelChangingPlan {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type CancelChangingPlanMutationFn = Apollo.MutationFunction<CancelChangingPlanMutation, CancelChangingPlanMutationVariables>;

/**
 * __useCancelChangingPlanMutation__
 *
 * To run a mutation, you first call `useCancelChangingPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelChangingPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelChangingPlanMutation, { data, loading, error }] = useCancelChangingPlanMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelChangingPlanMutation(baseOptions?: Apollo.MutationHookOptions<CancelChangingPlanMutation, CancelChangingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelChangingPlanMutation, CancelChangingPlanMutationVariables>(CancelChangingPlanDocument, options);
      }
export type CancelChangingPlanMutationHookResult = ReturnType<typeof useCancelChangingPlanMutation>;
export type CancelChangingPlanMutationResult = Apollo.MutationResult<CancelChangingPlanMutation>;
export type CancelChangingPlanMutationOptions = Apollo.BaseMutationOptions<CancelChangingPlanMutation, CancelChangingPlanMutationVariables>;
export const BuyYourBikeDocument = gql`
    mutation buyYourBike($priceInPence: Int) {
  buyYourBike(priceInPence: $priceInPence) {
    ... on InvoicePdf {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type BuyYourBikeMutationFn = Apollo.MutationFunction<BuyYourBikeMutation, BuyYourBikeMutationVariables>;

/**
 * __useBuyYourBikeMutation__
 *
 * To run a mutation, you first call `useBuyYourBikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyYourBikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyYourBikeMutation, { data, loading, error }] = useBuyYourBikeMutation({
 *   variables: {
 *      priceInPence: // value for 'priceInPence'
 *   },
 * });
 */
export function useBuyYourBikeMutation(baseOptions?: Apollo.MutationHookOptions<BuyYourBikeMutation, BuyYourBikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyYourBikeMutation, BuyYourBikeMutationVariables>(BuyYourBikeDocument, options);
      }
export type BuyYourBikeMutationHookResult = ReturnType<typeof useBuyYourBikeMutation>;
export type BuyYourBikeMutationResult = Apollo.MutationResult<BuyYourBikeMutation>;
export type BuyYourBikeMutationOptions = Apollo.BaseMutationOptions<BuyYourBikeMutation, BuyYourBikeMutationVariables>;
export const ReportAnIssueDocument = gql`
    mutation reportAnIssue($input: IssueReportInput!) {
  reportAnIssue(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type ReportAnIssueMutationFn = Apollo.MutationFunction<ReportAnIssueMutation, ReportAnIssueMutationVariables>;

/**
 * __useReportAnIssueMutation__
 *
 * To run a mutation, you first call `useReportAnIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportAnIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportAnIssueMutation, { data, loading, error }] = useReportAnIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportAnIssueMutation(baseOptions?: Apollo.MutationHookOptions<ReportAnIssueMutation, ReportAnIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportAnIssueMutation, ReportAnIssueMutationVariables>(ReportAnIssueDocument, options);
      }
export type ReportAnIssueMutationHookResult = ReturnType<typeof useReportAnIssueMutation>;
export type ReportAnIssueMutationResult = Apollo.MutationResult<ReportAnIssueMutation>;
export type ReportAnIssueMutationOptions = Apollo.BaseMutationOptions<ReportAnIssueMutation, ReportAnIssueMutationVariables>;
export const RescheduleAnIssueDocument = gql`
    mutation rescheduleAnIssue($input: RescheduleIssueReportInput!) {
  rescheduleAnIssue(input: $input) {
    ... on IssueReportData {
      ...issueReportData
    }
    ... on APIError {
      message
    }
  }
}
    ${IssueReportDataFragmentDoc}`;
export type RescheduleAnIssueMutationFn = Apollo.MutationFunction<RescheduleAnIssueMutation, RescheduleAnIssueMutationVariables>;

/**
 * __useRescheduleAnIssueMutation__
 *
 * To run a mutation, you first call `useRescheduleAnIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleAnIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleAnIssueMutation, { data, loading, error }] = useRescheduleAnIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleAnIssueMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleAnIssueMutation, RescheduleAnIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleAnIssueMutation, RescheduleAnIssueMutationVariables>(RescheduleAnIssueDocument, options);
      }
export type RescheduleAnIssueMutationHookResult = ReturnType<typeof useRescheduleAnIssueMutation>;
export type RescheduleAnIssueMutationResult = Apollo.MutationResult<RescheduleAnIssueMutation>;
export type RescheduleAnIssueMutationOptions = Apollo.BaseMutationOptions<RescheduleAnIssueMutation, RescheduleAnIssueMutationVariables>;
export const RescheduleCollectionDocument = gql`
    mutation rescheduleCollection($input: OrderReschedulingInput!) {
  rescheduleCollectionOrder(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type RescheduleCollectionMutationFn = Apollo.MutationFunction<RescheduleCollectionMutation, RescheduleCollectionMutationVariables>;

/**
 * __useRescheduleCollectionMutation__
 *
 * To run a mutation, you first call `useRescheduleCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleCollectionMutation, { data, loading, error }] = useRescheduleCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleCollectionMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleCollectionMutation, RescheduleCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleCollectionMutation, RescheduleCollectionMutationVariables>(RescheduleCollectionDocument, options);
      }
export type RescheduleCollectionMutationHookResult = ReturnType<typeof useRescheduleCollectionMutation>;
export type RescheduleCollectionMutationResult = Apollo.MutationResult<RescheduleCollectionMutation>;
export type RescheduleCollectionMutationOptions = Apollo.BaseMutationOptions<RescheduleCollectionMutation, RescheduleCollectionMutationVariables>;
export const RevertCancellationDocument = gql`
    mutation revertCancellation {
  revertCancellation {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type RevertCancellationMutationFn = Apollo.MutationFunction<RevertCancellationMutation, RevertCancellationMutationVariables>;

/**
 * __useRevertCancellationMutation__
 *
 * To run a mutation, you first call `useRevertCancellationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertCancellationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertCancellationMutation, { data, loading, error }] = useRevertCancellationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRevertCancellationMutation(baseOptions?: Apollo.MutationHookOptions<RevertCancellationMutation, RevertCancellationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertCancellationMutation, RevertCancellationMutationVariables>(RevertCancellationDocument, options);
      }
export type RevertCancellationMutationHookResult = ReturnType<typeof useRevertCancellationMutation>;
export type RevertCancellationMutationResult = Apollo.MutationResult<RevertCancellationMutation>;
export type RevertCancellationMutationOptions = Apollo.BaseMutationOptions<RevertCancellationMutation, RevertCancellationMutationVariables>;
export const ClaimLoyaltyRewardDocument = gql`
    mutation claimLoyaltyReward($rewardKey: RewardJourneyKey!, $buzziversaryOrder: KitOrderTypeInput) {
  claimLoyaltyReward(rewardKey: $rewardKey, buzziversaryOrder: $buzziversaryOrder) {
    ... on StripeUpcomingInvoice {
      ...stripeUpcomingInvoice
    }
    ... on IssueReportType {
      category
      subcategory
      address
      appointmentEndTime
      appointmentStartTime
      reportNumber
    }
    ... on APIError {
      message
    }
  }
}
    ${StripeUpcomingInvoiceFragmentDoc}`;
export type ClaimLoyaltyRewardMutationFn = Apollo.MutationFunction<ClaimLoyaltyRewardMutation, ClaimLoyaltyRewardMutationVariables>;

/**
 * __useClaimLoyaltyRewardMutation__
 *
 * To run a mutation, you first call `useClaimLoyaltyRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimLoyaltyRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimLoyaltyRewardMutation, { data, loading, error }] = useClaimLoyaltyRewardMutation({
 *   variables: {
 *      rewardKey: // value for 'rewardKey'
 *      buzziversaryOrder: // value for 'buzziversaryOrder'
 *   },
 * });
 */
export function useClaimLoyaltyRewardMutation(baseOptions?: Apollo.MutationHookOptions<ClaimLoyaltyRewardMutation, ClaimLoyaltyRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimLoyaltyRewardMutation, ClaimLoyaltyRewardMutationVariables>(ClaimLoyaltyRewardDocument, options);
      }
export type ClaimLoyaltyRewardMutationHookResult = ReturnType<typeof useClaimLoyaltyRewardMutation>;
export type ClaimLoyaltyRewardMutationResult = Apollo.MutationResult<ClaimLoyaltyRewardMutation>;
export type ClaimLoyaltyRewardMutationOptions = Apollo.BaseMutationOptions<ClaimLoyaltyRewardMutation, ClaimLoyaltyRewardMutationVariables>;
export const ChangePaymentDateDocument = gql`
    mutation changePaymentDate($requestedDay: Int!) {
  changePaymentDate(requestedDay: $requestedDay) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type ChangePaymentDateMutationFn = Apollo.MutationFunction<ChangePaymentDateMutation, ChangePaymentDateMutationVariables>;

/**
 * __useChangePaymentDateMutation__
 *
 * To run a mutation, you first call `useChangePaymentDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePaymentDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePaymentDateMutation, { data, loading, error }] = useChangePaymentDateMutation({
 *   variables: {
 *      requestedDay: // value for 'requestedDay'
 *   },
 * });
 */
export function useChangePaymentDateMutation(baseOptions?: Apollo.MutationHookOptions<ChangePaymentDateMutation, ChangePaymentDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePaymentDateMutation, ChangePaymentDateMutationVariables>(ChangePaymentDateDocument, options);
      }
export type ChangePaymentDateMutationHookResult = ReturnType<typeof useChangePaymentDateMutation>;
export type ChangePaymentDateMutationResult = Apollo.MutationResult<ChangePaymentDateMutation>;
export type ChangePaymentDateMutationOptions = Apollo.BaseMutationOptions<ChangePaymentDateMutation, ChangePaymentDateMutationVariables>;
export const CreateKlarnaUrlDocument = gql`
    mutation createKlarnaUrl($invoiceId: String!, $redirectUrl: String!) {
  createKlarnaUrl(invoiceId: $invoiceId, redirectUrl: $redirectUrl) {
    ... on KlarnaObject {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type CreateKlarnaUrlMutationFn = Apollo.MutationFunction<CreateKlarnaUrlMutation, CreateKlarnaUrlMutationVariables>;

/**
 * __useCreateKlarnaUrlMutation__
 *
 * To run a mutation, you first call `useCreateKlarnaUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKlarnaUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKlarnaUrlMutation, { data, loading, error }] = useCreateKlarnaUrlMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCreateKlarnaUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateKlarnaUrlMutation, CreateKlarnaUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKlarnaUrlMutation, CreateKlarnaUrlMutationVariables>(CreateKlarnaUrlDocument, options);
      }
export type CreateKlarnaUrlMutationHookResult = ReturnType<typeof useCreateKlarnaUrlMutation>;
export type CreateKlarnaUrlMutationResult = Apollo.MutationResult<CreateKlarnaUrlMutation>;
export type CreateKlarnaUrlMutationOptions = Apollo.BaseMutationOptions<CreateKlarnaUrlMutation, CreateKlarnaUrlMutationVariables>;
export const UpdateBillingAddressDocument = gql`
    mutation updateBillingAddress($input: UpdateBillingAddressInput!) {
  updateBillingAddress(input: $input) {
    message
  }
}
    `;
export type UpdateBillingAddressMutationFn = Apollo.MutationFunction<UpdateBillingAddressMutation, UpdateBillingAddressMutationVariables>;

/**
 * __useUpdateBillingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingAddressMutation, { data, loading, error }] = useUpdateBillingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingAddressMutation, UpdateBillingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingAddressMutation, UpdateBillingAddressMutationVariables>(UpdateBillingAddressDocument, options);
      }
export type UpdateBillingAddressMutationHookResult = ReturnType<typeof useUpdateBillingAddressMutation>;
export type UpdateBillingAddressMutationResult = Apollo.MutationResult<UpdateBillingAddressMutation>;
export type UpdateBillingAddressMutationOptions = Apollo.BaseMutationOptions<UpdateBillingAddressMutation, UpdateBillingAddressMutationVariables>;
export const SubscribeTheftFeeAddOnDocument = gql`
    mutation subscribeTheftFeeAddOn {
  subscribeTheftFeeAddOn {
    ... on SubscriptionTheftFeeAddOnType {
      ...subscriptionTheftFeeAddOnType
    }
    ... on APIError {
      message
    }
  }
}
    ${SubscriptionTheftFeeAddOnTypeFragmentDoc}`;
export type SubscribeTheftFeeAddOnMutationFn = Apollo.MutationFunction<SubscribeTheftFeeAddOnMutation, SubscribeTheftFeeAddOnMutationVariables>;

/**
 * __useSubscribeTheftFeeAddOnMutation__
 *
 * To run a mutation, you first call `useSubscribeTheftFeeAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeTheftFeeAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeTheftFeeAddOnMutation, { data, loading, error }] = useSubscribeTheftFeeAddOnMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeTheftFeeAddOnMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeTheftFeeAddOnMutation, SubscribeTheftFeeAddOnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeTheftFeeAddOnMutation, SubscribeTheftFeeAddOnMutationVariables>(SubscribeTheftFeeAddOnDocument, options);
      }
export type SubscribeTheftFeeAddOnMutationHookResult = ReturnType<typeof useSubscribeTheftFeeAddOnMutation>;
export type SubscribeTheftFeeAddOnMutationResult = Apollo.MutationResult<SubscribeTheftFeeAddOnMutation>;
export type SubscribeTheftFeeAddOnMutationOptions = Apollo.BaseMutationOptions<SubscribeTheftFeeAddOnMutation, SubscribeTheftFeeAddOnMutationVariables>;
export const CancelTheftFeeAddOnDocument = gql`
    mutation cancelTheftFeeAddOn {
  cancelTheftFeeAddOn {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type CancelTheftFeeAddOnMutationFn = Apollo.MutationFunction<CancelTheftFeeAddOnMutation, CancelTheftFeeAddOnMutationVariables>;

/**
 * __useCancelTheftFeeAddOnMutation__
 *
 * To run a mutation, you first call `useCancelTheftFeeAddOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTheftFeeAddOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTheftFeeAddOnMutation, { data, loading, error }] = useCancelTheftFeeAddOnMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelTheftFeeAddOnMutation(baseOptions?: Apollo.MutationHookOptions<CancelTheftFeeAddOnMutation, CancelTheftFeeAddOnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTheftFeeAddOnMutation, CancelTheftFeeAddOnMutationVariables>(CancelTheftFeeAddOnDocument, options);
      }
export type CancelTheftFeeAddOnMutationHookResult = ReturnType<typeof useCancelTheftFeeAddOnMutation>;
export type CancelTheftFeeAddOnMutationResult = Apollo.MutationResult<CancelTheftFeeAddOnMutation>;
export type CancelTheftFeeAddOnMutationOptions = Apollo.BaseMutationOptions<CancelTheftFeeAddOnMutation, CancelTheftFeeAddOnMutationVariables>;
export const JoinTheGeoggraphicWaitlistDocument = gql`
    mutation joinTheGeoggraphicWaitlist($input: GeographicWaitlistInput!) {
  joinTheGeoggraphicWaitlist(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type JoinTheGeoggraphicWaitlistMutationFn = Apollo.MutationFunction<JoinTheGeoggraphicWaitlistMutation, JoinTheGeoggraphicWaitlistMutationVariables>;

/**
 * __useJoinTheGeoggraphicWaitlistMutation__
 *
 * To run a mutation, you first call `useJoinTheGeoggraphicWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTheGeoggraphicWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTheGeoggraphicWaitlistMutation, { data, loading, error }] = useJoinTheGeoggraphicWaitlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinTheGeoggraphicWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<JoinTheGeoggraphicWaitlistMutation, JoinTheGeoggraphicWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinTheGeoggraphicWaitlistMutation, JoinTheGeoggraphicWaitlistMutationVariables>(JoinTheGeoggraphicWaitlistDocument, options);
      }
export type JoinTheGeoggraphicWaitlistMutationHookResult = ReturnType<typeof useJoinTheGeoggraphicWaitlistMutation>;
export type JoinTheGeoggraphicWaitlistMutationResult = Apollo.MutationResult<JoinTheGeoggraphicWaitlistMutation>;
export type JoinTheGeoggraphicWaitlistMutationOptions = Apollo.BaseMutationOptions<JoinTheGeoggraphicWaitlistMutation, JoinTheGeoggraphicWaitlistMutationVariables>;
export const CreateKlarnaUrlForBuyingYourBikeDocument = gql`
    mutation createKlarnaUrlForBuyingYourBike($redirectUrl: String!) {
  createKlarnaUrlForBuyingYourBike(redirectUrl: $redirectUrl) {
    ... on KlarnaObject {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type CreateKlarnaUrlForBuyingYourBikeMutationFn = Apollo.MutationFunction<CreateKlarnaUrlForBuyingYourBikeMutation, CreateKlarnaUrlForBuyingYourBikeMutationVariables>;

/**
 * __useCreateKlarnaUrlForBuyingYourBikeMutation__
 *
 * To run a mutation, you first call `useCreateKlarnaUrlForBuyingYourBikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKlarnaUrlForBuyingYourBikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKlarnaUrlForBuyingYourBikeMutation, { data, loading, error }] = useCreateKlarnaUrlForBuyingYourBikeMutation({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCreateKlarnaUrlForBuyingYourBikeMutation(baseOptions?: Apollo.MutationHookOptions<CreateKlarnaUrlForBuyingYourBikeMutation, CreateKlarnaUrlForBuyingYourBikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKlarnaUrlForBuyingYourBikeMutation, CreateKlarnaUrlForBuyingYourBikeMutationVariables>(CreateKlarnaUrlForBuyingYourBikeDocument, options);
      }
export type CreateKlarnaUrlForBuyingYourBikeMutationHookResult = ReturnType<typeof useCreateKlarnaUrlForBuyingYourBikeMutation>;
export type CreateKlarnaUrlForBuyingYourBikeMutationResult = Apollo.MutationResult<CreateKlarnaUrlForBuyingYourBikeMutation>;
export type CreateKlarnaUrlForBuyingYourBikeMutationOptions = Apollo.BaseMutationOptions<CreateKlarnaUrlForBuyingYourBikeMutation, CreateKlarnaUrlForBuyingYourBikeMutationVariables>;
export const CreateKlarnaUrlForCancellationDocument = gql`
    mutation createKlarnaUrlForCancellation($input: CancellationRequestInput!, $redirectUrl: String!) {
  createKlarnaUrlForCancellation(data: $input, redirectUrl: $redirectUrl) {
    ... on KlarnaObject {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;
export type CreateKlarnaUrlForCancellationMutationFn = Apollo.MutationFunction<CreateKlarnaUrlForCancellationMutation, CreateKlarnaUrlForCancellationMutationVariables>;

/**
 * __useCreateKlarnaUrlForCancellationMutation__
 *
 * To run a mutation, you first call `useCreateKlarnaUrlForCancellationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKlarnaUrlForCancellationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKlarnaUrlForCancellationMutation, { data, loading, error }] = useCreateKlarnaUrlForCancellationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCreateKlarnaUrlForCancellationMutation(baseOptions?: Apollo.MutationHookOptions<CreateKlarnaUrlForCancellationMutation, CreateKlarnaUrlForCancellationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKlarnaUrlForCancellationMutation, CreateKlarnaUrlForCancellationMutationVariables>(CreateKlarnaUrlForCancellationDocument, options);
      }
export type CreateKlarnaUrlForCancellationMutationHookResult = ReturnType<typeof useCreateKlarnaUrlForCancellationMutation>;
export type CreateKlarnaUrlForCancellationMutationResult = Apollo.MutationResult<CreateKlarnaUrlForCancellationMutation>;
export type CreateKlarnaUrlForCancellationMutationOptions = Apollo.BaseMutationOptions<CreateKlarnaUrlForCancellationMutation, CreateKlarnaUrlForCancellationMutationVariables>;
export const CheckUserDocument = gql`
    query checkUser($email: String!) {
  checkEmailHasAccount(email: $email)
}
    `;

/**
 * __useCheckUserQuery__
 *
 * To run a query within a React component, call `useCheckUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckUserQuery(baseOptions: Apollo.QueryHookOptions<CheckUserQuery, CheckUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckUserQuery, CheckUserQueryVariables>(CheckUserDocument, options);
      }
export function useCheckUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUserQuery, CheckUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckUserQuery, CheckUserQueryVariables>(CheckUserDocument, options);
        }
export type CheckUserQueryHookResult = ReturnType<typeof useCheckUserQuery>;
export type CheckUserLazyQueryHookResult = ReturnType<typeof useCheckUserLazyQuery>;
export type CheckUserQueryResult = Apollo.QueryResult<CheckUserQuery, CheckUserQueryVariables>;
export const HasSubscriptionDocument = gql`
    query hasSubscription {
  viewer {
    hasSubscription
  }
}
    `;

/**
 * __useHasSubscriptionQuery__
 *
 * To run a query within a React component, call `useHasSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<HasSubscriptionQuery, HasSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasSubscriptionQuery, HasSubscriptionQueryVariables>(HasSubscriptionDocument, options);
      }
export function useHasSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasSubscriptionQuery, HasSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasSubscriptionQuery, HasSubscriptionQueryVariables>(HasSubscriptionDocument, options);
        }
export type HasSubscriptionQueryHookResult = ReturnType<typeof useHasSubscriptionQuery>;
export type HasSubscriptionLazyQueryHookResult = ReturnType<typeof useHasSubscriptionLazyQuery>;
export type HasSubscriptionQueryResult = Apollo.QueryResult<HasSubscriptionQuery, HasSubscriptionQueryVariables>;
export const GetReferralCodeDocument = gql`
    query getReferralCode {
  viewer {
    subscription {
      generatedReferralCode
    }
  }
}
    `;

/**
 * __useGetReferralCodeQuery__
 *
 * To run a query within a React component, call `useGetReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferralCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferralCodeQuery(baseOptions?: Apollo.QueryHookOptions<GetReferralCodeQuery, GetReferralCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferralCodeQuery, GetReferralCodeQueryVariables>(GetReferralCodeDocument, options);
      }
export function useGetReferralCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferralCodeQuery, GetReferralCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferralCodeQuery, GetReferralCodeQueryVariables>(GetReferralCodeDocument, options);
        }
export type GetReferralCodeQueryHookResult = ReturnType<typeof useGetReferralCodeQuery>;
export type GetReferralCodeLazyQueryHookResult = ReturnType<typeof useGetReferralCodeLazyQuery>;
export type GetReferralCodeQueryResult = Apollo.QueryResult<GetReferralCodeQuery, GetReferralCodeQueryVariables>;
export const AllHdyhausDocument = gql`
    query allHdyhaus {
  hdyhauOptions
}
    `;

/**
 * __useAllHdyhausQuery__
 *
 * To run a query within a React component, call `useAllHdyhausQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllHdyhausQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllHdyhausQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllHdyhausQuery(baseOptions?: Apollo.QueryHookOptions<AllHdyhausQuery, AllHdyhausQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllHdyhausQuery, AllHdyhausQueryVariables>(AllHdyhausDocument, options);
      }
export function useAllHdyhausLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllHdyhausQuery, AllHdyhausQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllHdyhausQuery, AllHdyhausQueryVariables>(AllHdyhausDocument, options);
        }
export type AllHdyhausQueryHookResult = ReturnType<typeof useAllHdyhausQuery>;
export type AllHdyhausLazyQueryHookResult = ReturnType<typeof useAllHdyhausLazyQuery>;
export type AllHdyhausQueryResult = Apollo.QueryResult<AllHdyhausQuery, AllHdyhausQueryVariables>;
export const GetConfirmationViewerDocument = gql`
    query getConfirmationViewer {
  viewer {
    subscription {
      stripeSubscriptionId
      discountCode
      packageSlug
      requestedDeliveryDate
      generatedReferralCode
    }
    riderId
    isRejoiner
  }
}
    `;

/**
 * __useGetConfirmationViewerQuery__
 *
 * To run a query within a React component, call `useGetConfirmationViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfirmationViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfirmationViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfirmationViewerQuery(baseOptions?: Apollo.QueryHookOptions<GetConfirmationViewerQuery, GetConfirmationViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfirmationViewerQuery, GetConfirmationViewerQueryVariables>(GetConfirmationViewerDocument, options);
      }
export function useGetConfirmationViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfirmationViewerQuery, GetConfirmationViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfirmationViewerQuery, GetConfirmationViewerQueryVariables>(GetConfirmationViewerDocument, options);
        }
export type GetConfirmationViewerQueryHookResult = ReturnType<typeof useGetConfirmationViewerQuery>;
export type GetConfirmationViewerLazyQueryHookResult = ReturnType<typeof useGetConfirmationViewerLazyQuery>;
export type GetConfirmationViewerQueryResult = Apollo.QueryResult<GetConfirmationViewerQuery, GetConfirmationViewerQueryVariables>;
export const CitiesDocument = gql`
    query cities {
  cities {
    ...city
  }
}
    ${CityFragmentDoc}`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const BikesDocument = gql`
    query bikes($cityId: Int) {
  bikes(cityId: $cityId) {
    bikes {
      ...bikeType
    }
    city {
      ...city
    }
    id
  }
}
    ${BikeTypeFragmentDoc}
${CityFragmentDoc}`;

/**
 * __useBikesQuery__
 *
 * To run a query within a React component, call `useBikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBikesQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useBikesQuery(baseOptions?: Apollo.QueryHookOptions<BikesQuery, BikesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BikesQuery, BikesQueryVariables>(BikesDocument, options);
      }
export function useBikesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BikesQuery, BikesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BikesQuery, BikesQueryVariables>(BikesDocument, options);
        }
export type BikesQueryHookResult = ReturnType<typeof useBikesQuery>;
export type BikesLazyQueryHookResult = ReturnType<typeof useBikesLazyQuery>;
export type BikesQueryResult = Apollo.QueryResult<BikesQuery, BikesQueryVariables>;
export const PreviewBuyingYourBikeDocument = gql`
    query previewBuyingYourBike($priceInPence: Int) {
  previewBuyingYourBike(priceInPence: $priceInPence) {
    ... on BuyingBikeInvoiceSummary {
      buyingBikePrice
      paymentMethod {
        ...paymentMethod
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${PaymentMethodFragmentDoc}`;

/**
 * __usePreviewBuyingYourBikeQuery__
 *
 * To run a query within a React component, call `usePreviewBuyingYourBikeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewBuyingYourBikeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewBuyingYourBikeQuery({
 *   variables: {
 *      priceInPence: // value for 'priceInPence'
 *   },
 * });
 */
export function usePreviewBuyingYourBikeQuery(baseOptions?: Apollo.QueryHookOptions<PreviewBuyingYourBikeQuery, PreviewBuyingYourBikeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewBuyingYourBikeQuery, PreviewBuyingYourBikeQueryVariables>(PreviewBuyingYourBikeDocument, options);
      }
export function usePreviewBuyingYourBikeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewBuyingYourBikeQuery, PreviewBuyingYourBikeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewBuyingYourBikeQuery, PreviewBuyingYourBikeQueryVariables>(PreviewBuyingYourBikeDocument, options);
        }
export type PreviewBuyingYourBikeQueryHookResult = ReturnType<typeof usePreviewBuyingYourBikeQuery>;
export type PreviewBuyingYourBikeLazyQueryHookResult = ReturnType<typeof usePreviewBuyingYourBikeLazyQuery>;
export type PreviewBuyingYourBikeQueryResult = Apollo.QueryResult<PreviewBuyingYourBikeQuery, PreviewBuyingYourBikeQueryVariables>;
export const RescheduleDeliveryOrderDocument = gql`
    query rescheduleDeliveryOrder($input: OrderReschedulingInput!) {
  rescheduleDeliveryOrder(input: $input) {
    ... on DeliveryOrderReschedulingInvoiceSummary {
      deliveryCharge
      paymentMethod {
        ...paymentMethod
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${PaymentMethodFragmentDoc}`;

/**
 * __useRescheduleDeliveryOrderQuery__
 *
 * To run a query within a React component, call `useRescheduleDeliveryOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useRescheduleDeliveryOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRescheduleDeliveryOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleDeliveryOrderQuery(baseOptions: Apollo.QueryHookOptions<RescheduleDeliveryOrderQuery, RescheduleDeliveryOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RescheduleDeliveryOrderQuery, RescheduleDeliveryOrderQueryVariables>(RescheduleDeliveryOrderDocument, options);
      }
export function useRescheduleDeliveryOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RescheduleDeliveryOrderQuery, RescheduleDeliveryOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RescheduleDeliveryOrderQuery, RescheduleDeliveryOrderQueryVariables>(RescheduleDeliveryOrderDocument, options);
        }
export type RescheduleDeliveryOrderQueryHookResult = ReturnType<typeof useRescheduleDeliveryOrderQuery>;
export type RescheduleDeliveryOrderLazyQueryHookResult = ReturnType<typeof useRescheduleDeliveryOrderLazyQuery>;
export type RescheduleDeliveryOrderQueryResult = Apollo.QueryResult<RescheduleDeliveryOrderQuery, RescheduleDeliveryOrderQueryVariables>;
export const GetIssueReportsDocument = gql`
    query getIssueReports {
  getIssueReports {
    ... on IssueReportList {
      issueReports {
        ...issueReportData
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${IssueReportDataFragmentDoc}`;

/**
 * __useGetIssueReportsQuery__
 *
 * To run a query within a React component, call `useGetIssueReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIssueReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetIssueReportsQuery, GetIssueReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssueReportsQuery, GetIssueReportsQueryVariables>(GetIssueReportsDocument, options);
      }
export function useGetIssueReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssueReportsQuery, GetIssueReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssueReportsQuery, GetIssueReportsQueryVariables>(GetIssueReportsDocument, options);
        }
export type GetIssueReportsQueryHookResult = ReturnType<typeof useGetIssueReportsQuery>;
export type GetIssueReportsLazyQueryHookResult = ReturnType<typeof useGetIssueReportsLazyQuery>;
export type GetIssueReportsQueryResult = Apollo.QueryResult<GetIssueReportsQuery, GetIssueReportsQueryVariables>;
export const AvailableBikeProfDatesDocument = gql`
    query availableBikeProfDates($postcode: String) {
  availableBikeProfDates(postcode: $postcode) {
    ... on SlotPairList {
      availableSlots {
        startTime
        endTime
      }
    }
    ... on APIError {
      message
    }
  }
}
    `;

/**
 * __useAvailableBikeProfDatesQuery__
 *
 * To run a query within a React component, call `useAvailableBikeProfDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableBikeProfDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableBikeProfDatesQuery({
 *   variables: {
 *      postcode: // value for 'postcode'
 *   },
 * });
 */
export function useAvailableBikeProfDatesQuery(baseOptions?: Apollo.QueryHookOptions<AvailableBikeProfDatesQuery, AvailableBikeProfDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableBikeProfDatesQuery, AvailableBikeProfDatesQueryVariables>(AvailableBikeProfDatesDocument, options);
      }
export function useAvailableBikeProfDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableBikeProfDatesQuery, AvailableBikeProfDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableBikeProfDatesQuery, AvailableBikeProfDatesQueryVariables>(AvailableBikeProfDatesDocument, options);
        }
export type AvailableBikeProfDatesQueryHookResult = ReturnType<typeof useAvailableBikeProfDatesQuery>;
export type AvailableBikeProfDatesLazyQueryHookResult = ReturnType<typeof useAvailableBikeProfDatesLazyQuery>;
export type AvailableBikeProfDatesQueryResult = Apollo.QueryResult<AvailableBikeProfDatesQuery, AvailableBikeProfDatesQueryVariables>;
export const RescheduleCollectionOrderDocument = gql`
    query rescheduleCollectionOrder($input: OrderReschedulingInput!) {
  rescheduleCollectionOrder(input: $input) {
    ... on CollectionOrderReschedulingInvoiceSummary {
      collectionCharge
      prorataPayment
      paymentMethod {
        ...paymentMethod
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${PaymentMethodFragmentDoc}`;

/**
 * __useRescheduleCollectionOrderQuery__
 *
 * To run a query within a React component, call `useRescheduleCollectionOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useRescheduleCollectionOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRescheduleCollectionOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleCollectionOrderQuery(baseOptions: Apollo.QueryHookOptions<RescheduleCollectionOrderQuery, RescheduleCollectionOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RescheduleCollectionOrderQuery, RescheduleCollectionOrderQueryVariables>(RescheduleCollectionOrderDocument, options);
      }
export function useRescheduleCollectionOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RescheduleCollectionOrderQuery, RescheduleCollectionOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RescheduleCollectionOrderQuery, RescheduleCollectionOrderQueryVariables>(RescheduleCollectionOrderDocument, options);
        }
export type RescheduleCollectionOrderQueryHookResult = ReturnType<typeof useRescheduleCollectionOrderQuery>;
export type RescheduleCollectionOrderLazyQueryHookResult = ReturnType<typeof useRescheduleCollectionOrderLazyQuery>;
export type RescheduleCollectionOrderQueryResult = Apollo.QueryResult<RescheduleCollectionOrderQuery, RescheduleCollectionOrderQueryVariables>;
export const ValidateRedemptionCodeDocument = gql`
    query validateRedemptionCode($redemptionCode: String!, $isCyclescheme: Boolean! = false) {
  validateRedemptionCode(
    redemptionCode: $redemptionCode
    isCyclescheme: $isCyclescheme
  ) {
    ... on RedemptionCodeInfo {
      ...redemptionCodeInfo
    }
    ... on APIError {
      message
    }
  }
}
    ${RedemptionCodeInfoFragmentDoc}`;

/**
 * __useValidateRedemptionCodeQuery__
 *
 * To run a query within a React component, call `useValidateRedemptionCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateRedemptionCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateRedemptionCodeQuery({
 *   variables: {
 *      redemptionCode: // value for 'redemptionCode'
 *      isCyclescheme: // value for 'isCyclescheme'
 *   },
 * });
 */
export function useValidateRedemptionCodeQuery(baseOptions: Apollo.QueryHookOptions<ValidateRedemptionCodeQuery, ValidateRedemptionCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateRedemptionCodeQuery, ValidateRedemptionCodeQueryVariables>(ValidateRedemptionCodeDocument, options);
      }
export function useValidateRedemptionCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateRedemptionCodeQuery, ValidateRedemptionCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateRedemptionCodeQuery, ValidateRedemptionCodeQueryVariables>(ValidateRedemptionCodeDocument, options);
        }
export type ValidateRedemptionCodeQueryHookResult = ReturnType<typeof useValidateRedemptionCodeQuery>;
export type ValidateRedemptionCodeLazyQueryHookResult = ReturnType<typeof useValidateRedemptionCodeLazyQuery>;
export type ValidateRedemptionCodeQueryResult = Apollo.QueryResult<ValidateRedemptionCodeQuery, ValidateRedemptionCodeQueryVariables>;
export const GetDefaultPaymentMethodDocument = gql`
    query getDefaultPaymentMethod {
  getDefaultPaymentMethod {
    ... on PaymentMethod {
      ...paymentMethod
    }
  }
}
    ${PaymentMethodFragmentDoc}`;

/**
 * __useGetDefaultPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetDefaultPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultPaymentMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultPaymentMethodQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultPaymentMethodQuery, GetDefaultPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultPaymentMethodQuery, GetDefaultPaymentMethodQueryVariables>(GetDefaultPaymentMethodDocument, options);
      }
export function useGetDefaultPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultPaymentMethodQuery, GetDefaultPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultPaymentMethodQuery, GetDefaultPaymentMethodQueryVariables>(GetDefaultPaymentMethodDocument, options);
        }
export type GetDefaultPaymentMethodQueryHookResult = ReturnType<typeof useGetDefaultPaymentMethodQuery>;
export type GetDefaultPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetDefaultPaymentMethodLazyQuery>;
export type GetDefaultPaymentMethodQueryResult = Apollo.QueryResult<GetDefaultPaymentMethodQuery, GetDefaultPaymentMethodQueryVariables>;
export const GetBuzzbikeRetailsDocument = gql`
    query getBuzzbikeRetails($cityId: Int!) {
  getBuzzbikeRetails(cityId: $cityId) {
    addressLineOne
    addressLineTwo
    city
    id
    postcode
    retailName
    retailPhoneNumber
  }
}
    `;

/**
 * __useGetBuzzbikeRetailsQuery__
 *
 * To run a query within a React component, call `useGetBuzzbikeRetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuzzbikeRetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuzzbikeRetailsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useGetBuzzbikeRetailsQuery(baseOptions: Apollo.QueryHookOptions<GetBuzzbikeRetailsQuery, GetBuzzbikeRetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuzzbikeRetailsQuery, GetBuzzbikeRetailsQueryVariables>(GetBuzzbikeRetailsDocument, options);
      }
export function useGetBuzzbikeRetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuzzbikeRetailsQuery, GetBuzzbikeRetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuzzbikeRetailsQuery, GetBuzzbikeRetailsQueryVariables>(GetBuzzbikeRetailsDocument, options);
        }
export type GetBuzzbikeRetailsQueryHookResult = ReturnType<typeof useGetBuzzbikeRetailsQuery>;
export type GetBuzzbikeRetailsLazyQueryHookResult = ReturnType<typeof useGetBuzzbikeRetailsLazyQuery>;
export type GetBuzzbikeRetailsQueryResult = Apollo.QueryResult<GetBuzzbikeRetailsQuery, GetBuzzbikeRetailsQueryVariables>;
export const GetChangePaymentDateDocument = gql`
    query getChangePaymentDate($requestedDay: Int!) {
  changePaymentDate(requestedDay: $requestedDay) {
    ... on ChangePaymentDateProrata {
      chargeAmount
      chargeFromDate
      chargeToDate
      theftFeeAddOnChargeAmount
    }
    ... on APIError {
      message
    }
  }
}
    `;

/**
 * __useGetChangePaymentDateQuery__
 *
 * To run a query within a React component, call `useGetChangePaymentDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangePaymentDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangePaymentDateQuery({
 *   variables: {
 *      requestedDay: // value for 'requestedDay'
 *   },
 * });
 */
export function useGetChangePaymentDateQuery(baseOptions: Apollo.QueryHookOptions<GetChangePaymentDateQuery, GetChangePaymentDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChangePaymentDateQuery, GetChangePaymentDateQueryVariables>(GetChangePaymentDateDocument, options);
      }
export function useGetChangePaymentDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChangePaymentDateQuery, GetChangePaymentDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChangePaymentDateQuery, GetChangePaymentDateQueryVariables>(GetChangePaymentDateDocument, options);
        }
export type GetChangePaymentDateQueryHookResult = ReturnType<typeof useGetChangePaymentDateQuery>;
export type GetChangePaymentDateLazyQueryHookResult = ReturnType<typeof useGetChangePaymentDateLazyQuery>;
export type GetChangePaymentDateQueryResult = Apollo.QueryResult<GetChangePaymentDateQuery, GetChangePaymentDateQueryVariables>;
export const OutstandingInvoicesDocument = gql`
    query outstandingInvoices {
  outstandingInvoices {
    ...summarisedInvoice
  }
}
    ${SummarisedInvoiceFragmentDoc}`;

/**
 * __useOutstandingInvoicesQuery__
 *
 * To run a query within a React component, call `useOutstandingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutstandingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutstandingInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOutstandingInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<OutstandingInvoicesQuery, OutstandingInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutstandingInvoicesQuery, OutstandingInvoicesQueryVariables>(OutstandingInvoicesDocument, options);
      }
export function useOutstandingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutstandingInvoicesQuery, OutstandingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutstandingInvoicesQuery, OutstandingInvoicesQueryVariables>(OutstandingInvoicesDocument, options);
        }
export type OutstandingInvoicesQueryHookResult = ReturnType<typeof useOutstandingInvoicesQuery>;
export type OutstandingInvoicesLazyQueryHookResult = ReturnType<typeof useOutstandingInvoicesLazyQuery>;
export type OutstandingInvoicesQueryResult = Apollo.QueryResult<OutstandingInvoicesQuery, OutstandingInvoicesQueryVariables>;
export const UpcomingInvoicesDocument = gql`
    query upcomingInvoices {
  upcomingInvoice {
    ...upcomingInvoice
  }
}
    ${UpcomingInvoiceFragmentDoc}`;

/**
 * __useUpcomingInvoicesQuery__
 *
 * To run a query within a React component, call `useUpcomingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<UpcomingInvoicesQuery, UpcomingInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingInvoicesQuery, UpcomingInvoicesQueryVariables>(UpcomingInvoicesDocument, options);
      }
export function useUpcomingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingInvoicesQuery, UpcomingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingInvoicesQuery, UpcomingInvoicesQueryVariables>(UpcomingInvoicesDocument, options);
        }
export type UpcomingInvoicesQueryHookResult = ReturnType<typeof useUpcomingInvoicesQuery>;
export type UpcomingInvoicesLazyQueryHookResult = ReturnType<typeof useUpcomingInvoicesLazyQuery>;
export type UpcomingInvoicesQueryResult = Apollo.QueryResult<UpcomingInvoicesQuery, UpcomingInvoicesQueryVariables>;
export const GetTheftFeeAddOnPriceDocument = gql`
    query getTheftFeeAddOnPrice($bikeId: Int) {
  getTheftFeeAddOnPrice(bikeId: $bikeId) {
    ... on TheftFeeAddOnPriceType {
      applyFrom
      theftFeeAddOnPriceInPence
    }
    ... on APIError {
      message
    }
  }
}
    `;

/**
 * __useGetTheftFeeAddOnPriceQuery__
 *
 * To run a query within a React component, call `useGetTheftFeeAddOnPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTheftFeeAddOnPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTheftFeeAddOnPriceQuery({
 *   variables: {
 *      bikeId: // value for 'bikeId'
 *   },
 * });
 */
export function useGetTheftFeeAddOnPriceQuery(baseOptions?: Apollo.QueryHookOptions<GetTheftFeeAddOnPriceQuery, GetTheftFeeAddOnPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTheftFeeAddOnPriceQuery, GetTheftFeeAddOnPriceQueryVariables>(GetTheftFeeAddOnPriceDocument, options);
      }
export function useGetTheftFeeAddOnPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTheftFeeAddOnPriceQuery, GetTheftFeeAddOnPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTheftFeeAddOnPriceQuery, GetTheftFeeAddOnPriceQueryVariables>(GetTheftFeeAddOnPriceDocument, options);
        }
export type GetTheftFeeAddOnPriceQueryHookResult = ReturnType<typeof useGetTheftFeeAddOnPriceQuery>;
export type GetTheftFeeAddOnPriceLazyQueryHookResult = ReturnType<typeof useGetTheftFeeAddOnPriceLazyQuery>;
export type GetTheftFeeAddOnPriceQueryResult = Apollo.QueryResult<GetTheftFeeAddOnPriceQuery, GetTheftFeeAddOnPriceQueryVariables>;
export const GetSubscriptionTheftFeeAddOnDocument = gql`
    query getSubscriptionTheftFeeAddOn {
  getSubscriptionTheftFeeAddOn {
    ... on SubscriptionTheftFeeAddOnType {
      ...subscriptionTheftFeeAddOnType
    }
    ... on APIError {
      message
    }
  }
}
    ${SubscriptionTheftFeeAddOnTypeFragmentDoc}`;

/**
 * __useGetSubscriptionTheftFeeAddOnQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionTheftFeeAddOnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionTheftFeeAddOnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionTheftFeeAddOnQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionTheftFeeAddOnQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionTheftFeeAddOnQuery, GetSubscriptionTheftFeeAddOnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionTheftFeeAddOnQuery, GetSubscriptionTheftFeeAddOnQueryVariables>(GetSubscriptionTheftFeeAddOnDocument, options);
      }
export function useGetSubscriptionTheftFeeAddOnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionTheftFeeAddOnQuery, GetSubscriptionTheftFeeAddOnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionTheftFeeAddOnQuery, GetSubscriptionTheftFeeAddOnQueryVariables>(GetSubscriptionTheftFeeAddOnDocument, options);
        }
export type GetSubscriptionTheftFeeAddOnQueryHookResult = ReturnType<typeof useGetSubscriptionTheftFeeAddOnQuery>;
export type GetSubscriptionTheftFeeAddOnLazyQueryHookResult = ReturnType<typeof useGetSubscriptionTheftFeeAddOnLazyQuery>;
export type GetSubscriptionTheftFeeAddOnQueryResult = Apollo.QueryResult<GetSubscriptionTheftFeeAddOnQuery, GetSubscriptionTheftFeeAddOnQueryVariables>;
export const GetBuyingYourBuzzbikeInvoiceUrlDocument = gql`
    query getBuyingYourBuzzbikeInvoiceUrl {
  getBuyingYourBuzzbikeInvoiceUrl {
    ... on InvoicePdf {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;

/**
 * __useGetBuyingYourBuzzbikeInvoiceUrlQuery__
 *
 * To run a query within a React component, call `useGetBuyingYourBuzzbikeInvoiceUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyingYourBuzzbikeInvoiceUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyingYourBuzzbikeInvoiceUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBuyingYourBuzzbikeInvoiceUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetBuyingYourBuzzbikeInvoiceUrlQuery, GetBuyingYourBuzzbikeInvoiceUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuyingYourBuzzbikeInvoiceUrlQuery, GetBuyingYourBuzzbikeInvoiceUrlQueryVariables>(GetBuyingYourBuzzbikeInvoiceUrlDocument, options);
      }
export function useGetBuyingYourBuzzbikeInvoiceUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuyingYourBuzzbikeInvoiceUrlQuery, GetBuyingYourBuzzbikeInvoiceUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuyingYourBuzzbikeInvoiceUrlQuery, GetBuyingYourBuzzbikeInvoiceUrlQueryVariables>(GetBuyingYourBuzzbikeInvoiceUrlDocument, options);
        }
export type GetBuyingYourBuzzbikeInvoiceUrlQueryHookResult = ReturnType<typeof useGetBuyingYourBuzzbikeInvoiceUrlQuery>;
export type GetBuyingYourBuzzbikeInvoiceUrlLazyQueryHookResult = ReturnType<typeof useGetBuyingYourBuzzbikeInvoiceUrlLazyQuery>;
export type GetBuyingYourBuzzbikeInvoiceUrlQueryResult = Apollo.QueryResult<GetBuyingYourBuzzbikeInvoiceUrlQuery, GetBuyingYourBuzzbikeInvoiceUrlQueryVariables>;
export const GetRenewCycleSchemePlanQuoteDocument = gql`
    query getRenewCycleSchemePlanQuote($redemptionCode: String!) {
  getRenewCycleSchemePlanQuote(redemptionCode: $redemptionCode) {
    ... on RenewCycleSchemePlanQuote {
      currentBikeType {
        ...bikeType
      }
      currentMonthlyPayment
      currentPackageId
      currentPackageSlug
      currentPlanEndDate
      currentPlanStartDate
      currentSwitchRolling {
        ...switchPackage
      }
      newBikeType {
        ...bikeType
      }
      newMonthlyPayment
      newPackageId
      newPackageSlug
      newPlanEndDate
      newPlanStartDate
      renewType
      newSwitchRolling {
        ...switchPackage
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${BikeTypeFragmentDoc}
${SwitchPackageFragmentDoc}`;

/**
 * __useGetRenewCycleSchemePlanQuoteQuery__
 *
 * To run a query within a React component, call `useGetRenewCycleSchemePlanQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenewCycleSchemePlanQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenewCycleSchemePlanQuoteQuery({
 *   variables: {
 *      redemptionCode: // value for 'redemptionCode'
 *   },
 * });
 */
export function useGetRenewCycleSchemePlanQuoteQuery(baseOptions: Apollo.QueryHookOptions<GetRenewCycleSchemePlanQuoteQuery, GetRenewCycleSchemePlanQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRenewCycleSchemePlanQuoteQuery, GetRenewCycleSchemePlanQuoteQueryVariables>(GetRenewCycleSchemePlanQuoteDocument, options);
      }
export function useGetRenewCycleSchemePlanQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRenewCycleSchemePlanQuoteQuery, GetRenewCycleSchemePlanQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRenewCycleSchemePlanQuoteQuery, GetRenewCycleSchemePlanQuoteQueryVariables>(GetRenewCycleSchemePlanQuoteDocument, options);
        }
export type GetRenewCycleSchemePlanQuoteQueryHookResult = ReturnType<typeof useGetRenewCycleSchemePlanQuoteQuery>;
export type GetRenewCycleSchemePlanQuoteLazyQueryHookResult = ReturnType<typeof useGetRenewCycleSchemePlanQuoteLazyQuery>;
export type GetRenewCycleSchemePlanQuoteQueryResult = Apollo.QueryResult<GetRenewCycleSchemePlanQuoteQuery, GetRenewCycleSchemePlanQuoteQueryVariables>;
export const GetNoMaintenancePackagesDocument = gql`
    query getNoMaintenancePackages {
  getNoMaintenancePackages {
    packageSlug
    ...package
  }
}
    ${PackageFragmentDoc}`;

/**
 * __useGetNoMaintenancePackagesQuery__
 *
 * To run a query within a React component, call `useGetNoMaintenancePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoMaintenancePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoMaintenancePackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNoMaintenancePackagesQuery(baseOptions?: Apollo.QueryHookOptions<GetNoMaintenancePackagesQuery, GetNoMaintenancePackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNoMaintenancePackagesQuery, GetNoMaintenancePackagesQueryVariables>(GetNoMaintenancePackagesDocument, options);
      }
export function useGetNoMaintenancePackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNoMaintenancePackagesQuery, GetNoMaintenancePackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNoMaintenancePackagesQuery, GetNoMaintenancePackagesQueryVariables>(GetNoMaintenancePackagesDocument, options);
        }
export type GetNoMaintenancePackagesQueryHookResult = ReturnType<typeof useGetNoMaintenancePackagesQuery>;
export type GetNoMaintenancePackagesLazyQueryHookResult = ReturnType<typeof useGetNoMaintenancePackagesLazyQuery>;
export type GetNoMaintenancePackagesQueryResult = Apollo.QueryResult<GetNoMaintenancePackagesQuery, GetNoMaintenancePackagesQueryVariables>;
export const AllUsersDocument = gql`
    query allUsers {
  viewer {
    email
    fullName
    riderId
  }
}
    `;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const UserProfileDocument = gql`
    query userProfile {
  viewer {
    firstName
    lastName
    fullName
    email
    birthDate
    title
    userId
    companyName
    isBtw
    isRejoiner
    rejoinedTimes
    gender
    cyclingConfidence
    nationalInsurance
    phoneNumberMobile
    promotionCode
    heightCm
    hasSubscription
    wantsMarketingEmails
    requestUrl
    cancellationRequestUrl
    homeAddress {
      addressLineOne
      addressLineTwo
      city
      postcode
    }
    subscription {
      ...subscription
    }
    verification {
      ...verification
    }
  }
}
    ${SubscriptionFragmentDoc}
${VerificationFragmentDoc}`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const UserSubscriptionDocument = gql`
    query userSubscription {
  viewer {
    requestUrl
    cancellationRequestUrl
    subscription {
      ...subscription
    }
  }
}
    ${SubscriptionFragmentDoc}`;

/**
 * __useUserSubscriptionQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(UserSubscriptionDocument, options);
      }
export function useUserSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(UserSubscriptionDocument, options);
        }
export type UserSubscriptionQueryHookResult = ReturnType<typeof useUserSubscriptionQuery>;
export type UserSubscriptionLazyQueryHookResult = ReturnType<typeof useUserSubscriptionLazyQuery>;
export type UserSubscriptionQueryResult = Apollo.QueryResult<UserSubscriptionQuery, UserSubscriptionQueryVariables>;
export const GetSubscribedPackagesDocument = gql`
    query getSubscribedPackages {
  getSubscribedPackages {
    ... on SubscribedPackages {
      currentPackage {
        ...subscribedPackage
      }
      nextPackage {
        ...subscribedPackage
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${SubscribedPackageFragmentDoc}`;

/**
 * __useGetSubscribedPackagesQuery__
 *
 * To run a query within a React component, call `useGetSubscribedPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscribedPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscribedPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscribedPackagesQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscribedPackagesQuery, GetSubscribedPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscribedPackagesQuery, GetSubscribedPackagesQueryVariables>(GetSubscribedPackagesDocument, options);
      }
export function useGetSubscribedPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscribedPackagesQuery, GetSubscribedPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscribedPackagesQuery, GetSubscribedPackagesQueryVariables>(GetSubscribedPackagesDocument, options);
        }
export type GetSubscribedPackagesQueryHookResult = ReturnType<typeof useGetSubscribedPackagesQuery>;
export type GetSubscribedPackagesLazyQueryHookResult = ReturnType<typeof useGetSubscribedPackagesLazyQuery>;
export type GetSubscribedPackagesQueryResult = Apollo.QueryResult<GetSubscribedPackagesQuery, GetSubscribedPackagesQueryVariables>;
export const GetRecommendedPackagesDocument = gql`
    query getRecommendedPackages($bikeId: Int, $packageGroupName: String, $freeMonthsPromo: Boolean) {
  getRecommendedPackages(
    bikeId: $bikeId
    packageGroupName: $packageGroupName
    freeMonthsPromo: $freeMonthsPromo
  ) {
    basePrice
    packageSlug
    savingAmount
    paidMonthly
    stickerPrice
    termLengthMonths
    couponToApplyAtEndOfMinimumTerm {
      ...stripeCoupon
    }
  }
}
    ${StripeCouponFragmentDoc}`;

/**
 * __useGetRecommendedPackagesQuery__
 *
 * To run a query within a React component, call `useGetRecommendedPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedPackagesQuery({
 *   variables: {
 *      bikeId: // value for 'bikeId'
 *      packageGroupName: // value for 'packageGroupName'
 *      freeMonthsPromo: // value for 'freeMonthsPromo'
 *   },
 * });
 */
export function useGetRecommendedPackagesQuery(baseOptions?: Apollo.QueryHookOptions<GetRecommendedPackagesQuery, GetRecommendedPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedPackagesQuery, GetRecommendedPackagesQueryVariables>(GetRecommendedPackagesDocument, options);
      }
export function useGetRecommendedPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedPackagesQuery, GetRecommendedPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedPackagesQuery, GetRecommendedPackagesQueryVariables>(GetRecommendedPackagesDocument, options);
        }
export type GetRecommendedPackagesQueryHookResult = ReturnType<typeof useGetRecommendedPackagesQuery>;
export type GetRecommendedPackagesLazyQueryHookResult = ReturnType<typeof useGetRecommendedPackagesLazyQuery>;
export type GetRecommendedPackagesQueryResult = Apollo.QueryResult<GetRecommendedPackagesQuery, GetRecommendedPackagesQueryVariables>;
export const PaymentDetailsDocument = gql`
    query paymentDetails {
  paymentDetails {
    last4
    expMonth
    expYear
    cardholderName
  }
}
    `;

/**
 * __usePaymentDetailsQuery__
 *
 * To run a query within a React component, call `usePaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentDetailsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentDetailsQuery, PaymentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentDetailsQuery, PaymentDetailsQueryVariables>(PaymentDetailsDocument, options);
      }
export function usePaymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentDetailsQuery, PaymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentDetailsQuery, PaymentDetailsQueryVariables>(PaymentDetailsDocument, options);
        }
export type PaymentDetailsQueryHookResult = ReturnType<typeof usePaymentDetailsQuery>;
export type PaymentDetailsLazyQueryHookResult = ReturnType<typeof usePaymentDetailsLazyQuery>;
export type PaymentDetailsQueryResult = Apollo.QueryResult<PaymentDetailsQuery, PaymentDetailsQueryVariables>;
/* Named operations */
export const namedOperations = {
  Query: {
    deliveryAvailability: 'deliveryAvailability',
    collectionAvailability: 'collectionAvailability',
    swapAvailability: 'swapAvailability',
    subscriptionCreationState: 'subscriptionCreationState',
    getBikeAvailability: 'getBikeAvailability',
    getPackages: 'getPackages',
    getPricingPackage: 'getPricingPackage',
    getKitAvailability: 'getKitAvailability',
    getSubscriptionQuote: 'getSubscriptionQuote',
    getChangingPlanQuote: 'getChangingPlanQuote',
    getTheftFeeAddOnPriceAtSingup: 'getTheftFeeAddOnPriceAtSingup',
    checkUser: 'checkUser',
    hasSubscription: 'hasSubscription',
    getReferralCode: 'getReferralCode',
    allHdyhaus: 'allHdyhaus',
    getConfirmationViewer: 'getConfirmationViewer',
    cities: 'cities',
    bikes: 'bikes',
    previewBuyingYourBike: 'previewBuyingYourBike',
    rescheduleDeliveryOrder: 'rescheduleDeliveryOrder',
    getIssueReports: 'getIssueReports',
    availableBikeProfDates: 'availableBikeProfDates',
    rescheduleCollectionOrder: 'rescheduleCollectionOrder',
    validateRedemptionCode: 'validateRedemptionCode',
    getDefaultPaymentMethod: 'getDefaultPaymentMethod',
    getBuzzbikeRetails: 'getBuzzbikeRetails',
    getChangePaymentDate: 'getChangePaymentDate',
    outstandingInvoices: 'outstandingInvoices',
    upcomingInvoices: 'upcomingInvoices',
    getTheftFeeAddOnPrice: 'getTheftFeeAddOnPrice',
    getSubscriptionTheftFeeAddOn: 'getSubscriptionTheftFeeAddOn',
    getBuyingYourBuzzbikeInvoiceUrl: 'getBuyingYourBuzzbikeInvoiceUrl',
    getRenewCycleSchemePlanQuote: 'getRenewCycleSchemePlanQuote',
    getNoMaintenancePackages: 'getNoMaintenancePackages',
    allUsers: 'allUsers',
    userProfile: 'userProfile',
    userSubscription: 'userSubscription',
    getSubscribedPackages: 'getSubscribedPackages',
    getRecommendedPackages: 'getRecommendedPackages',
    paymentDetails: 'paymentDetails'
  },
  Mutation: {
    startSetupStripePayment: 'startSetupStripePayment',
    checkoutSubscription: 'checkoutSubscription',
    createAccount: 'createAccount',
    updateAccount: 'updateAccount',
    changePassword: 'changePassword',
    resetPassword: 'resetPassword',
    redeemResetPasswordToken: 'redeemResetPasswordToken',
    applyPromoCode: 'applyPromoCode',
    startEditStripePayment: 'startEditStripePayment',
    finishEditStripePayment: 'finishEditStripePayment',
    updateMarketingPreferences: 'updateMarketingPreferences',
    createRejoiner: 'createRejoiner',
    changePlan: 'changePlan',
    updateKitOrder: 'updateKitOrder',
    updateRider: 'updateRider',
    applyRetentionDiscount: 'applyRetentionDiscount',
    pauseCollectionPayment: 'pauseCollectionPayment',
    joinTheWaitlist: 'joinTheWaitlist',
    deleteAccount: 'deleteAccount',
    cancelChangingPlan: 'cancelChangingPlan',
    buyYourBike: 'buyYourBike',
    reportAnIssue: 'reportAnIssue',
    rescheduleAnIssue: 'rescheduleAnIssue',
    rescheduleCollection: 'rescheduleCollection',
    revertCancellation: 'revertCancellation',
    claimLoyaltyReward: 'claimLoyaltyReward',
    changePaymentDate: 'changePaymentDate',
    createKlarnaUrl: 'createKlarnaUrl',
    updateBillingAddress: 'updateBillingAddress',
    subscribeTheftFeeAddOn: 'subscribeTheftFeeAddOn',
    cancelTheftFeeAddOn: 'cancelTheftFeeAddOn',
    joinTheGeoggraphicWaitlist: 'joinTheGeoggraphicWaitlist',
    createKlarnaUrlForBuyingYourBike: 'createKlarnaUrlForBuyingYourBike',
    createKlarnaUrlForCancellation: 'createKlarnaUrlForCancellation'
  },
  Fragment: {
    city: 'city',
    bikeType: 'bikeType',
    billingDetails: 'billingDetails',
    upcomingInvoice: 'upcomingInvoice',
    summarisedInvoice: 'summarisedInvoice',
    paymentMethod: 'paymentMethod',
    redemptionCodeInfo: 'redemptionCodeInfo',
    viewer: 'viewer',
    stripeUpcomingInvoice: 'stripeUpcomingInvoice',
    stripeCoupon: 'stripeCoupon',
    appliedDiscountCode: 'appliedDiscountCode',
    package: 'package',
    issueReportData: 'issueReportData',
    subscribedPackage: 'subscribedPackage',
    subscription: 'subscription',
    verification: 'verification',
    subscriptionTheftFeeAddOnType: 'subscriptionTheftFeeAddOnType',
    switchPackage: 'switchPackage'
  }
}
/* Apollo NextJS SSR */
export const DeliveryAvailabilitySsr = gql`
    query deliveryAvailability($bikeId: Int, $cityId: Int, $buzzbikeRetailId: Int, $postcode: String) {
  availableDeliveryDates(
    bikeId: $bikeId
    cityId: $cityId
    buzzbikeRetailId: $buzzbikeRetailId
    postcode: $postcode
  )
}
    `;
export async function getServerPageDeliveryAvailability
    (options: Omit<Apollo.QueryOptions<DeliveryAvailabilityQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<DeliveryAvailabilityQuery>({ ...options, query: DeliveryAvailabilitySsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useDeliveryAvailability = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<DeliveryAvailabilityQuery, DeliveryAvailabilityQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(DeliveryAvailabilitySsr, options);
};
export type PageDeliveryAvailabilityComp = React.FC<{data?: DeliveryAvailabilityQuery, error?: Apollo.ApolloError}>;
export const withPageDeliveryAvailability = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<DeliveryAvailabilityQuery, DeliveryAvailabilityQueryVariables>) => (WrappedComponent:PageDeliveryAvailabilityComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(DeliveryAvailabilitySsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrDeliveryAvailability = {
      getServerPage: getServerPageDeliveryAvailability,
      withPage: withPageDeliveryAvailability,
      usePage: useDeliveryAvailability,
    }
export const CollectionAvailabilitySsr = gql`
    query collectionAvailability($buzzbikeRetailId: Int, $postcode: String) {
  availableCollectionDates(
    buzzbikeRetailId: $buzzbikeRetailId
    postcode: $postcode
  )
}
    `;
export async function getServerPageCollectionAvailability
    (options: Omit<Apollo.QueryOptions<CollectionAvailabilityQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<CollectionAvailabilityQuery>({ ...options, query: CollectionAvailabilitySsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCollectionAvailability = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<CollectionAvailabilityQuery, CollectionAvailabilityQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(CollectionAvailabilitySsr, options);
};
export type PageCollectionAvailabilityComp = React.FC<{data?: CollectionAvailabilityQuery, error?: Apollo.ApolloError}>;
export const withPageCollectionAvailability = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<CollectionAvailabilityQuery, CollectionAvailabilityQueryVariables>) => (WrappedComponent:PageCollectionAvailabilityComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(CollectionAvailabilitySsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCollectionAvailability = {
      getServerPage: getServerPageCollectionAvailability,
      withPage: withPageCollectionAvailability,
      usePage: useCollectionAvailability,
    }
export const SwapAvailabilitySsr = gql`
    query swapAvailability($cityId: Int, $postcode: String) {
  availableSwapDates(cityId: $cityId, postcode: $postcode)
}
    `;
export async function getServerPageSwapAvailability
    (options: Omit<Apollo.QueryOptions<SwapAvailabilityQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<SwapAvailabilityQuery>({ ...options, query: SwapAvailabilitySsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSwapAvailability = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<SwapAvailabilityQuery, SwapAvailabilityQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(SwapAvailabilitySsr, options);
};
export type PageSwapAvailabilityComp = React.FC<{data?: SwapAvailabilityQuery, error?: Apollo.ApolloError}>;
export const withPageSwapAvailability = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<SwapAvailabilityQuery, SwapAvailabilityQueryVariables>) => (WrappedComponent:PageSwapAvailabilityComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(SwapAvailabilitySsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrSwapAvailability = {
      getServerPage: getServerPageSwapAvailability,
      withPage: withPageSwapAvailability,
      usePage: useSwapAvailability,
    }
export const SubscriptionCreationStateSsr = gql`
    query subscriptionCreationState($id: UUID!) {
  subscriptionCreationState(id: $id) {
    errorMessage
    screeningFailed
    subscription {
      ...subscription
    }
  }
}
    ${SubscriptionFragmentDoc}`;
export async function getServerPageSubscriptionCreationState
    (options: Omit<Apollo.QueryOptions<SubscriptionCreationStateQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<SubscriptionCreationStateQuery>({ ...options, query: SubscriptionCreationStateSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSubscriptionCreationState = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<SubscriptionCreationStateQuery, SubscriptionCreationStateQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(SubscriptionCreationStateSsr, options);
};
export type PageSubscriptionCreationStateComp = React.FC<{data?: SubscriptionCreationStateQuery, error?: Apollo.ApolloError}>;
export const withPageSubscriptionCreationState = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<SubscriptionCreationStateQuery, SubscriptionCreationStateQueryVariables>) => (WrappedComponent:PageSubscriptionCreationStateComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(SubscriptionCreationStateSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrSubscriptionCreationState = {
      getServerPage: getServerPageSubscriptionCreationState,
      withPage: withPageSubscriptionCreationState,
      usePage: useSubscriptionCreationState,
    }
export const GetBikeAvailabilitySsr = gql`
    query getBikeAvailability {
  getBikeAvailability {
    variations {
      images {
        imageUrl
        isFeatured
      }
      kitInventoryId
      code
      name
    }
    kitDescriptionId
    name
    shortDescription
  }
}
    `;
export async function getServerPageGetBikeAvailability
    (options: Omit<Apollo.QueryOptions<GetBikeAvailabilityQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetBikeAvailabilityQuery>({ ...options, query: GetBikeAvailabilitySsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetBikeAvailability = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetBikeAvailabilityQuery, GetBikeAvailabilityQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetBikeAvailabilitySsr, options);
};
export type PageGetBikeAvailabilityComp = React.FC<{data?: GetBikeAvailabilityQuery, error?: Apollo.ApolloError}>;
export const withPageGetBikeAvailability = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetBikeAvailabilityQuery, GetBikeAvailabilityQueryVariables>) => (WrappedComponent:PageGetBikeAvailabilityComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetBikeAvailabilitySsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetBikeAvailability = {
      getServerPage: getServerPageGetBikeAvailability,
      withPage: withPageGetBikeAvailability,
      usePage: useGetBikeAvailability,
    }
export const GetPackagesSsr = gql`
    query getPackages($bikeId: Int, $cityId: Int, $discountCode: String, $packageGroupName: String) {
  pricingPackages(
    bikeId: $bikeId
    cityId: $cityId
    discountCode: $discountCode
    packageGroupName: $packageGroupName
  ) {
    defaultPackage {
      packageSlug
      ...package
    }
    otherPackages {
      packageSlug
      ...package
    }
  }
}
    ${PackageFragmentDoc}`;
export async function getServerPageGetPackages
    (options: Omit<Apollo.QueryOptions<GetPackagesQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetPackagesQuery>({ ...options, query: GetPackagesSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetPackages = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetPackagesSsr, options);
};
export type PageGetPackagesComp = React.FC<{data?: GetPackagesQuery, error?: Apollo.ApolloError}>;
export const withPageGetPackages = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) => (WrappedComponent:PageGetPackagesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetPackagesSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetPackages = {
      getServerPage: getServerPageGetPackages,
      withPage: withPageGetPackages,
      usePage: useGetPackages,
    }
export const GetPricingPackageSsr = gql`
    query getPricingPackage($packageSlug: String!) {
  getPricingPackage(packageSlug: $packageSlug) {
    ...package
  }
}
    ${PackageFragmentDoc}`;
export async function getServerPageGetPricingPackage
    (options: Omit<Apollo.QueryOptions<GetPricingPackageQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetPricingPackageQuery>({ ...options, query: GetPricingPackageSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetPricingPackage = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetPricingPackageQuery, GetPricingPackageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetPricingPackageSsr, options);
};
export type PageGetPricingPackageComp = React.FC<{data?: GetPricingPackageQuery, error?: Apollo.ApolloError}>;
export const withPageGetPricingPackage = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetPricingPackageQuery, GetPricingPackageQueryVariables>) => (WrappedComponent:PageGetPricingPackageComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetPricingPackageSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetPricingPackage = {
      getServerPage: getServerPageGetPricingPackage,
      withPage: withPageGetPricingPackage,
      usePage: useGetPricingPackage,
    }
export const GetKitAvailabilitySsr = gql`
    query getKitAvailability($bikeId: Int, $cityId: Int) {
  __typename
  getKitAvailability(bikeId: $bikeId, cityId: $cityId) {
    brandLogoUrl
    brandName
    description
    whyWeLoveIt
    whatsIncluded
    featuresAndBenefits
    bestFor
    kitCategory {
      categoryName
      description
      imageUrl
      kitCategoryId
    }
    kitDescriptionId
    name
    returns
    shortDescription
    sizeGuide
    technicalDetails
    variations {
      code
      color
      images {
        imageUrl
        thumbnailImageUrl
        isFeatured
      }
      isSellingFast
      kitInventoryId
      name
      price
      quantity
      rrpPrice
      size
    }
  }
}
    `;
export async function getServerPageGetKitAvailability
    (options: Omit<Apollo.QueryOptions<GetKitAvailabilityQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetKitAvailabilityQuery>({ ...options, query: GetKitAvailabilitySsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetKitAvailability = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetKitAvailabilityQuery, GetKitAvailabilityQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetKitAvailabilitySsr, options);
};
export type PageGetKitAvailabilityComp = React.FC<{data?: GetKitAvailabilityQuery, error?: Apollo.ApolloError}>;
export const withPageGetKitAvailability = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetKitAvailabilityQuery, GetKitAvailabilityQueryVariables>) => (WrappedComponent:PageGetKitAvailabilityComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetKitAvailabilitySsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetKitAvailability = {
      getServerPage: getServerPageGetKitAvailability,
      withPage: withPageGetKitAvailability,
      usePage: useGetKitAvailability,
    }
export const GetSubscriptionQuoteSsr = gql`
    query getSubscriptionQuote($input: GetSubscriptionQuoteInput!) {
  getSubscriptionQuote(input: $input) {
    appliedDiscountCode {
      ...appliedDiscountCode
    }
    collectionFeeInPence
    couponToApplyAtEndOfMinimumTerm {
      ...stripeCoupon
    }
    delivery {
      comparativeCost
      cost
      deliveryDate
    }
    totalDueToday
    selectedBike {
      bikeId
      images {
        imageUrl
        isFeatured
        thumbnailImageUrl
      }
      name
      variationName
    }
    selectedKitItems {
      variations {
        kitInventoryId
        price
        quantity
        code
        color
        images {
          imageUrl
          thumbnailImageUrl
          isFeatured
        }
        name
        rrpPrice
        size
      }
      bestFor
      brandLogoUrl
      brandName
      kitCategory {
        categoryName
        description
        imageUrl
        kitCategoryId
      }
      description
      kitDescriptionId
      name
      returns
      shortDescription
      sizeGuide
      technicalDetails
      whyWeLoveIt
      whatsIncluded
    }
    setupFeeInPence
    theftFeeAddOnPriceInPence
    subscription {
      ... on MonthlyQuote {
        cancellation {
          earliestDate
          noticePeriodDays
        }
        monthlyCommitment: commitment {
          lengthMonths
          monthlyRateAfterwards
        }
        noMaintenance
        revertsTo {
          delayMonths
          stickerPrice
        }
        stickerPrice
        charged
      }
      ... on FixedTermQuote {
        cancellation {
          earliestDate
          noticePeriodDays
        }
        fixedTermCommitment: commitment {
          lengthMonths
          monthlyRateAfterwards
        }
        noMaintenance
        stickerPrice
      }
    }
  }
}
    ${AppliedDiscountCodeFragmentDoc}
${StripeCouponFragmentDoc}`;
export async function getServerPageGetSubscriptionQuote
    (options: Omit<Apollo.QueryOptions<GetSubscriptionQuoteQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetSubscriptionQuoteQuery>({ ...options, query: GetSubscriptionQuoteSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSubscriptionQuote = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetSubscriptionQuoteQuery, GetSubscriptionQuoteQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetSubscriptionQuoteSsr, options);
};
export type PageGetSubscriptionQuoteComp = React.FC<{data?: GetSubscriptionQuoteQuery, error?: Apollo.ApolloError}>;
export const withPageGetSubscriptionQuote = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetSubscriptionQuoteQuery, GetSubscriptionQuoteQueryVariables>) => (WrappedComponent:PageGetSubscriptionQuoteComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetSubscriptionQuoteSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetSubscriptionQuote = {
      getServerPage: getServerPageGetSubscriptionQuote,
      withPage: withPageGetSubscriptionQuote,
      usePage: useGetSubscriptionQuote,
    }
export const StartSetupStripePaymentSsr = gql`
    mutation startSetupStripePayment {
  startSetupStripePaymentAnonymous {
    clientSecret
    setupIntentId
  }
}
    `;
export const CheckoutSubscriptionSsr = gql`
    mutation checkoutSubscription($input: CreateSubscriptionInput!) {
  startSubscriptionCreation(input: $input) {
    ... on CreateSubscriptionFailed {
      errorMessage
    }
    ... on CreateSubscriptionStarted {
      id
    }
  }
}
    `;
export const GetChangingPlanQuoteSsr = gql`
    query getChangingPlanQuote($input: GetChangingPlanQuoteInput!) {
  getChangingPlanQuote(input: $input) {
    ... on ChangingPlanQuote {
      currentPackage {
        ...package
      }
      newPackage {
        ...package
      }
      additionalProRataInPence
      appliedDiscountCode
      nextMonthlyInvoiceDate
      endDate
      startDate
      theftFeeAddOnPriceInPence
      newSubscription {
        ... on MonthlyQuote {
          cancellation {
            earliestDate
            noticePeriodDays
          }
          monthlyCommitment: commitment {
            lengthMonths
            monthlyRateAfterwards
          }
          revertsTo {
            delayMonths
            stickerPrice
          }
          stickerPrice
          charged
        }
        ... on FixedTermQuote {
          cancellation {
            earliestDate
            noticePeriodDays
          }
          fixedTermCommitment: commitment {
            lengthMonths
            monthlyRateAfterwards
          }
          stickerPrice
        }
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${PackageFragmentDoc}`;
export async function getServerPageGetChangingPlanQuote
    (options: Omit<Apollo.QueryOptions<GetChangingPlanQuoteQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetChangingPlanQuoteQuery>({ ...options, query: GetChangingPlanQuoteSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetChangingPlanQuote = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetChangingPlanQuoteQuery, GetChangingPlanQuoteQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetChangingPlanQuoteSsr, options);
};
export type PageGetChangingPlanQuoteComp = React.FC<{data?: GetChangingPlanQuoteQuery, error?: Apollo.ApolloError}>;
export const withPageGetChangingPlanQuote = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetChangingPlanQuoteQuery, GetChangingPlanQuoteQueryVariables>) => (WrappedComponent:PageGetChangingPlanQuoteComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetChangingPlanQuoteSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetChangingPlanQuote = {
      getServerPage: getServerPageGetChangingPlanQuote,
      withPage: withPageGetChangingPlanQuote,
      usePage: useGetChangingPlanQuote,
    }
export const GetTheftFeeAddOnPriceAtSingupSsr = gql`
    query getTheftFeeAddOnPriceAtSingup($bikeId: Int) {
  getTheftFeeAddOnPriceAtSingup(bikeId: $bikeId) {
    ... on TheftFeeAddOnPriceType {
      applyFrom
      theftFeeAddOnPriceInPence
    }
    ... on APIError {
      message
    }
  }
}
    `;
export async function getServerPageGetTheftFeeAddOnPriceAtSingup
    (options: Omit<Apollo.QueryOptions<GetTheftFeeAddOnPriceAtSingupQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetTheftFeeAddOnPriceAtSingupQuery>({ ...options, query: GetTheftFeeAddOnPriceAtSingupSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTheftFeeAddOnPriceAtSingup = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetTheftFeeAddOnPriceAtSingupQuery, GetTheftFeeAddOnPriceAtSingupQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetTheftFeeAddOnPriceAtSingupSsr, options);
};
export type PageGetTheftFeeAddOnPriceAtSingupComp = React.FC<{data?: GetTheftFeeAddOnPriceAtSingupQuery, error?: Apollo.ApolloError}>;
export const withPageGetTheftFeeAddOnPriceAtSingup = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetTheftFeeAddOnPriceAtSingupQuery, GetTheftFeeAddOnPriceAtSingupQueryVariables>) => (WrappedComponent:PageGetTheftFeeAddOnPriceAtSingupComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetTheftFeeAddOnPriceAtSingupSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetTheftFeeAddOnPriceAtSingup = {
      getServerPage: getServerPageGetTheftFeeAddOnPriceAtSingup,
      withPage: withPageGetTheftFeeAddOnPriceAtSingup,
      usePage: useGetTheftFeeAddOnPriceAtSingup,
    }
export const CreateAccountSsr = gql`
    mutation createAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    __typename
    ... on CreateAccountResult {
      accessToken
      viewer {
        ...viewer
      }
    }
    ... on CreateUserErrorDuplicate {
      message
    }
  }
}
    ${ViewerFragmentDoc}`;
export const UpdateAccountSsr = gql`
    mutation updateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    ...viewer
  }
}
    ${ViewerFragmentDoc}`;
export const ChangePasswordSsr = gql`
    mutation changePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    ... on ChangePasswordResult {
      message
    }
    ... on ChangePasswordError {
      message
    }
  }
}
    `;
export const ResetPasswordSsr = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    ... on ResetPasswordResult {
      message
    }
    ... on ResetPasswordError {
      message
    }
  }
}
    `;
export const RedeemResetPasswordTokenSsr = gql`
    mutation redeemResetPasswordToken($input: RedeemResetPasswordTokenInput!) {
  redeemResetPasswordToken(input: $input) {
    ... on ResetPasswordResult {
      message
    }
    ... on ResetPasswordError {
      message
    }
  }
}
    `;
export const ApplyPromoCodeSsr = gql`
    mutation applyPromoCode($promoCode: String!) {
  echo(input: {message: $promoCode}) {
    ... on EchoSuccess {
      message: message
    }
    ... on EchoError {
      error: error
    }
  }
}
    `;
export const StartEditStripePaymentSsr = gql`
    mutation startEditStripePayment {
  startEditStripePayment {
    clientSecret
    setupIntentId
  }
}
    `;
export const FinishEditStripePaymentSsr = gql`
    mutation finishEditStripePayment($setupIntentId: String!) {
  finishEditStripePayment(setupIntentId: $setupIntentId) {
    cardholderName
    expMonth
    expYear
    last4
  }
}
    `;
export const UpdateMarketingPreferencesSsr = gql`
    mutation updateMarketingPreferences($email: String!, $optedIn: Boolean!) {
  updateMarketingPreferences(email: $email, optedIn: $optedIn)
}
    `;
export const CreateRejoinerSsr = gql`
    mutation createRejoiner {
  createRejoiner {
    ... on CreateAccountResult {
      accessToken
      viewer {
        ...viewer
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${ViewerFragmentDoc}`;
export const ChangePlanSsr = gql`
    mutation changePlan($input: ChangingPlanData!) {
  changePlan(input: $input) {
    ... on SubscribedPackages {
      currentPackage {
        ...subscribedPackage
      }
      nextPackage {
        ...subscribedPackage
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${SubscribedPackageFragmentDoc}`;
export const UpdateKitOrderSsr = gql`
    mutation updateKitOrder($input: DeliveryOptionsInput!) {
  updateKitOrder(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const UpdateRiderSsr = gql`
    mutation updateRider($input: UpdateRiderInput!) {
  updateRider(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const ApplyRetentionDiscountSsr = gql`
    mutation applyRetentionDiscount($discountCode: String!) {
  applyRetentionDiscount(discountCode: $discountCode) {
    ... on StripeUpcomingInvoice {
      ...stripeUpcomingInvoice
    }
    ... on APIError {
      message
    }
  }
}
    ${StripeUpcomingInvoiceFragmentDoc}`;
export const PauseCollectionPaymentSsr = gql`
    mutation pauseCollectionPayment($chargeAmountInPence: Int!, $months: Int!) {
  pauseCollectionPayment(
    chargeAmountInPence: $chargeAmountInPence
    months: $months
  ) {
    ... on StripeUpcomingInvoice {
      ...stripeUpcomingInvoice
    }
    ... on APIError {
      message
    }
  }
}
    ${StripeUpcomingInvoiceFragmentDoc}`;
export const JoinTheWaitlistSsr = gql`
    mutation joinTheWaitlist($input: WaitlistInput!) {
  __typename
  joinTheWaitlist(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const DeleteAccountSsr = gql`
    mutation deleteAccount {
  deleteAccount {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const CancelChangingPlanSsr = gql`
    mutation cancelChangingPlan {
  cancelChangingPlan {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const BuyYourBikeSsr = gql`
    mutation buyYourBike($priceInPence: Int) {
  buyYourBike(priceInPence: $priceInPence) {
    ... on InvoicePdf {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const ReportAnIssueSsr = gql`
    mutation reportAnIssue($input: IssueReportInput!) {
  reportAnIssue(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const RescheduleAnIssueSsr = gql`
    mutation rescheduleAnIssue($input: RescheduleIssueReportInput!) {
  rescheduleAnIssue(input: $input) {
    ... on IssueReportData {
      ...issueReportData
    }
    ... on APIError {
      message
    }
  }
}
    ${IssueReportDataFragmentDoc}`;
export const RescheduleCollectionSsr = gql`
    mutation rescheduleCollection($input: OrderReschedulingInput!) {
  rescheduleCollectionOrder(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const RevertCancellationSsr = gql`
    mutation revertCancellation {
  revertCancellation {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const ClaimLoyaltyRewardSsr = gql`
    mutation claimLoyaltyReward($rewardKey: RewardJourneyKey!, $buzziversaryOrder: KitOrderTypeInput) {
  claimLoyaltyReward(rewardKey: $rewardKey, buzziversaryOrder: $buzziversaryOrder) {
    ... on StripeUpcomingInvoice {
      ...stripeUpcomingInvoice
    }
    ... on IssueReportType {
      category
      subcategory
      address
      appointmentEndTime
      appointmentStartTime
      reportNumber
    }
    ... on APIError {
      message
    }
  }
}
    ${StripeUpcomingInvoiceFragmentDoc}`;
export const ChangePaymentDateSsr = gql`
    mutation changePaymentDate($requestedDay: Int!) {
  changePaymentDate(requestedDay: $requestedDay) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const CreateKlarnaUrlSsr = gql`
    mutation createKlarnaUrl($invoiceId: String!, $redirectUrl: String!) {
  createKlarnaUrl(invoiceId: $invoiceId, redirectUrl: $redirectUrl) {
    ... on KlarnaObject {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const UpdateBillingAddressSsr = gql`
    mutation updateBillingAddress($input: UpdateBillingAddressInput!) {
  updateBillingAddress(input: $input) {
    message
  }
}
    `;
export const SubscribeTheftFeeAddOnSsr = gql`
    mutation subscribeTheftFeeAddOn {
  subscribeTheftFeeAddOn {
    ... on SubscriptionTheftFeeAddOnType {
      ...subscriptionTheftFeeAddOnType
    }
    ... on APIError {
      message
    }
  }
}
    ${SubscriptionTheftFeeAddOnTypeFragmentDoc}`;
export const CancelTheftFeeAddOnSsr = gql`
    mutation cancelTheftFeeAddOn {
  cancelTheftFeeAddOn {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const JoinTheGeoggraphicWaitlistSsr = gql`
    mutation joinTheGeoggraphicWaitlist($input: GeographicWaitlistInput!) {
  joinTheGeoggraphicWaitlist(input: $input) {
    ... on APIResult {
      message
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const CreateKlarnaUrlForBuyingYourBikeSsr = gql`
    mutation createKlarnaUrlForBuyingYourBike($redirectUrl: String!) {
  createKlarnaUrlForBuyingYourBike(redirectUrl: $redirectUrl) {
    ... on KlarnaObject {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const CreateKlarnaUrlForCancellationSsr = gql`
    mutation createKlarnaUrlForCancellation($input: CancellationRequestInput!, $redirectUrl: String!) {
  createKlarnaUrlForCancellation(data: $input, redirectUrl: $redirectUrl) {
    ... on KlarnaObject {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;
export const CheckUserSsr = gql`
    query checkUser($email: String!) {
  checkEmailHasAccount(email: $email)
}
    `;
export async function getServerPageCheckUser
    (options: Omit<Apollo.QueryOptions<CheckUserQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<CheckUserQuery>({ ...options, query: CheckUserSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCheckUser = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<CheckUserQuery, CheckUserQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(CheckUserSsr, options);
};
export type PageCheckUserComp = React.FC<{data?: CheckUserQuery, error?: Apollo.ApolloError}>;
export const withPageCheckUser = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<CheckUserQuery, CheckUserQueryVariables>) => (WrappedComponent:PageCheckUserComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(CheckUserSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCheckUser = {
      getServerPage: getServerPageCheckUser,
      withPage: withPageCheckUser,
      usePage: useCheckUser,
    }
export const HasSubscriptionSsr = gql`
    query hasSubscription {
  viewer {
    hasSubscription
  }
}
    `;
export async function getServerPageHasSubscription
    (options: Omit<Apollo.QueryOptions<HasSubscriptionQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<HasSubscriptionQuery>({ ...options, query: HasSubscriptionSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useHasSubscription = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<HasSubscriptionQuery, HasSubscriptionQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(HasSubscriptionSsr, options);
};
export type PageHasSubscriptionComp = React.FC<{data?: HasSubscriptionQuery, error?: Apollo.ApolloError}>;
export const withPageHasSubscription = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<HasSubscriptionQuery, HasSubscriptionQueryVariables>) => (WrappedComponent:PageHasSubscriptionComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(HasSubscriptionSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrHasSubscription = {
      getServerPage: getServerPageHasSubscription,
      withPage: withPageHasSubscription,
      usePage: useHasSubscription,
    }
export const GetReferralCodeSsr = gql`
    query getReferralCode {
  viewer {
    subscription {
      generatedReferralCode
    }
  }
}
    `;
export async function getServerPageGetReferralCode
    (options: Omit<Apollo.QueryOptions<GetReferralCodeQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetReferralCodeQuery>({ ...options, query: GetReferralCodeSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetReferralCode = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetReferralCodeQuery, GetReferralCodeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetReferralCodeSsr, options);
};
export type PageGetReferralCodeComp = React.FC<{data?: GetReferralCodeQuery, error?: Apollo.ApolloError}>;
export const withPageGetReferralCode = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetReferralCodeQuery, GetReferralCodeQueryVariables>) => (WrappedComponent:PageGetReferralCodeComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetReferralCodeSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetReferralCode = {
      getServerPage: getServerPageGetReferralCode,
      withPage: withPageGetReferralCode,
      usePage: useGetReferralCode,
    }
export const AllHdyhausSsr = gql`
    query allHdyhaus {
  hdyhauOptions
}
    `;
export async function getServerPageAllHdyhaus
    (options: Omit<Apollo.QueryOptions<AllHdyhausQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<AllHdyhausQuery>({ ...options, query: AllHdyhausSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useAllHdyhaus = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<AllHdyhausQuery, AllHdyhausQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(AllHdyhausSsr, options);
};
export type PageAllHdyhausComp = React.FC<{data?: AllHdyhausQuery, error?: Apollo.ApolloError}>;
export const withPageAllHdyhaus = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<AllHdyhausQuery, AllHdyhausQueryVariables>) => (WrappedComponent:PageAllHdyhausComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(AllHdyhausSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrAllHdyhaus = {
      getServerPage: getServerPageAllHdyhaus,
      withPage: withPageAllHdyhaus,
      usePage: useAllHdyhaus,
    }
export const GetConfirmationViewerSsr = gql`
    query getConfirmationViewer {
  viewer {
    subscription {
      stripeSubscriptionId
      discountCode
      packageSlug
      requestedDeliveryDate
      generatedReferralCode
    }
    riderId
    isRejoiner
  }
}
    `;
export async function getServerPageGetConfirmationViewer
    (options: Omit<Apollo.QueryOptions<GetConfirmationViewerQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetConfirmationViewerQuery>({ ...options, query: GetConfirmationViewerSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetConfirmationViewer = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetConfirmationViewerQuery, GetConfirmationViewerQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetConfirmationViewerSsr, options);
};
export type PageGetConfirmationViewerComp = React.FC<{data?: GetConfirmationViewerQuery, error?: Apollo.ApolloError}>;
export const withPageGetConfirmationViewer = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetConfirmationViewerQuery, GetConfirmationViewerQueryVariables>) => (WrappedComponent:PageGetConfirmationViewerComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetConfirmationViewerSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetConfirmationViewer = {
      getServerPage: getServerPageGetConfirmationViewer,
      withPage: withPageGetConfirmationViewer,
      usePage: useGetConfirmationViewer,
    }
export const CitiesSsr = gql`
    query cities {
  cities {
    ...city
  }
}
    ${CityFragmentDoc}`;
export async function getServerPageCities
    (options: Omit<Apollo.QueryOptions<CitiesQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<CitiesQuery>({ ...options, query: CitiesSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCities = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<CitiesQuery, CitiesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(CitiesSsr, options);
};
export type PageCitiesComp = React.FC<{data?: CitiesQuery, error?: Apollo.ApolloError}>;
export const withPageCities = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<CitiesQuery, CitiesQueryVariables>) => (WrappedComponent:PageCitiesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(CitiesSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrCities = {
      getServerPage: getServerPageCities,
      withPage: withPageCities,
      usePage: useCities,
    }
export const BikesSsr = gql`
    query bikes($cityId: Int) {
  bikes(cityId: $cityId) {
    bikes {
      ...bikeType
    }
    city {
      ...city
    }
    id
  }
}
    ${BikeTypeFragmentDoc}
${CityFragmentDoc}`;
export async function getServerPageBikes
    (options: Omit<Apollo.QueryOptions<BikesQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<BikesQuery>({ ...options, query: BikesSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBikes = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<BikesQuery, BikesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(BikesSsr, options);
};
export type PageBikesComp = React.FC<{data?: BikesQuery, error?: Apollo.ApolloError}>;
export const withPageBikes = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<BikesQuery, BikesQueryVariables>) => (WrappedComponent:PageBikesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(BikesSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrBikes = {
      getServerPage: getServerPageBikes,
      withPage: withPageBikes,
      usePage: useBikes,
    }
export const PreviewBuyingYourBikeSsr = gql`
    query previewBuyingYourBike($priceInPence: Int) {
  previewBuyingYourBike(priceInPence: $priceInPence) {
    ... on BuyingBikeInvoiceSummary {
      buyingBikePrice
      paymentMethod {
        ...paymentMethod
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export async function getServerPagePreviewBuyingYourBike
    (options: Omit<Apollo.QueryOptions<PreviewBuyingYourBikeQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<PreviewBuyingYourBikeQuery>({ ...options, query: PreviewBuyingYourBikeSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePreviewBuyingYourBike = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<PreviewBuyingYourBikeQuery, PreviewBuyingYourBikeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(PreviewBuyingYourBikeSsr, options);
};
export type PagePreviewBuyingYourBikeComp = React.FC<{data?: PreviewBuyingYourBikeQuery, error?: Apollo.ApolloError}>;
export const withPagePreviewBuyingYourBike = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<PreviewBuyingYourBikeQuery, PreviewBuyingYourBikeQueryVariables>) => (WrappedComponent:PagePreviewBuyingYourBikeComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(PreviewBuyingYourBikeSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrPreviewBuyingYourBike = {
      getServerPage: getServerPagePreviewBuyingYourBike,
      withPage: withPagePreviewBuyingYourBike,
      usePage: usePreviewBuyingYourBike,
    }
export const RescheduleDeliveryOrderSsr = gql`
    query rescheduleDeliveryOrder($input: OrderReschedulingInput!) {
  rescheduleDeliveryOrder(input: $input) {
    ... on DeliveryOrderReschedulingInvoiceSummary {
      deliveryCharge
      paymentMethod {
        ...paymentMethod
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export async function getServerPageRescheduleDeliveryOrder
    (options: Omit<Apollo.QueryOptions<RescheduleDeliveryOrderQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<RescheduleDeliveryOrderQuery>({ ...options, query: RescheduleDeliveryOrderSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRescheduleDeliveryOrder = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<RescheduleDeliveryOrderQuery, RescheduleDeliveryOrderQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(RescheduleDeliveryOrderSsr, options);
};
export type PageRescheduleDeliveryOrderComp = React.FC<{data?: RescheduleDeliveryOrderQuery, error?: Apollo.ApolloError}>;
export const withPageRescheduleDeliveryOrder = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<RescheduleDeliveryOrderQuery, RescheduleDeliveryOrderQueryVariables>) => (WrappedComponent:PageRescheduleDeliveryOrderComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(RescheduleDeliveryOrderSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrRescheduleDeliveryOrder = {
      getServerPage: getServerPageRescheduleDeliveryOrder,
      withPage: withPageRescheduleDeliveryOrder,
      usePage: useRescheduleDeliveryOrder,
    }
export const GetIssueReportsSsr = gql`
    query getIssueReports {
  getIssueReports {
    ... on IssueReportList {
      issueReports {
        ...issueReportData
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${IssueReportDataFragmentDoc}`;
export async function getServerPageGetIssueReports
    (options: Omit<Apollo.QueryOptions<GetIssueReportsQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetIssueReportsQuery>({ ...options, query: GetIssueReportsSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetIssueReports = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetIssueReportsQuery, GetIssueReportsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetIssueReportsSsr, options);
};
export type PageGetIssueReportsComp = React.FC<{data?: GetIssueReportsQuery, error?: Apollo.ApolloError}>;
export const withPageGetIssueReports = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetIssueReportsQuery, GetIssueReportsQueryVariables>) => (WrappedComponent:PageGetIssueReportsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetIssueReportsSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetIssueReports = {
      getServerPage: getServerPageGetIssueReports,
      withPage: withPageGetIssueReports,
      usePage: useGetIssueReports,
    }
export const AvailableBikeProfDatesSsr = gql`
    query availableBikeProfDates($postcode: String) {
  availableBikeProfDates(postcode: $postcode) {
    ... on SlotPairList {
      availableSlots {
        startTime
        endTime
      }
    }
    ... on APIError {
      message
    }
  }
}
    `;
export async function getServerPageAvailableBikeProfDates
    (options: Omit<Apollo.QueryOptions<AvailableBikeProfDatesQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<AvailableBikeProfDatesQuery>({ ...options, query: AvailableBikeProfDatesSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useAvailableBikeProfDates = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<AvailableBikeProfDatesQuery, AvailableBikeProfDatesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(AvailableBikeProfDatesSsr, options);
};
export type PageAvailableBikeProfDatesComp = React.FC<{data?: AvailableBikeProfDatesQuery, error?: Apollo.ApolloError}>;
export const withPageAvailableBikeProfDates = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<AvailableBikeProfDatesQuery, AvailableBikeProfDatesQueryVariables>) => (WrappedComponent:PageAvailableBikeProfDatesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(AvailableBikeProfDatesSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrAvailableBikeProfDates = {
      getServerPage: getServerPageAvailableBikeProfDates,
      withPage: withPageAvailableBikeProfDates,
      usePage: useAvailableBikeProfDates,
    }
export const RescheduleCollectionOrderSsr = gql`
    query rescheduleCollectionOrder($input: OrderReschedulingInput!) {
  rescheduleCollectionOrder(input: $input) {
    ... on CollectionOrderReschedulingInvoiceSummary {
      collectionCharge
      prorataPayment
      paymentMethod {
        ...paymentMethod
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export async function getServerPageRescheduleCollectionOrder
    (options: Omit<Apollo.QueryOptions<RescheduleCollectionOrderQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<RescheduleCollectionOrderQuery>({ ...options, query: RescheduleCollectionOrderSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRescheduleCollectionOrder = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<RescheduleCollectionOrderQuery, RescheduleCollectionOrderQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(RescheduleCollectionOrderSsr, options);
};
export type PageRescheduleCollectionOrderComp = React.FC<{data?: RescheduleCollectionOrderQuery, error?: Apollo.ApolloError}>;
export const withPageRescheduleCollectionOrder = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<RescheduleCollectionOrderQuery, RescheduleCollectionOrderQueryVariables>) => (WrappedComponent:PageRescheduleCollectionOrderComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(RescheduleCollectionOrderSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrRescheduleCollectionOrder = {
      getServerPage: getServerPageRescheduleCollectionOrder,
      withPage: withPageRescheduleCollectionOrder,
      usePage: useRescheduleCollectionOrder,
    }
export const ValidateRedemptionCodeSsr = gql`
    query validateRedemptionCode($redemptionCode: String!, $isCyclescheme: Boolean! = false) {
  validateRedemptionCode(
    redemptionCode: $redemptionCode
    isCyclescheme: $isCyclescheme
  ) {
    ... on RedemptionCodeInfo {
      ...redemptionCodeInfo
    }
    ... on APIError {
      message
    }
  }
}
    ${RedemptionCodeInfoFragmentDoc}`;
export async function getServerPageValidateRedemptionCode
    (options: Omit<Apollo.QueryOptions<ValidateRedemptionCodeQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<ValidateRedemptionCodeQuery>({ ...options, query: ValidateRedemptionCodeSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useValidateRedemptionCode = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<ValidateRedemptionCodeQuery, ValidateRedemptionCodeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(ValidateRedemptionCodeSsr, options);
};
export type PageValidateRedemptionCodeComp = React.FC<{data?: ValidateRedemptionCodeQuery, error?: Apollo.ApolloError}>;
export const withPageValidateRedemptionCode = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<ValidateRedemptionCodeQuery, ValidateRedemptionCodeQueryVariables>) => (WrappedComponent:PageValidateRedemptionCodeComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(ValidateRedemptionCodeSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrValidateRedemptionCode = {
      getServerPage: getServerPageValidateRedemptionCode,
      withPage: withPageValidateRedemptionCode,
      usePage: useValidateRedemptionCode,
    }
export const GetDefaultPaymentMethodSsr = gql`
    query getDefaultPaymentMethod {
  getDefaultPaymentMethod {
    ... on PaymentMethod {
      ...paymentMethod
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export async function getServerPageGetDefaultPaymentMethod
    (options: Omit<Apollo.QueryOptions<GetDefaultPaymentMethodQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetDefaultPaymentMethodQuery>({ ...options, query: GetDefaultPaymentMethodSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetDefaultPaymentMethod = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetDefaultPaymentMethodQuery, GetDefaultPaymentMethodQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetDefaultPaymentMethodSsr, options);
};
export type PageGetDefaultPaymentMethodComp = React.FC<{data?: GetDefaultPaymentMethodQuery, error?: Apollo.ApolloError}>;
export const withPageGetDefaultPaymentMethod = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetDefaultPaymentMethodQuery, GetDefaultPaymentMethodQueryVariables>) => (WrappedComponent:PageGetDefaultPaymentMethodComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetDefaultPaymentMethodSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetDefaultPaymentMethod = {
      getServerPage: getServerPageGetDefaultPaymentMethod,
      withPage: withPageGetDefaultPaymentMethod,
      usePage: useGetDefaultPaymentMethod,
    }
export const GetBuzzbikeRetailsSsr = gql`
    query getBuzzbikeRetails($cityId: Int!) {
  getBuzzbikeRetails(cityId: $cityId) {
    addressLineOne
    addressLineTwo
    city
    id
    postcode
    retailName
    retailPhoneNumber
  }
}
    `;
export async function getServerPageGetBuzzbikeRetails
    (options: Omit<Apollo.QueryOptions<GetBuzzbikeRetailsQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetBuzzbikeRetailsQuery>({ ...options, query: GetBuzzbikeRetailsSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetBuzzbikeRetails = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetBuzzbikeRetailsQuery, GetBuzzbikeRetailsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetBuzzbikeRetailsSsr, options);
};
export type PageGetBuzzbikeRetailsComp = React.FC<{data?: GetBuzzbikeRetailsQuery, error?: Apollo.ApolloError}>;
export const withPageGetBuzzbikeRetails = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetBuzzbikeRetailsQuery, GetBuzzbikeRetailsQueryVariables>) => (WrappedComponent:PageGetBuzzbikeRetailsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetBuzzbikeRetailsSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetBuzzbikeRetails = {
      getServerPage: getServerPageGetBuzzbikeRetails,
      withPage: withPageGetBuzzbikeRetails,
      usePage: useGetBuzzbikeRetails,
    }
export const GetChangePaymentDateSsr = gql`
    query getChangePaymentDate($requestedDay: Int!) {
  changePaymentDate(requestedDay: $requestedDay) {
    ... on ChangePaymentDateProrata {
      chargeAmount
      chargeFromDate
      chargeToDate
      theftFeeAddOnChargeAmount
    }
    ... on APIError {
      message
    }
  }
}
    `;
export async function getServerPageGetChangePaymentDate
    (options: Omit<Apollo.QueryOptions<GetChangePaymentDateQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetChangePaymentDateQuery>({ ...options, query: GetChangePaymentDateSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetChangePaymentDate = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetChangePaymentDateQuery, GetChangePaymentDateQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetChangePaymentDateSsr, options);
};
export type PageGetChangePaymentDateComp = React.FC<{data?: GetChangePaymentDateQuery, error?: Apollo.ApolloError}>;
export const withPageGetChangePaymentDate = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetChangePaymentDateQuery, GetChangePaymentDateQueryVariables>) => (WrappedComponent:PageGetChangePaymentDateComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetChangePaymentDateSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetChangePaymentDate = {
      getServerPage: getServerPageGetChangePaymentDate,
      withPage: withPageGetChangePaymentDate,
      usePage: useGetChangePaymentDate,
    }
export const OutstandingInvoicesSsr = gql`
    query outstandingInvoices {
  outstandingInvoices {
    ...summarisedInvoice
  }
}
    ${SummarisedInvoiceFragmentDoc}`;
export async function getServerPageOutstandingInvoices
    (options: Omit<Apollo.QueryOptions<OutstandingInvoicesQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<OutstandingInvoicesQuery>({ ...options, query: OutstandingInvoicesSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useOutstandingInvoices = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<OutstandingInvoicesQuery, OutstandingInvoicesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(OutstandingInvoicesSsr, options);
};
export type PageOutstandingInvoicesComp = React.FC<{data?: OutstandingInvoicesQuery, error?: Apollo.ApolloError}>;
export const withPageOutstandingInvoices = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<OutstandingInvoicesQuery, OutstandingInvoicesQueryVariables>) => (WrappedComponent:PageOutstandingInvoicesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(OutstandingInvoicesSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrOutstandingInvoices = {
      getServerPage: getServerPageOutstandingInvoices,
      withPage: withPageOutstandingInvoices,
      usePage: useOutstandingInvoices,
    }
export const UpcomingInvoicesSsr = gql`
    query upcomingInvoices {
  upcomingInvoice {
    ...upcomingInvoice
  }
}
    ${UpcomingInvoiceFragmentDoc}`;
export async function getServerPageUpcomingInvoices
    (options: Omit<Apollo.QueryOptions<UpcomingInvoicesQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<UpcomingInvoicesQuery>({ ...options, query: UpcomingInvoicesSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useUpcomingInvoices = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<UpcomingInvoicesQuery, UpcomingInvoicesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(UpcomingInvoicesSsr, options);
};
export type PageUpcomingInvoicesComp = React.FC<{data?: UpcomingInvoicesQuery, error?: Apollo.ApolloError}>;
export const withPageUpcomingInvoices = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<UpcomingInvoicesQuery, UpcomingInvoicesQueryVariables>) => (WrappedComponent:PageUpcomingInvoicesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(UpcomingInvoicesSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrUpcomingInvoices = {
      getServerPage: getServerPageUpcomingInvoices,
      withPage: withPageUpcomingInvoices,
      usePage: useUpcomingInvoices,
    }
export const GetTheftFeeAddOnPriceSsr = gql`
    query getTheftFeeAddOnPrice($bikeId: Int) {
  getTheftFeeAddOnPrice(bikeId: $bikeId) {
    ... on TheftFeeAddOnPriceType {
      applyFrom
      theftFeeAddOnPriceInPence
    }
    ... on APIError {
      message
    }
  }
}
    `;
export async function getServerPageGetTheftFeeAddOnPrice
    (options: Omit<Apollo.QueryOptions<GetTheftFeeAddOnPriceQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetTheftFeeAddOnPriceQuery>({ ...options, query: GetTheftFeeAddOnPriceSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetTheftFeeAddOnPrice = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetTheftFeeAddOnPriceQuery, GetTheftFeeAddOnPriceQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetTheftFeeAddOnPriceSsr, options);
};
export type PageGetTheftFeeAddOnPriceComp = React.FC<{data?: GetTheftFeeAddOnPriceQuery, error?: Apollo.ApolloError}>;
export const withPageGetTheftFeeAddOnPrice = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetTheftFeeAddOnPriceQuery, GetTheftFeeAddOnPriceQueryVariables>) => (WrappedComponent:PageGetTheftFeeAddOnPriceComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetTheftFeeAddOnPriceSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetTheftFeeAddOnPrice = {
      getServerPage: getServerPageGetTheftFeeAddOnPrice,
      withPage: withPageGetTheftFeeAddOnPrice,
      usePage: useGetTheftFeeAddOnPrice,
    }
export const GetSubscriptionTheftFeeAddOnSsr = gql`
    query getSubscriptionTheftFeeAddOn {
  getSubscriptionTheftFeeAddOn {
    ... on SubscriptionTheftFeeAddOnType {
      ...subscriptionTheftFeeAddOnType
    }
    ... on APIError {
      message
    }
  }
}
    ${SubscriptionTheftFeeAddOnTypeFragmentDoc}`;
export async function getServerPageGetSubscriptionTheftFeeAddOn
    (options: Omit<Apollo.QueryOptions<GetSubscriptionTheftFeeAddOnQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetSubscriptionTheftFeeAddOnQuery>({ ...options, query: GetSubscriptionTheftFeeAddOnSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSubscriptionTheftFeeAddOn = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetSubscriptionTheftFeeAddOnQuery, GetSubscriptionTheftFeeAddOnQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetSubscriptionTheftFeeAddOnSsr, options);
};
export type PageGetSubscriptionTheftFeeAddOnComp = React.FC<{data?: GetSubscriptionTheftFeeAddOnQuery, error?: Apollo.ApolloError}>;
export const withPageGetSubscriptionTheftFeeAddOn = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetSubscriptionTheftFeeAddOnQuery, GetSubscriptionTheftFeeAddOnQueryVariables>) => (WrappedComponent:PageGetSubscriptionTheftFeeAddOnComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetSubscriptionTheftFeeAddOnSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetSubscriptionTheftFeeAddOn = {
      getServerPage: getServerPageGetSubscriptionTheftFeeAddOn,
      withPage: withPageGetSubscriptionTheftFeeAddOn,
      usePage: useGetSubscriptionTheftFeeAddOn,
    }
export const GetBuyingYourBuzzbikeInvoiceUrlSsr = gql`
    query getBuyingYourBuzzbikeInvoiceUrl {
  getBuyingYourBuzzbikeInvoiceUrl {
    ... on InvoicePdf {
      url
    }
    ... on APIError {
      message
    }
  }
}
    `;
export async function getServerPageGetBuyingYourBuzzbikeInvoiceUrl
    (options: Omit<Apollo.QueryOptions<GetBuyingYourBuzzbikeInvoiceUrlQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetBuyingYourBuzzbikeInvoiceUrlQuery>({ ...options, query: GetBuyingYourBuzzbikeInvoiceUrlSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetBuyingYourBuzzbikeInvoiceUrl = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetBuyingYourBuzzbikeInvoiceUrlQuery, GetBuyingYourBuzzbikeInvoiceUrlQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetBuyingYourBuzzbikeInvoiceUrlSsr, options);
};
export type PageGetBuyingYourBuzzbikeInvoiceUrlComp = React.FC<{data?: GetBuyingYourBuzzbikeInvoiceUrlQuery, error?: Apollo.ApolloError}>;
export const withPageGetBuyingYourBuzzbikeInvoiceUrl = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetBuyingYourBuzzbikeInvoiceUrlQuery, GetBuyingYourBuzzbikeInvoiceUrlQueryVariables>) => (WrappedComponent:PageGetBuyingYourBuzzbikeInvoiceUrlComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetBuyingYourBuzzbikeInvoiceUrlSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetBuyingYourBuzzbikeInvoiceUrl = {
      getServerPage: getServerPageGetBuyingYourBuzzbikeInvoiceUrl,
      withPage: withPageGetBuyingYourBuzzbikeInvoiceUrl,
      usePage: useGetBuyingYourBuzzbikeInvoiceUrl,
    }
export const GetRenewCycleSchemePlanQuoteSsr = gql`
    query getRenewCycleSchemePlanQuote($redemptionCode: String!) {
  getRenewCycleSchemePlanQuote(redemptionCode: $redemptionCode) {
    ... on RenewCycleSchemePlanQuote {
      currentBikeType {
        ...bikeType
      }
      currentMonthlyPayment
      currentPackageId
      currentPackageSlug
      currentPlanEndDate
      currentPlanStartDate
      currentSwitchRolling {
        ...switchPackage
      }
      newBikeType {
        ...bikeType
      }
      newMonthlyPayment
      newPackageId
      newPackageSlug
      newPlanEndDate
      newPlanStartDate
      renewType
      newSwitchRolling {
        ...switchPackage
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${BikeTypeFragmentDoc}
${SwitchPackageFragmentDoc}`;
export async function getServerPageGetRenewCycleSchemePlanQuote
    (options: Omit<Apollo.QueryOptions<GetRenewCycleSchemePlanQuoteQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetRenewCycleSchemePlanQuoteQuery>({ ...options, query: GetRenewCycleSchemePlanQuoteSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetRenewCycleSchemePlanQuote = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetRenewCycleSchemePlanQuoteQuery, GetRenewCycleSchemePlanQuoteQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetRenewCycleSchemePlanQuoteSsr, options);
};
export type PageGetRenewCycleSchemePlanQuoteComp = React.FC<{data?: GetRenewCycleSchemePlanQuoteQuery, error?: Apollo.ApolloError}>;
export const withPageGetRenewCycleSchemePlanQuote = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetRenewCycleSchemePlanQuoteQuery, GetRenewCycleSchemePlanQuoteQueryVariables>) => (WrappedComponent:PageGetRenewCycleSchemePlanQuoteComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetRenewCycleSchemePlanQuoteSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetRenewCycleSchemePlanQuote = {
      getServerPage: getServerPageGetRenewCycleSchemePlanQuote,
      withPage: withPageGetRenewCycleSchemePlanQuote,
      usePage: useGetRenewCycleSchemePlanQuote,
    }
export const GetNoMaintenancePackagesSsr = gql`
    query getNoMaintenancePackages {
  getNoMaintenancePackages {
    packageSlug
    ...package
  }
}
    ${PackageFragmentDoc}`;
export async function getServerPageGetNoMaintenancePackages
    (options: Omit<Apollo.QueryOptions<GetNoMaintenancePackagesQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetNoMaintenancePackagesQuery>({ ...options, query: GetNoMaintenancePackagesSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetNoMaintenancePackages = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetNoMaintenancePackagesQuery, GetNoMaintenancePackagesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetNoMaintenancePackagesSsr, options);
};
export type PageGetNoMaintenancePackagesComp = React.FC<{data?: GetNoMaintenancePackagesQuery, error?: Apollo.ApolloError}>;
export const withPageGetNoMaintenancePackages = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetNoMaintenancePackagesQuery, GetNoMaintenancePackagesQueryVariables>) => (WrappedComponent:PageGetNoMaintenancePackagesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetNoMaintenancePackagesSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetNoMaintenancePackages = {
      getServerPage: getServerPageGetNoMaintenancePackages,
      withPage: withPageGetNoMaintenancePackages,
      usePage: useGetNoMaintenancePackages,
    }
export const AllUsersSsr = gql`
    query allUsers {
  viewer {
    email
    fullName
    riderId
  }
}
    `;
export async function getServerPageAllUsers
    (options: Omit<Apollo.QueryOptions<AllUsersQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<AllUsersQuery>({ ...options, query: AllUsersSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useAllUsers = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(AllUsersSsr, options);
};
export type PageAllUsersComp = React.FC<{data?: AllUsersQuery, error?: Apollo.ApolloError}>;
export const withPageAllUsers = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) => (WrappedComponent:PageAllUsersComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(AllUsersSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrAllUsers = {
      getServerPage: getServerPageAllUsers,
      withPage: withPageAllUsers,
      usePage: useAllUsers,
    }
export const UserProfileSsr = gql`
    query userProfile {
  viewer {
    firstName
    lastName
    fullName
    email
    birthDate
    title
    userId
    companyName
    isBtw
    isRejoiner
    rejoinedTimes
    gender
    cyclingConfidence
    nationalInsurance
    phoneNumberMobile
    promotionCode
    heightCm
    hasSubscription
    wantsMarketingEmails
    requestUrl
    cancellationRequestUrl
    homeAddress {
      addressLineOne
      addressLineTwo
      city
      postcode
    }
    subscription {
      ...subscription
    }
    verification {
      ...verification
    }
  }
}
    ${SubscriptionFragmentDoc}
${VerificationFragmentDoc}`;
export async function getServerPageUserProfile
    (options: Omit<Apollo.QueryOptions<UserProfileQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<UserProfileQuery>({ ...options, query: UserProfileSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useUserProfile = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(UserProfileSsr, options);
};
export type PageUserProfileComp = React.FC<{data?: UserProfileQuery, error?: Apollo.ApolloError}>;
export const withPageUserProfile = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) => (WrappedComponent:PageUserProfileComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(UserProfileSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrUserProfile = {
      getServerPage: getServerPageUserProfile,
      withPage: withPageUserProfile,
      usePage: useUserProfile,
    }
export const UserSubscriptionSsr = gql`
    query userSubscription {
  viewer {
    requestUrl
    cancellationRequestUrl
    subscription {
      ...subscription
    }
  }
}
    ${SubscriptionFragmentDoc}`;
export async function getServerPageUserSubscription
    (options: Omit<Apollo.QueryOptions<UserSubscriptionQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<UserSubscriptionQuery>({ ...options, query: UserSubscriptionSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useUserSubscription = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(UserSubscriptionSsr, options);
};
export type PageUserSubscriptionComp = React.FC<{data?: UserSubscriptionQuery, error?: Apollo.ApolloError}>;
export const withPageUserSubscription = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>) => (WrappedComponent:PageUserSubscriptionComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(UserSubscriptionSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrUserSubscription = {
      getServerPage: getServerPageUserSubscription,
      withPage: withPageUserSubscription,
      usePage: useUserSubscription,
    }
export const GetSubscribedPackagesSsr = gql`
    query getSubscribedPackages {
  getSubscribedPackages {
    ... on SubscribedPackages {
      currentPackage {
        ...subscribedPackage
      }
      nextPackage {
        ...subscribedPackage
      }
    }
    ... on APIError {
      message
    }
  }
}
    ${SubscribedPackageFragmentDoc}`;
export async function getServerPageGetSubscribedPackages
    (options: Omit<Apollo.QueryOptions<GetSubscribedPackagesQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetSubscribedPackagesQuery>({ ...options, query: GetSubscribedPackagesSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetSubscribedPackages = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetSubscribedPackagesQuery, GetSubscribedPackagesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetSubscribedPackagesSsr, options);
};
export type PageGetSubscribedPackagesComp = React.FC<{data?: GetSubscribedPackagesQuery, error?: Apollo.ApolloError}>;
export const withPageGetSubscribedPackages = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetSubscribedPackagesQuery, GetSubscribedPackagesQueryVariables>) => (WrappedComponent:PageGetSubscribedPackagesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetSubscribedPackagesSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetSubscribedPackages = {
      getServerPage: getServerPageGetSubscribedPackages,
      withPage: withPageGetSubscribedPackages,
      usePage: useGetSubscribedPackages,
    }
export const GetRecommendedPackagesSsr = gql`
    query getRecommendedPackages($bikeId: Int, $packageGroupName: String, $freeMonthsPromo: Boolean) {
  getRecommendedPackages(
    bikeId: $bikeId
    packageGroupName: $packageGroupName
    freeMonthsPromo: $freeMonthsPromo
  ) {
    basePrice
    packageSlug
    savingAmount
    paidMonthly
    stickerPrice
    termLengthMonths
    couponToApplyAtEndOfMinimumTerm {
      ...stripeCoupon
    }
  }
}
    ${StripeCouponFragmentDoc}`;
export async function getServerPageGetRecommendedPackages
    (options: Omit<Apollo.QueryOptions<GetRecommendedPackagesQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<GetRecommendedPackagesQuery>({ ...options, query: GetRecommendedPackagesSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetRecommendedPackages = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetRecommendedPackagesQuery, GetRecommendedPackagesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(GetRecommendedPackagesSsr, options);
};
export type PageGetRecommendedPackagesComp = React.FC<{data?: GetRecommendedPackagesQuery, error?: Apollo.ApolloError}>;
export const withPageGetRecommendedPackages = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<GetRecommendedPackagesQuery, GetRecommendedPackagesQueryVariables>) => (WrappedComponent:PageGetRecommendedPackagesComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(GetRecommendedPackagesSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetRecommendedPackages = {
      getServerPage: getServerPageGetRecommendedPackages,
      withPage: withPageGetRecommendedPackages,
      usePage: useGetRecommendedPackages,
    }
export const PaymentDetailsSsr = gql`
    query paymentDetails {
  paymentDetails {
    last4
    expMonth
    expYear
    cardholderName
  }
}
    `;
export async function getServerPagePaymentDetails
    (options: Omit<Apollo.QueryOptions<PaymentDetailsQueryVariables>, 'query'>, apolloClient: Apollo.ApolloClient<NormalizedCacheObject> ){
        
        
        const data = await apolloClient.query<PaymentDetailsQuery>({ ...options, query: PaymentDetailsSsr });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePaymentDetails = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<PaymentDetailsQuery, PaymentDetailsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(PaymentDetailsSsr, options);
};
export type PagePaymentDetailsComp = React.FC<{data?: PaymentDetailsQuery, error?: Apollo.ApolloError}>;
export const withPagePaymentDetails = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<PaymentDetailsQuery, PaymentDetailsQueryVariables>) => (WrappedComponent:PagePaymentDetailsComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(PaymentDetailsSsr, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrPaymentDetails = {
      getServerPage: getServerPagePaymentDetails,
      withPage: withPagePaymentDetails,
      usePage: usePaymentDetails,
    }